import { Injectable } from '@angular/core';
import { Util } from '@util';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelProvider {

  private basePageView = '/traspaso';

  private pathPixeles = {
    contact: '/contactar',
    contactSuccessful: '/contactar/exitoso',
  };

  constructor(
    private util: Util,
  ) { }

  public registerPageView(page: contanctPages) {
    /* istanbul ignore else */
    if (this.util.isProductionEnvironment()) {
      fbq('track', 'PageView');
      fbq('track', 'PageTrack', {
        path: `${this.basePageView + this.pathPixeles[page]}`,
      });
    }
  }
}

export type contanctPages = 'contact' | 'contactSuccessful';

