import { Mockup } from '@interfaces/mockup.interface';

export const CLIENT_MOCKUP: Mockup = {
  success: [{
    response:
    {
      name: 'ALEXIS ALEJANDRO',
      lastName: 'SANCHEZ',
      motherLastName: 'SANCHEZ',
      rut: '19',
      cellphoneNumber: '987683214',
      email: 'pv@pv.cl'
    }
  }],
  failures: [{
    response: {
      statusCode: 500,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
    }
  }],
};

export const CURRENT_ACCOUNTS_MOCK = {
  success: [{
    response: {
      'accounts': [
        {
          'description': 'CUENTA OBLIGATORIA',
          'funds': ['A'],
          'type': 'CCO',
        },
        {
          'description': 'CUENTA AHORRO VOLUNTARIO',
          'funds': ['A'],
          'type': 'CAV',
        },
        {
          'description': 'CUENTA COTIZACION VOLUNTARIA',
          'funds': ['A'],
          'type': 'CCV',
        },
        {
          'description': 'CUENTA DEPOSITOS CONVENIDOS',
          'funds': ['A'],
          'type': 'CDC',
        },
      ],
    },
  }],
  failures: [{
    response: {
      statusCode: 500,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
    }
  }],
};
