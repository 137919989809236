import { Component, Input, OnInit } from '@angular/core';
import { MONTH_DICT } from '@constants';
import { ClientQuotations } from '@interfaces/clientQuotations.interface';
import { EarnedProfitability } from '@interfaces/earnedProfitability.interface';
import { ProductResponse } from '@interfaces/getProductsResponse.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { Util } from '@util';
import { BarChartOptions, AutoScaleAxis, Data } from 'chartist';
import { finalize } from 'rxjs/operators';
import { ChartType } from '@components/chartist/chartist.component';

@Component({
  selector: 'app-affiliate-earned-profitability',
  templateUrl: './affiliate-earned-profitability.component.html',
  styleUrls: ['./affiliate-earned-profitability.component.scss'],
})
export class AffiliateEarnedProfitabilityComponent implements OnInit {
  @Input() public totalBalance: number;
  @Input() public earnedProfitability: EarnedProfitability;
  @Input() public CCOAccount: ProductResponse;
  public loading: boolean;
  public type: ChartType = 'Bar';
  public chartData: Data;
  public chartOptions: BarChartOptions;
  public axisY: AutoScaleAxis;
  public withdrawalAmountPercentage: string;
  public customerContributionPercentage: string;
  public profitPercentage: string;
  public quotations: ClientQuotations;
  public serviceError: string;

  constructor(
    private loadingProvider: LoadingProvider,
    private postVentaService: PostVentaService,
    public util: Util,
  ) { }

  public ngOnInit(): void {
    this.loading = true;
    this.loadingProvider.showLoading();
    this.loadQuotations();
    this.buildChart();
  }

  public getFundTypeClass(type: string): string {
    return `type-${type.toLowerCase()}`;
  }

  private async loadQuotations() {
    this.loading = true;
    this.postVentaService.getClientQuotations()
      .pipe(finalize(() => {
        this.loading = false;
        this.loadingProvider.hideLoading();
      }))
      .subscribe((quotations) => {
        const [month, year] = quotations.lastQuotation.split('-');
        this.quotations = {
          lastQuotation: `${MONTH_DICT[Number(month)]} ${year}`,
          monthsListed: this.getMonthsAndYears(quotations.monthsListed)
        };
      },
        () => {
          this.serviceError = 'quotationsError';
        });
  }

  private buildChart() {
    this.setChartData();
    this.setChartOptions();
  }

  private setChartData() {
    const sumAmount =
      this.earnedProfitability.customerContribution + this.earnedProfitability.profit + this.earnedProfitability.withdrawalAmount;
    const withdrawalAmountPercentage = this.util.getPercentage(this.earnedProfitability.withdrawalAmount, sumAmount);
    const customerContributionPercentage = this.util.getPercentage(this.earnedProfitability.customerContribution, sumAmount);
    const profitPercentage = this.util.getPercentage(this.earnedProfitability.profit, sumAmount);

    this.withdrawalAmountPercentage = Math.round(withdrawalAmountPercentage) + '%';
    this.customerContributionPercentage = Math.round(customerContributionPercentage) + '%';
    this.profitPercentage = Math.round(profitPercentage) + '%';
    this.chartData = {
      series: [
        [customerContributionPercentage],
        [profitPercentage],
        [-withdrawalAmountPercentage],
      ],
    };
  }

  private setChartOptions() {
    this.chartOptions = {
      axisY: {
        scaleMinSpace: 1,
        onlyInteger: true,
        labelInterpolationFnc: (value: number) => {
          return value % 20 === 0 ? value : null;
        }
      },
      height: 200,
      width: 450,
      high: 100,
      low: -40,
      seriesBarDistance: 85,
    };
  }

  private getMonthsAndYears(totalMonths: number): string {
    const years: number = Math.floor(totalMonths / 12);
    const months: number = totalMonths % 12;
    return `${years} año${years !== 1 ? 's' : ''} ${months} mes${months !== 1 ? 'es' : ''}`;
  }
}
