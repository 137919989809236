import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PortfolioComplain } from '@interfaces/portfolioComplain.interface';

@Component({
  selector: 'app-complain-modal',
  templateUrl: 'complain-modal.component.html',
  styleUrls: ['complain-modal.component.scss'],
})
export class ComplainModalComponent {
  @Input() public complains: PortfolioComplain[];

  constructor(
    private bottomSheet: MatBottomSheet,
  ) { }

  public close() {
    this.bottomSheet.dismiss();
  }
}
