import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { POST_VENTA_URL } from '@constants';
import { OTExemptPayment } from '@interfaces/OTExemptPayment.interface';
import { ClientBusinessTube, ClientsBusinessTubeResponse } from '@interfaces/clientsBusinessTube.interface';
import { Executive } from '@interfaces/executive.interface';
import { FilterInterface } from '@interfaces/filter.interface';
import { SideMenuItem } from '@interfaces/sideMenuItem.interface';
import { SplitDate } from '@interfaces/splitDate.interface';
import { StatusBusinessTube } from '@interfaces/statusBusinessTube.interface';
import { ModalProvider } from '@providers/modal/modal';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { Util } from '@util';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DEVICE_TYPE, EXECUTIVE_RUT } from 'util/storage.constants';

export type options = 'summary' | 'create' | 'edit' | 'details' | 'list' | 'cancel-details' | 'summary-archived' | 'list-archived';
const FIRST_MONTH = '1';
const LAST_MONTH = '12';
const ZERO = '0';
const DEFAULT_FILTER = {
  id: 'withoutFilter',
  displayName: 'Sin Filtro',
};
const CLOSED_CUSTOM_FILTER = {
  id: 'closed',
  displayName: '',
};

const ARCHIVED_CLIENTS = true;

@Component({
  selector: 'app-business-tube',
  templateUrl: './business-tube.component.html',
  styleUrls: ['./business-tube.component.scss']
})
export class BusinessTubeComponent implements OnInit {
  public loading = true;
  public searcher = false;
  public showSearcher = true;
  public isArchivedView = false;
  public isBiotablet: boolean;
  public infoExecutive: Executive;
  public clientSelected: ClientBusinessTube = null;
  public archivedClients: ClientsBusinessTubeResponse = { prospect: [], contacted: [], interviewed: [], closed: [] };
  public clients: ClientsBusinessTubeResponse = { prospect: [], contacted: [], interviewed: [], closed: [] };
  public sideNavItems: SideMenuItem[] = [];
  public serviceError: string;
  public option = 'summary';
  public selectedStatus: StatusBusinessTube;
  public selectedFilterIn = DEFAULT_FILTER;
  public defaultFilter = JSON.parse(JSON.stringify(DEFAULT_FILTER));
  private backUpClients: ClientsBusinessTubeResponse;
  private backUpArchivedClients = {} as ClientsBusinessTubeResponse;

  constructor(
    private router: Router,
    private postVentaService: PostVentaService,
    private authenticationService: AuthenticationService,
    private util: Util,
    private location: Location,
    private inactivityService: InactivityService,
    private modalProvider: ModalProvider,
  ) { }

  public ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    this.loading = true;
    const executiveRut = localStorage.getItem(EXECUTIVE_RUT);
    this.isBiotablet = localStorage.getItem(DEVICE_TYPE) === 'biotablet';
    const getClientsBusinessTubeService = this.postVentaService.getClientsBusinessTube(executiveRut);
    const getExecutiveService = this.authenticationService.executiveLogin(executiveRut);

    const services = [getClientsBusinessTubeService, getExecutiveService];

    if (this.shouldCallExemptPaymentService()) {
      const getExemptPaymentService = this.postVentaService.getExemptPayment();
      services.push(getExemptPaymentService);
    }

    forkJoin(services).pipe(
      finalize(() => this.loading = false),
    ).subscribe(
      ([clients, executive, exemptPayment]: [ClientsBusinessTubeResponse, Executive, OTExemptPayment]) => {
        this.setOTExemptPayment(exemptPayment);
        this.serviceError = null;
        this.setClientsInfo(clients);
        this.infoExecutive = executive;
        this.infoExecutive.rut = executiveRut;
      },
      () => {
        this.serviceError = 'clientsBusinessTubeService';
      }
    );
  }

  public goToRoute(option$: string) {
    if (option$ !== 'edit' && option$ !== 'details') this.clientSelected = null;
    if (option$ === 'cancel-details' && this.selectedStatus) return this.option = 'list';
    if (option$ === 'cancel-details' && !this.selectedStatus) return this.option = 'summary';
    this.option = option$;
  }

  public setArchived() {
    this.loading = true;
    const executiveRut = localStorage.getItem(EXECUTIVE_RUT);
    this.postVentaService.getClientsBusinessTube(executiveRut, ARCHIVED_CLIENTS).pipe(
      finalize(() => {
        this.isArchivedView = true;
        this.option = 'summary-archived';
        this.loading = false;
      }))
      .subscribe((archivedClients) => {
        this.setClientsInfo(archivedClients, ARCHIVED_CLIENTS);
      },
        () => this.serviceError = 'clientsBusinessTubeService');
  }

  public setFilter(filter: FilterInterface) {
    this.selectedFilterIn = filter;
    let filteredClients: ClientsBusinessTubeResponse;
    const date = this.util.getSplitDate(new Date());
    const clients = this.isArchivedView ?
      JSON.parse(JSON.stringify(this.backUpArchivedClients)) : JSON.parse(JSON.stringify(this.backUpClients));
    this.clients = JSON.parse(JSON.stringify(this.backUpClients));
    this.archivedClients = JSON.parse(JSON.stringify(this.backUpArchivedClients));

    switch (filter.id) {
      case 'today':
        filteredClients = this.filterClientsByModificationDate(clients, date);
        this.isArchivedView ? this.archivedClients = filteredClients : this.clients = filteredClients;
        break;
      case 'thisMonth':
        date.day = null;
        filteredClients = this.filterClientsByModificationDate(clients, date);
        this.isArchivedView ? this.archivedClients = filteredClients : this.clients = filteredClients;
        break;
      case 'lastMonth':
        const lastMonthDate = this.getLastMonthDate(date);
        filteredClients = this.filterClientsByModificationDate(clients, lastMonthDate);
        this.isArchivedView ? this.archivedClients = filteredClients : this.clients = filteredClients;
        break;
      case 'customDates':
        this.modalProvider.openCustomDatesModal().subscribe((dates) => {
          if (!dates) return this.selectedFilterIn = JSON.parse(JSON.stringify(CLOSED_CUSTOM_FILTER));
          const { toDate, fromDate } = dates;
          filteredClients = this.filterClientsByCustomDates(clients, fromDate, toDate);
          this.isArchivedView ? this.archivedClients = filteredClients : this.clients = filteredClients;
        });
        break;
      case 'withoutFilter':
        break;
    }
  }

  public reloadClients() {
    this.loading = true;
    const executiveRut = localStorage.getItem(EXECUTIVE_RUT);
    this.postVentaService.getClientsBusinessTube(executiveRut, this.isArchivedView).pipe(
      finalize(() => this.loading = false))
      .subscribe((response) => {
        this.serviceError = null;
        this.setClientsInfo(response, this.isArchivedView);
      },
        () => this.serviceError = 'clientsBusinessTubeService');
  }

  public getSelectedStatus(status: StatusBusinessTube) {
    this.selectedStatus = status;
  }

  public setClientSelected(client: ClientBusinessTube) {
    this.clientSelected = client;
  }

  public handleSearcher(option$) {
    this.clients = JSON.parse(JSON.stringify(this.backUpClients));
    this.archivedClients = JSON.parse(JSON.stringify(this.backUpArchivedClients));
    this.searcher = Boolean(option$);
  }

  public goToClientDetailFromSearcher(client$: ClientBusinessTube) {
    this.clients = JSON.parse(JSON.stringify(this.backUpClients));
    this.archivedClients = JSON.parse(JSON.stringify(this.backUpArchivedClients));
    this.clientSelected = client$;
    this.searcher = false;
  }

  public back() {
    const isFromSummary = this.option === 'summary';
    const isFromSummaryArchived = this.option === 'summary-archived';

    if (this.searcher) return this.searcher = false;
    if (isFromSummary) return this.location.back();
    if (isFromSummaryArchived) {
      this.isArchivedView = false;
      this.reloadClients();
    }
    this.option = this.isArchivedView ? 'summary-archived' : 'summary';
    this.selectedFilterIn = JSON.parse(JSON.stringify(this.defaultFilter));
    this.clients = JSON.parse(JSON.stringify(this.backUpClients));
    this.archivedClients = JSON.parse(JSON.stringify(this.backUpArchivedClients));
    this.selectedStatus = null;
    this.clientSelected = null;
  }

  public reLoad() {
    this.router.navigate([POST_VENTA_URL]);
  }


  public getBusinessExemptPaymentDescription(businessRut: string, businessExempt: boolean): string {
    switch (true) {
      case businessExempt && !!businessRut:
        return 'Sí';
      case !businessExempt && !!businessRut:
        return 'No';
      case !businessRut:
        return 'Puedes completar la información';
    }
  }
  private getLastMonthDate(date: SplitDate): SplitDate {
    const { month, year } = date;
    const monthNumber = Number(month);
    const yearNumber = Number(year);
    return {
      day: null,
      month: monthNumber !== Number(FIRST_MONTH) ? String(monthNumber - 1).padStart(2, ZERO) : LAST_MONTH,
      year: monthNumber !== Number(FIRST_MONTH) ? year : String(yearNumber - 1)
    };
  }

  private parseDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  private filterClientsByCustomDates(
    clients: ClientsBusinessTubeResponse, fromDateStr: string, toDateStr: string): ClientsBusinessTubeResponse {
    const fromDate = this.parseDate(fromDateStr);
    const toDate = this.parseDate(toDateStr);

    for (const status of Object.keys(clients)) {
      clients[status] = clients[status].filter((client: ClientBusinessTube) => {
        const modificationDate = this.parseDate(client.modificationDate);
        return modificationDate >= fromDate && modificationDate <= toDate;
      });
    }
    return clients;
  }

  private filterClientsByModificationDate(clients: ClientsBusinessTubeResponse, date: SplitDate): ClientsBusinessTubeResponse {
    for (const status of Object.keys(clients)) {
      clients[status] = clients[status].filter((client: ClientBusinessTube) => {
        const splitModificationDate = client.modificationDate.split('-');
        return this.checkDate(splitModificationDate, date);
      });
    }
    return clients;
  }

  private setClientsInfo(clients: ClientsBusinessTubeResponse, archived: boolean = false) {
    const exemptPaymentClients = localStorage.getItem('exemptPaymentClients');
    const clientsArray: string[] = exemptPaymentClients.split(',');
    const exemptPaymentBusiness = localStorage.getItem('exemptPaymentBusiness');
    const businessArray: string[] = exemptPaymentBusiness.split(',');
    for (const status of Object.keys(clients)) {
      const clientsMapped = clients[status].map((client: ClientBusinessTube) => {
        return {
          ...client,
          exemptPayment: {
            client: clientsArray.includes(client.clientRut.toUpperCase()),
            business: client.businessRut ? businessArray.includes(client.businessRut.toUpperCase()) : false
          }
        };
      });
      archived ? this.archivedClients[status] = clientsMapped : this.clients[status] = clientsMapped;
    }
    archived ? this.backUpArchivedClients =
      JSON.parse(JSON.stringify(this.archivedClients)) : this.backUpClients = JSON.parse(JSON.stringify(this.clients));
  }

  private checkDate(modificationDate: string[], targetDate: SplitDate): boolean {
    const { day, month, year } = targetDate;
    return (!day || modificationDate[0] === day) && modificationDate[1] === month && modificationDate[2] === year;
  }

  private setOTExemptPayment(exemptPayment: OTExemptPayment) {
    if (exemptPayment) {
      const { business, clients, lastUpdate } = exemptPayment;
      const date: Date = new Date();

      const day: number = date.getDate();
      const month: number = date.getMonth() + 1;
      const year: number = date.getFullYear();

      localStorage.setItem('exemptPaymentInfo', JSON.stringify({ lastUpdate, lastCall: `${day}-${month}-${year}` }));
      localStorage.setItem('exemptPaymentBusiness', String(business));
      localStorage.setItem('exemptPaymentClients', String(clients));
    }
  }

  private shouldCallExemptPaymentService() {
    const date: Date = new Date();

    const day: number = date.getDate();
    const month: number = date.getMonth() + 1;
    const year: number = date.getFullYear();
    const exemptPaymentInfo = JSON.parse(localStorage.getItem('exemptPaymentInfo'));
    if (this.hasExemptPaymentData() && exemptPaymentInfo && exemptPaymentInfo.lastUpdate === `${month}-${year}`)
      return false;
    return !exemptPaymentInfo || exemptPaymentInfo.lastCall !== `${day}-${month}-${year}`;
  }

  private hasExemptPaymentData(): boolean {
    const exemptPaymentClients = localStorage.getItem('exemptPaymentClients');
    const exemptPaymentBusiness = localStorage.getItem('exemptPaymentBusiness');
    return !!exemptPaymentClients && !!exemptPaymentBusiness;
  }
}
