import { AuthenticationService } from '@services/authentication/authentication.service';
import { CommunicationProvider } from '@providers/communication/communication';
import { Component, OnInit } from '@angular/core';
import {
  AFP_PATH, EXECUTIVE_URL, invalidTokenError, MY_PORTFOLIO_URL, ONLINE_LOGIN_URL, POST_VENTA_PATH,
  TIMER_PATH, TIME_QUESTIONS, TIME_TRANSFER, HIDDEN_HEADER_PATHS
} from '@constants';
import { LoadingProvider } from '@providers/loading/loading';
import { MatDialog } from '@angular/material/dialog';
import { ModalProvider } from '@providers/modal/modal';
import { Router, NavigationEnd } from '@angular/router';
import { TimerProvider } from '@providers/timer/timer';
import { TimerConfigInterface } from '@interfaces/timerConfig.interface';
import { v4 as uuidv4 } from 'uuid';
import { first } from 'rxjs/operators';
import { GoogleMapsLoaderService } from '@services/google-maps-loader/google-maps-loader.service';
import { Meta, Title } from '@angular/platform-browser';

import packageInfo from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public showLoading = false;
  public showBackButton = false;
  public showFooter = true;
  public url: string;
  public isTimerPath: boolean;
  public totalTime: number;
  public timeQuestions = TIME_QUESTIONS;
  public timeTransfer = TIME_TRANSFER;
  public pathAfp = AFP_PATH;
  public activeTimer = true;
  public hideTimer = false;
  public showHeader = true;
  public version = packageInfo.version;

  constructor(
    private communicationProvider: CommunicationProvider,
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalProvider: ModalProvider,
    private meta: Meta,
    private googleMapsLoaderService: GoogleMapsLoaderService,
    private loadingProvider: LoadingProvider,
    private dialogRef: MatDialog,
    private timerProvider: TimerProvider,
  ) {
    this.subscribeUnauthorizedEvent();
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        this.showHeader = !(HIDDEN_HEADER_PATHS.some((path) => path === event.url));
        const isPostVentaPath = POST_VENTA_PATH.some((path) => path === event.url);
        this.showFooter = !isPostVentaPath;
        this.showBackButton = isPostVentaPath && event.url !== EXECUTIVE_URL && event.url !== MY_PORTFOLIO_URL;
        this.url = event.url;
        this.checkPathtimer(event.url);
      }
    });
    this.meta.addTag({ name: 'version', content: packageInfo.version });
  }

  public ngOnInit() {
    this.loadingProvider.getLoadingRequestIntercepted().subscribe((showLoading) => setTimeout(() => this.showLoading = showLoading, 0));
    this.subscribeTimerEvent();
    sessionStorage.setItem('traceID', uuidv4());
    this.authenticationService.validateRecaptcha().pipe(first()).subscribe();
    this.loadGoogleMapsScript();
  }

  public onActivate() {
    window.scroll(0, 0);
  }


  private subscribeUnauthorizedEvent() {
    this.communicationProvider.requestIntercepted
      .subscribe(() => {
        if (!this.authenticationService.isAuthenticated()) return;
        this.authenticationService.logout()
          .catch(() => this.authenticationService.removeCurrentUserData())
          .finally(() => {
            this.dialogRef.closeAll();
            this.router.navigateByUrl(ONLINE_LOGIN_URL);
            this.modalProvider.openGenericErrorModal(invalidTokenError);
          });
      });
  }

  private subscribeTimerEvent() {
    this.timerProvider.getTimerRequestIntercepted()
      .subscribe((response: TimerConfigInterface) => {
        this.activeTimer = response.activeTimer;
        this.hideTimer = response.hideTimer;
      });
  }

  private checkPathtimer(url: string) {
    this.isTimerPath = TIMER_PATH.some((path) => path === url);
    url !== this.pathAfp ? this.changeTimer(this.timeQuestions) :
      this.changeTimer(this.timeTransfer);
  }

  private changeTimer(time: number) {
    this.totalTime = time;
  }

  private loadGoogleMapsScript() {
    this.googleMapsLoaderService.load();
  }
}
