import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { VALIDATION_OPERATIONS } from '@constants';
import { BiometricLoginData } from '@interfaces/biometricLoginData.interface';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Util } from '@util';

@Component({
  selector: 'app-biometric-sign',
  templateUrl: './biometric-sign.component.html',
  styleUrls: ['./biometric-sign.component.scss'],
})

export class BiometricSignComponent {
  @Input() public rut: string;
  @Input() public validateButtonText: string;
  @Input() public callback = Function.prototype;
  @Input() public type: string;
  @Input() public formInvalid = true;
  @Output() public biometricResponse = new EventEmitter();
  @Output() public isLoading = new EventEmitter();

  public firstSegmentText = 'Huella';
  public secondSegmentText = 'Cédula';
  public selectedSegment = 1;
  public userType = 'clientSignature';
  public operations = [{
    name: VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION,
    displayName: 'Validar con huella'
  }, {
    name: VALIDATION_OPERATIONS.BARCODE,
    displayName: 'Validar con cédula'
  }];
  public operation = VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
  public pageId: string;
  public url: string;
  public validationInfo: BiometricLoginData;
  public biometricError: { code: any; description: any } = { code: null, description: '' };
  public loading: boolean;
  private validStatus = 'activo';
  private token: string;
  private uid: string;
  private isPostVenta = true;
  private initialLogin;
  private biometricLogin = { operation: '', barcode: '' };
  private undefinedBarcode = undefined;
  private clientValidationStatus = 'login';
  private validStatusCode = 200;
  private biometricWindow: Window;
  private barcodeOption = 2;

  constructor(
    private authenticationService: AuthenticationService,
    private firebaseDatabase: AngularFireDatabase,
    private util: Util,
    private ngZone: NgZone,
  ) {}

  public changeSegmentSelection($event: number) {
    const userStatus = `${ this.type }ValidationStatus`;
    this.selectedSegment = $event;
    this.operation = this.selectedSegment === this.barcodeOption ?
    VALIDATION_OPERATIONS.BARCODE : VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
    if (this.selectedSegment === this.barcodeOption) this.operation = VALIDATION_OPERATIONS.BARCODE;
    if (this.operation === VALIDATION_OPERATIONS.BARCODE && this[userStatus] === 'login') this.setLoginWithIdentityCard();
    if (this.operation === VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION && this[userStatus] === 'loginIdentityCard') this.setLogin();
  }

  public validate() {
    this.verifyBiometricIdentity(this.rut, this.type);
  }

  private async verifyBiometricIdentity(rut: string, type: string) {
    const statusName = `${ type }ValidationStatus`;

    await this.setTokenAndUid();

    await this.generateUrlAndPageId(this.util.rutClean(rut));
    // BYPASS
    // this.firebaseBypass(rut, this.uid, this.pageId);
    this[statusName] = this.initialLogin === 'loginIdentityCard' ? 'waitingIdentityCard' : 'waiting';
    this.callBiometricApk();
    this.validateUser(type);
  }

  // private async firebaseBypass(rut: string, uid: string, pageId: string) {
  //   const data = {
  //     verification_serie: '',
  //     verification_rut: rut,
  //     verification_nombres: '',
  //     verification_apellidos:  '',
  //     verification_nacimiento: '',
  //     verification_codigo: 200,
  //     verification_mensaje: 'Proceso finalizado correctamente',
  //     verification_transaccion: '',
  //     verification_url: '',
  //     verification_codigo_barra: '',
  //     status: 'activo'
  //   };
  //   await this.firebaseDatabase.database.ref('user').child(uid).child(pageId).set(data);
  // }

  private async setTokenAndUid() {
    const sessionData = await this.authenticationService.signInAnonymously();

    this.token = sessionData.token;
    this.uid = sessionData.uid;
  }

  private async validateUser(type: string) {
    if (this.operation === VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION) this.isLoading.emit(true);
    const sessionData = await this.authenticationService.signInAnonymously();
    this.uid = sessionData.uid;
    this.cancelOnChangeDataEvent();
    this.firebaseDatabase.database.ref(`user/${ this.uid }/${ this.pageId }`)
      .on('value', (data) => this.handleFirebaseData(data));
  }

  private handleFirebaseData(data: firebase.default.database.DataSnapshot) {
    const firebaseData = data.val();
    if (!firebaseData || firebaseData.status !== this.validStatus) return;
    this.ngZone.run(async () => {

      if (this.isBiometricResponseInvalid(firebaseData)) return this.handleInvalidBiometricResponse(firebaseData);

      const isBarcodeOperation = this.operation === VALIDATION_OPERATIONS.BARCODE;
      const userStatus = `${ this.type }ValidationStatus`;
      if (isBarcodeOperation && this[userStatus] === 'waitingIdentityCard') return this.setBarCode(userStatus, firebaseData);

      this.validation();
      this.cancelOnChangeDataEvent();
    });
  }

  private setBarCode(userStatus: string, firebaseData) {
    this.biometricLogin.barcode = firebaseData.verification_codigo_barra;
    this[userStatus] = 'login';
    this.initialLogin = 'login';
    this.cancelOnChangeDataEvent();
    this.validate();
  }

  private setLoginWithIdentityCard() {
    const userStatus = `${this.type}ValidationStatus`;
    this[userStatus] = 'loginIdentityCard';
    this.initialLogin = 'loginIdentityCard';
  }

  private setLogin() {
    const userStatus = `${this.type}ValidationStatus`;
    this[userStatus] = 'login';
    this.initialLogin = 'login';
  }

  private handleUserValidationError() {
    this.clientValidationStatus = 'errorValidation';
  }

  private handleInvalidBiometricResponse(data) {
    const { verification_codigo: code, verification_mensaje: description } = data;
    this.biometricError = { code, description };
    this.handleUserValidationError();
    this.cancelOnChangeDataEvent();
  }

  private isBiometricResponseInvalid(data: any) {
    return data.verification_codigo !== this.validStatusCode;
  }

  private cancelOnChangeDataEvent() {
    this.firebaseDatabase.database.ref(`user/${ this.uid }/${ this.pageId }`).off('value');
  }

  private async generateUrlAndPageId(rut: string) {
    const { url, pageId } = await this.getUrlAndPageId(rut);
    this.url = url;
    this.pageId = pageId;
  }

  private callBiometricApk() {
    this.biometricWindow = window.open(this.url);
    if (this.biometricWindow) {
      setTimeout(() => this.biometricWindow.close(), 1000);
    }
  }

  private async validation() {
    await this.validateAndSetToken(this.rut);
    this.biometricResponse.emit(this.clientValidationStatus);
    this.clientValidationStatus = 'ok';
  }

  private async getUrlAndPageId(rut: string) {

    const userStatus = `${ this.type }ValidationStatus`;
    const isVerifyIdentity = this.operation === VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
    const failValidation = this[userStatus] === 'errorValidation';

    if (isVerifyIdentity) {
      return this.authenticationService.biometricUrl(rut, this.token, this.uid, this.undefinedBarcode, this.isPostVenta);
    }
    if (this.initialLogin === 'loginIdentityCard' || failValidation) {
      return this.authenticationService.biometricUrlForIdentityCard(this.token, this.uid, this.isPostVenta);
    }
    if (this.initialLogin === 'login') {
      return this.authenticationService.biometricUrl(rut, this.token, this.uid, this.biometricLogin.barcode, this.isPostVenta);
    }
  }

  private async validateAndSetToken(rut: string) {
    await this.authenticationService.getToken(rut)
    .toPromise()
    .then(async ({ token }) => {
      await this.authenticationService.setAffiliateIdToken(token);
    });
  }

}
