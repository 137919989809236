/* eslint-disable no-magic-numbers */
export const OPENING_DISCOUNT_MOCK = {
  success: [{
    response: {
      'messageDescription': '',
      'code': 20013,
      'service_Error': null,
      'folioNumber': '1234',
      'email': 'test@test.cl',
      'message': '',
    },
  }, {
    response: {
      'messageDescription': '',
      'code': 20014,
      'service_Error': null,
      'folioNumber': '2345',
      'email': '',
      'message': '',
    },
  }],
  failures: [{
    response: {
      'code': 50001,
      'folioNumber': null,
      'messageDescription': 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      'serviceErrorMessage': '',
      'message': 'Error',
    },
  }, {
    response: {
      'code': 40074,
      'folioNumber': null,
      'messageDescription': 'Ya tiene una petición realizada con este RUT.',
      'serviceErrorMessage': '',
      'message': 'Error',
    },
  }, {
    response: {
      'code': 40076,
      'folioNumber': null,
      'messageDescription': 'Hubo un error al realizar la transacción.',
      'serviceErrorMessage': '',
      'message': 'Error',
    },
  }, {
    response: {
        'code': 40077,
        'folioNumber': null,
        'messageDescription': 'La solicitud no pudo ser encontrada.',
        'serviceErrorMessage': '',
        'message': 'Error',
    },
  }, {
    response: {
        'code': 40078,
        'folioNumber': null,
        'messageDescription': 'Hubo un error al realizar la transacción.',
        'serviceErrorMessage': '',
        'message': 'Error',
    },
  }, {
    response: {
        'code': 40079,
        'folioNumber': null,
        'messageDescription': 'Hubo un error al realizar la transacción.',
        'serviceErrorMessage': '',
        'message': 'Error',
    },
  }, {
    response: {
        'code': 40067,
        'folioNumber': null,
        'messageDescription': 'No se pudo encontrar el regimen.',
        'serviceErrorMessage': '',
        'message': 'Error',
    },
  }, {
    response: {
        'code': 40068,
        'folioNumber': null,
        'messageDescription': 'No se pudo encontrar la lista de regimenes.',
        'serviceErrorMessage': '',
        'message': 'Error',
    },
  }, {
    response: {
        'code': 40073,
        'folioNumber': null,
        'messageDescription': 'Los montos no son válidos.',
        'serviceErrorMessage': '',
        'message': 'Error',
    },
  }],
};
