import { Injectable } from '@angular/core';
import { environment } from '@env';
import { GetSecurityQuestionsResponse, QuestionAnswered } from '@interfaces/securityQuestions.interface';
import { HttpClientInterceptor } from '@providers/httpClientInterceptor/httpClientInterceptor';
import { Util } from '@util';
import { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { SECURITY_QUESTIONS_LOGIN_MOCKUP, SECURITY_QUESTIONS_MOCKUP } from './questions.mock';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  public baseURL: string;

  constructor(
    public http: HttpClientInterceptor,
    public util: Util,
    public authenticationService: AuthenticationService,
  ) {
    this.baseURL = environment.baseURL;
  }

  public getSecurityQuestions(
    rut: string,
    documentId: string,
    firebaseId?: string,
    retry = false,
    origin?: string
  ): Observable<GetSecurityQuestionsResponse> {

    const mockRetry = retry && environment.mockHttpCalls ? '&retry' : '';
    const petitionUrl = `${this.baseURL}authentication/security-questions?rut=${rut}&documentId=${documentId}`;
    let traceParams = `&traceID=${this.util.getTraceID()}&origin=${origin ? origin : this.util.getOriginTransfer()}`;
    const executiveRut = this.authenticationService.getExecutiveRut();
    if (executiveRut) traceParams += `&executiveRut=${executiveRut}`;
    const url = firebaseId ?
      `${petitionUrl}&firebaseId=${firebaseId}${traceParams}${mockRetry}`
      : `${petitionUrl}${traceParams}${mockRetry}`;

    return this.http.get(url, SECURITY_QUESTIONS_MOCKUP).pipe(catchError(error => this.util.generalCatchError(error)));
  }

  public securityQuestionsLogin(
    answers: Array<QuestionAnswered>,
    rut: string,
    transactionId: string,
    interactiveQuestionId: number,
    isSinacofi: boolean
  ) {
    const request = {
      answers,
      rut,
      transactionId,
      interactiveQuestionId,
      isSinacofi,
      origin: this.util.getOriginTransfer(),
      traceID: this.util.getTraceID(),
      executiveRut: this.authenticationService.getExecutiveRut()
    };
    return this.http.post(`${this.baseURL}authentication/security-questions/`, request, SECURITY_QUESTIONS_LOGIN_MOCKUP)
      .pipe(
        mergeMap(async (response) => await this.authenticationService.setCurrentUserData(response.token)),
        catchError(error => this.util.generalCatchError(error)),
      );
  }

  public authSecurityQuestionsLogin(
    answers: Array<QuestionAnswered>,
    rut: string,
    transactionId: string,
    interactiveQuestionId: number,
    firebaseId: string,
    isSinacofi
  ) {
    const request = {
      answers,
      rut,
      transactionId,
      interactiveQuestionId,
      firebaseId,
      biotablet: true,
      isSinacofi,
      origin: this.util.getOriginTransfer(),
      traceID: this.util.getTraceID(),
      executiveRut: this.authenticationService.getExecutiveRut()
    };
    return this.http.post(`${this.baseURL}authentication/remote-security-questions/`, request, SECURITY_QUESTIONS_LOGIN_MOCKUP)
      .pipe(
        mergeMap(async (response) => response),
        catchError(error => this.util.generalCatchError(error)),
      );
  }
}
