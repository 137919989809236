import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Employer } from '@interfaces/client.interface';
import { HttpClientInterceptor } from '@providers/httpClientInterceptor/httpClientInterceptor';
import { Util } from '@util';
import { SEARCH_EMPLOYER } from './searchEmployer.mock';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class EmployerService {
  public baseURL: string;

  constructor(
    public http: HttpClientInterceptor,
    public util: Util,
    public authenticationService: AuthenticationService,
  ) {
    this.baseURL = environment.baseURL;
  }

  public searchEmployer(employerRut: string): Observable<Employer> {
    const uid = this.authenticationService.uid();
    let url = `${this.baseURL}employers/?rut=${uid}&employerRut=${employerRut}&origin=${this.util.getOriginTransfer()
    }&traceID=${this.util.getTraceID()}`;
    const executiveRut = this.authenticationService.getExecutiveRut();
    if (executiveRut) url += `&executiveRut=${executiveRut}`;
    return this.http.get(url, SEARCH_EMPLOYER).pipe(
      mergeMap(employer => {
        return of(employer);
      }),
      catchError(error => this.util.generalCatchError(error))
    );
  }
}
