import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Injectable(
  { providedIn: 'root' }
)
export class TransferProvider {
  private firebaseIdSubject = new BehaviorSubject<string>(null);
  private isFirebaseAssisted = new BehaviorSubject<boolean>(false);

  constructor() { }

  public observeFirebaseId() {
    return this.firebaseIdSubject.asObservable();
  }

  public setFirebaseId(firebaseId: string) {
    this.firebaseIdSubject.next(firebaseId);
  }

  public observeIsFirebaseAssisted() {
    return this.isFirebaseAssisted.asObservable();
  }

  public setIsFirebaseAssisted(isFirebaseAssisted: boolean) {
    this.isFirebaseAssisted.next(isFirebaseAssisted);
  }

  public combined() {
    return combineLatest([
      this.observeFirebaseId(),
      this.observeIsFirebaseAssisted(),
    ]);
  }

}
