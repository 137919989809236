import { Injectable } from '@angular/core';
import { environment } from '@env';
import { AccountsGraphicSummaryResponse } from '@interfaces/accountGraphicSummaryResponse';
import { AttentionCodeResponse } from '@interfaces/attentionCodeResponse';
import { CertificateRequest } from '@interfaces/certificate.request.interface';
import { AccountCert, ClientDataResponse } from '@interfaces/clientData.interface';
import { ClientEmployersResponse } from '@interfaces/clientEmployers.response';
import { ExecutivePorfolioResponse } from '@interfaces/executivePortfolioResponse.interface';
import { GetContractResponse } from '@interfaces/getContractResponse.interface';
import { ClientEconomicActivity } from '@interfaces/economicActivity.interface';
import { MarkAffiliateContactedRequest } from '@interfaces/markAffiliateContactedRequest.interface';
import { PortfolioComplain } from '@interfaces/portfolioComplain.interface';
import { ProfitabilityEmailRequest } from '@interfaces/profitabilityEmailRequest.interface';
import { SecurityKeyStatusresponse } from '@interfaces/securityKeyStatusresponse.interface';
import { GetSecurityPasswordStateResponse } from '@interfaces/securityPasswordState.interface';
import { HttpClientInterceptor } from '@providers/httpClientInterceptor/httpClientInterceptor';
import { GRAPH_SUMMARY_MOCKUP } from '@services/post-venta/mocks/accountsGraphicSummary.mock';
import { SECURITY_KEY_STATUS_MOCK } from '@services/post-venta/mocks/getSecurityKeyStatus.mock';
import { MARK_AFFILIATE_AS_CONTACTED_MOCKUP } from '@services/post-venta/mocks/markAffiliateAsContacted.mock';
import { Util } from '@util';
import { CertificateTypes } from 'app/modules/post-venta/quote-certificate/quote-certificate.component';
import { ATTENTION_CODE_MOCK } from 'app/services/client/mocks/attention-code.mock';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CLIENT_DATA } from 'util/storage.constants';
import { AuthenticationService } from '../authentication/authentication.service';
import { CLIENT_MOCKUP, CURRENT_ACCOUNTS_MOCK } from './mocks/client.mock';
import { CLIENT_EMPLOYERS_MOCKUP } from './mocks/clientEmployers.mock';
import { PORTFOLIO_COMPLAIN_MOCKUP } from './mocks/complains.mock';
import { GET_CERTIFICATE_MOCKUP } from './mocks/getCertificate.mock';
import { GET_CONTRACT_MOCKUP } from './mocks/getContract.mock';
import { EXECUTIVE_PORTFOLIO_MOCKUP } from './mocks/getExecutivePorfolio.mock';
import { SEND_CONTRACT_MOCKUP } from './mocks/sendContract.mock';
import { SEND_PASSWORD_MOCKUP } from './mocks/sendPassword.mock';
import { SECURITY_PASSWORD_STATE_MOCKUP } from './mocks/sendSecurityPassword.mock';
import { CLIENTS_BUSINESS_TUBE_MOCKUP } from './mocks/getClientsBusinesTube.mock';
import { ClientBusinessTube, ClientsBusinessTubeResponse } from '@interfaces/clientsBusinessTube.interface';
import { OTExemptPayment } from '@interfaces/OTExemptPayment.interface';
import { OT_EXEMPT_PAYMENT_MOCKUP } from './mocks/exemptPayment.mock';
import { EarnedProfitability } from '@interfaces/earnedProfitability.interface';
import { EARNED_PROFITABILITY_MOCKUP } from './mocks/earnedProfitability.mock';
import { ClientQuotationsResponse } from '@interfaces/clientQuotations.interface';
import { CLIENT_QUOTATIONS_MOCKUP } from './mocks/clientQuotations.mock';
import { SendPublicCertificateRequest } from '@interfaces/sendPublicCertificate.interface';
import { ECONOMIC_ACTIVITIES_MOCKUP } from './mocks/economicActivities.mock';
import { JOB_TITLES_MOCKUP } from './mocks/jobTitles.mock';

@Injectable({
  providedIn: 'root'
})
export class PostVentaService {
  public baseURL: string;

  constructor(
    public http: HttpClientInterceptor,
    public util: Util,
    public authenticationService: AuthenticationService,
  ) {
    this.baseURL = environment.baseURL;
  }

  public getClient(rut: string): Observable<ClientDataResponse> {
    return this.http.get(`${this.baseURL}post-venta/client/?rut=${rut}`, CLIENT_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public updateClient(request): Observable<any> {
    return this.http.put(`${this.baseURL}post-venta/client/`, request, CLIENT_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getClientEmployers(rut: string): Observable<ClientEmployersResponse[]> {
    return this.http.get(`${this.baseURL}post-venta/client/employers/?rut=${rut}`, CLIENT_EMPLOYERS_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public sendTemporalPassword(request): Observable<any> {
    return this.http.post(`${this.baseURL}post-venta/send-temporal-password/`, request, SEND_PASSWORD_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getCertificate(request: CertificateRequest, certificateType: CertificateTypes): Observable<any> {
    const endpoint = this.getCertificateEndpoint(certificateType);
    let url = `${environment.baseURL}${endpoint}?rut=${request.rut}&executiveInfo=${request.executiveInfo}`;
    if (certificateType === 'quotations' || certificateType === 'remunerations') {
      if (request.periodCode) {
        url += `&productType=${request.productType}&periodCode=${request.periodCode}`;
      } else {
        url += `&productType=${request.productType}&startDate=${request.fromDate}&endDate=${request.toDate}`;
      }
    }
    return this.http.get(url, GET_CERTIFICATE_MOCKUP)
      .pipe(catchError(error => this.util.generalCatchError(error)));
  }

  private getCertificateEndpoint(certificateType: CertificateTypes): string {
    const endpointMap: Record<CertificateTypes, string> = {
      contingency_records: 'certificates/contingency-records',
      affiliate: 'certificates/affiliate',
      vacations: 'certificates/vacations',
      quotations: 'certificates/quotations',
      remunerations: 'certificates/remunerations'
    };
    return endpointMap[certificateType] || 'certificates';
  }

  public currentAccounts(): Observable<Array<AccountCert>> {
    const clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    const rut = clientData.rut;
    return this.http.get(`${environment.baseURL}clients/accounts/?rut=${rut}`, CURRENT_ACCOUNTS_MOCK).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public graphicSummary(): Observable<AccountsGraphicSummaryResponse> {
    const clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    const rut = clientData.rut;
    return this.http.get(`${environment.baseURL}post-venta/graphic-summary/?rut=${rut}`, GRAPH_SUMMARY_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public earnedProfitability(): Observable<EarnedProfitability> {
    const clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    const rut = clientData.rut;
    return this.http.get(`${environment.baseURL}post-venta/earned-profitability/${rut}`, EARNED_PROFITABILITY_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getClientQuotations(): Observable<ClientQuotationsResponse> {
    const clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    const rut = clientData.rut;
    return this.http.get(`${environment.baseURL}post-venta/client-quotations?rut=${rut}`, CLIENT_QUOTATIONS_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getSecurityPasswordState(rut: string): Observable<GetSecurityPasswordStateResponse> {
    return this.http.get(`${environment.baseAppURL}security/key/status/?rut=${rut}`, SECURITY_PASSWORD_STATE_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getContract(rut: string): Observable<GetContractResponse> {
    return this.http.get(`${environment.baseAppURL}clients/security-keys/${rut}/contract`, GET_CONTRACT_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public sendContract(rut: string): Observable<void> {
    return this.http.post(`${environment.baseAppURL}clients/security-keys/${rut}/contract`, {}, SEND_CONTRACT_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public cancelContract(rut: string): Observable<void> {
    return this.http.delete(`${environment.baseAppURL}clients/security-keys/${rut}/contract`, SEND_CONTRACT_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getPortfolioComplains(rut: string): Observable<PortfolioComplain[]> {
    return this.http.get(`${environment.baseURL}post-venta/executive/complains?rut=${rut}`, PORTFOLIO_COMPLAIN_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getInfoAffiliatePortfolio(rut: string, executiveRut: string): Observable<PortfolioComplain[]> {
    return this.http.get(`${environment.baseURL}post-venta/portfolio/affiliate?rut=${rut}&executiveRut=${executiveRut}`,
      PORTFOLIO_COMPLAIN_MOCKUP).pipe(
        catchError(error => this.util.generalCatchError(error))
      );
  }

  public updateInfoAffiliatePortfolio(request: any): Observable<any> {
    return this.http.put(`${environment.baseURL}post-venta/portfolio/affiliate`, request, PORTFOLIO_COMPLAIN_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getExecutivePortfolio(rut: string): Observable<ExecutivePorfolioResponse[]> {
    return this.http.get(`${environment.baseURL}post-venta/executive/portfolio?rut=${rut}`, EXECUTIVE_PORTFOLIO_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getSecurityKeyStatus(rut: string): Observable<SecurityKeyStatusresponse> {
    return this.http.get(`${environment.baseURL}post-venta/security-key/status?rut=${rut}`, EXECUTIVE_PORTFOLIO_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public sendContactEmail(request: ProfitabilityEmailRequest): Observable<SecurityKeyStatusresponse> {
    return this.http.post(`${environment.baseURL}post-venta/send-contact-email`, request, SECURITY_KEY_STATUS_MOCK).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public markAffiliateAsContacted(request: MarkAffiliateContactedRequest): Observable<any> {
    return this.http.post(`${environment.baseURL}post-venta/mark-affiliate-contacted`, request, MARK_AFFILIATE_AS_CONTACTED_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public sendAttentionCode(rut: string, origin: string, executiveInfo: string,
    funCode: string, funModule: string, step: number): Observable<AttentionCodeResponse> {
    const data = { rut, origin, executiveInfo, funCode, funModule, step };
    return this.http.post(`${environment.baseURL}post-venta/attention-code/`, data, ATTENTION_CODE_MOCK);
  }

  public getClientsBusinessTube(executiveRut: string, archived: boolean = false): Observable<ClientsBusinessTubeResponse> {
    return this.http.get(`${environment.baseURL}post-venta/business-tube/${executiveRut}/clients?archived=${archived}`,
      CLIENTS_BUSINESS_TUBE_MOCKUP).pipe(
        catchError(error => this.util.generalCatchError(error))
      );
  }

  public getExemptPayment(): Observable<OTExemptPayment> {
    return this.http.get(`${environment.baseURL}post-venta/exempt-payment`, OT_EXEMPT_PAYMENT_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public createClientBusinessTube(request: ClientBusinessTube): Observable<ClientBusinessTube> {
    return this.http.post(`${environment.baseURL}post-venta/business-tube/client`, request, CLIENTS_BUSINESS_TUBE_MOCKUP).pipe(
      catchError(error => throwError(error.error))
    );
  }

  public updateClientBusinessTube(request: ClientBusinessTube): Observable<ClientBusinessTube> {
    return this.http.put(`${environment.baseURL}post-venta/business-tube/client`, request, CLIENTS_BUSINESS_TUBE_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public updateClientArchiveStatus(request: ClientBusinessTube): Observable<ClientBusinessTube> {
    return this.http.put(`${environment.baseURL}post-venta/business-tube/archive-client`, request, CLIENTS_BUSINESS_TUBE_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public sendPublicCertificates(request: SendPublicCertificateRequest): Observable<any> {
    return this.http.post(`${environment.baseURL}certificates/public`, request, GET_CERTIFICATE_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getClientEconomicActivities(): Observable<Array<ClientEconomicActivity>> {
    const url = `${environment.baseURL}post-venta/economic-activities`;
    return this.http.get(url, ECONOMIC_ACTIVITIES_MOCKUP);
  }

  public getJobTitles(): Observable<Array<ClientEconomicActivity>> {
    const url = `${environment.baseURL}post-venta/job-titles`;
    return this.http.get(url, JOB_TITLES_MOCKUP);
  }
}
