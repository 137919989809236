/* eslint-disable @typescript-eslint/ban-types */
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EMAIL_PATTERN, ERROR_MESSAGES_FORM } from '@constants';
import { ModalData } from '@interfaces/modalData.interface';

@Component({
  selector: 'app-send-email-pin-modal',
  templateUrl: './send-email-pin-modal.component.html',
  styleUrls: ['./send-email-pin-modal.component.scss'],
})
export class SendEmailPinModalComponent {
  public message: string;
  public messageDescription: string;
  public firstBtnText: string;
  public secondBtnText: string;
  public iconName: string;
  public email: string;
  public emailForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SendEmailPinModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
    Object.keys(data).forEach(key => this[key] = data[key]);
    this.emailForm = this.formBuilder.group({
      email: [this.email, [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]],
    });
  }

  public sendEmail(): void {
    this.dialogRef.close(this.emailForm.controls.email.value);
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public getErrorMessage(form: UntypedFormGroup, controlName: string) {
    const control = form.controls[controlName];

    if (control.hasError('required')) {
      return ERROR_MESSAGES_FORM['required'];
    }

    if (this.invalidEmail(form, controlName)) {
      return ERROR_MESSAGES_FORM['invalidEmail'];
    }

    return '';
  }

  private invalidEmail(form: UntypedFormGroup, controlName: string) {
    const control = form.controls[controlName];
    return control.hasError('email') || (control.hasError('pattern') && controlName === 'email');
  }
}
