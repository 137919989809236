import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ExecutivePorfolioResponse, PortfolioAffiliate, PortfolioAffiliateCard } from '@interfaces/executivePortfolioResponse.interface';
import { Util } from '@util';
import * as _ from 'lodash';

@Component({
  selector: 'app-portfolio-search-affiliate',
  templateUrl: './portfolio-search-affiliate.component.html',
  styleUrls: ['./portfolio-search-affiliate.component.scss']
})
export class PortfolioSearchAffiliateComponent {
  @Output() public actionRoute = new EventEmitter();
  @Output() public affiliateSelect$ = new EventEmitter<any>();
  @Input() public portfolio: ExecutivePorfolioResponse[];
  public searchForm: UntypedFormGroup;
  public searchResult = [];
  public searched = false;


  constructor(
    public util: Util,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.searchForm = this.formBuilder.group({
      search: [''],
    });
  }

  get isSearchActive() {
    const { search } = this.searchForm.controls;
    return search.value !== '';
  }

  get isSearchingByRut() {
    const { search } = this.searchForm.controls;
    return !isNaN(search.value[0]);
  }

  public closeSearcher() {
    this.actionRoute.emit(false);
  }

  public retrySearch() {
    this.searchResult = [];
    this.searched = false;
    const { search } = this.searchForm.controls;
    search.setValue('');
  }

  public searchAffiliate() {
    this.searchResult = [];
    const { search } = this.searchForm.controls;

    const reg = this.isSearchingByRut ?
      this.util.rutClean(search.value) : new RegExp(this.util.removeAccents(search.value).replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi');

    this.portfolio.forEach((monthPortfolio) => {
      monthPortfolio.affiliates.forEach((affiliate) => {
        const match = this.util.removeAccents(affiliate.name.split(' ')[0]).match(reg) ||
          this.util.removeAccents(affiliate.fatherLastName).match(reg) ||
          this.util.removeAccents(affiliate.motherLastName).match(reg) ||
          this.util.rutClean(affiliate.rut) === reg;
        if (match) this.searchResult.push({ ...affiliate, month: monthPortfolio.month });
      });
    });
    this.searched = true;
  }

  public generateInfoCard(affiliate: PortfolioAffiliate): Array<PortfolioAffiliateCard> {
    const infoCard: Array<PortfolioAffiliateCard> = [
      {
        title: 'Tipo de afiliado:',
        description: affiliate.affiliateType,
      },
      {
        title: 'Suscripción:',
        description: affiliate.transferDate ? this.util.formatChileanDate(affiliate.transferDate) : 'Sin Información',
      },
      {
        title: 'Afiliación:',
        description: affiliate.affiliationDate ? this.util.formatChileanDate(affiliate.affiliationDate) : 'Sin Información',
      },
      {
        title: 'Último Contacto:',
        description: affiliate.contactDate ? this.util.formatChileanDate(affiliate.contactDate) : 'Sin Información',
      }
    ];
    return infoCard;
  }

  public getNameAndLastNames(affiliate: PortfolioAffiliate) {
    return `${affiliate.name.split(' ')[0]} ${affiliate.fatherLastName} ${affiliate.motherLastName}`;
  }

  public getFormatDate(affiliate: PortfolioAffiliate) {
    if (!affiliate.departureDate) return;
    return this.util.formatChileanDate(affiliate.departureDate);
  }

  public getRut(affiliate: PortfolioAffiliate) {
    return this.util.rutFormat(affiliate.rut);
  }

  public getAction(rut: string, month: number) {
    return () => this.affiliateSelect$.emit({ rut, month });
  }

}
