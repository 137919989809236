import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  contratAlreadyExists,
  EMAIL_PATTERN,
  ERROR_MESSAGES_FORM,
  FUN_CODES,
  FUN_MODULES,
  ORIGIN_CODES,
  postVentaServiceError,
  securityPasswordMessageSuccess
} from '@constants';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { GetContractResponse } from '@interfaces/getContractResponse.interface';
import { EmailDomainValidator } from '@providers/emailDomainValidator/emailDomainValidator';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { Util } from '@util';
import { finalize } from 'rxjs/operators';
import { CLIENT_DATA } from 'util/storage.constants';

@Component({
  selector: 'app-send-contract',
  templateUrl: './send-contract.component.html',
  styleUrls: ['./send-contract.component.scss']
})
export class SendContractComponent implements OnInit {
  public clientData: ClientDataResponse;
  public emailForm: UntypedFormGroup;
  public executiveInfo: string;

  constructor(
    private postVentaService: PostVentaService,
    private emailDomainValidator: EmailDomainValidator,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private loadingProvider: LoadingProvider,
    private modalProvider: ModalProvider,
    public util: Util,
    private inactivityService: InactivityService

  ) {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]],
      acceptTerms: [false, Validators.requiredTrue]
    });
  }

  get form() {
    return this.emailForm.controls;
  }

  public ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    localStorage.setItem('originAffiliate', 'sacuPassword');
    localStorage.setItem('sacuPassword', 'true');
  }

  public getErrorMessage(controlName: string) {
    const control = this.form[controlName];
    if (control.hasError('required')) {
      return ERROR_MESSAGES_FORM['required'];
    }
    if (this.invalidEmail(controlName)) {
      return ERROR_MESSAGES_FORM['invalidEmail'];
    }
    if (control.hasError('invalidDomain')) {
      return ERROR_MESSAGES_FORM['invalidDomain'];
    }
    return '';
  }

  public async validateEmailDomain() {
    const emailControl = this.emailForm.controls['email'];
    const email = this.emailForm.value['email'];
    if (this.invalidEmail('email') || !email) return;
    const validDomain = await this.emailDomainValidator.validateEmailDomain(email);
    if (!validDomain) return emailControl.setErrors({ invalidDomain: true });
  }

  public sendContract() {
    this.loadingProvider.showLoading();
    this.postVentaService.getContract(this.clientData.rut)
      .subscribe((response: GetContractResponse) => {
        if (!response.exists) return this.sendContractEmail();
        this.loadingProvider.hideLoading();
        this.saveAttentionSendContract(true);
        this.modalProvider.openGenericErrorModal(contratAlreadyExists);
      }, error => {
        this.loadingProvider.hideLoading();
        this.handleServiceError(error);
      });
  }

  public goBack() {
    this.location.back();
  }

  private handleServiceError(error) {
    error = error.error || error;
    if (!error.code) error = postVentaServiceError;
    this.modalProvider.openGenericRetryErrorModal(error);
  }

  private sendContractEmail() {
    this.postVentaService.sendContract(this.clientData.rut)
      .pipe(finalize(() => this.loadingProvider.hideLoading()))
      .subscribe(() => {
        this.handleSuccessSendContract();
      }, error => this.handleServiceError(error));
  }

  private handleSuccessSendContract() {
    this.saveAttentionSendContract();

    const modalData = {
      ...securityPasswordMessageSuccess,
      iconName: 'i-success',
      primaryCallback: () => this.goBack(),
      firstBtnText: 'Entendido',
      images: false,
    };
    this.modalProvider.openModal(modalData);
  }

  private saveAttentionSendContract(failed: boolean = false) {
    this.executiveInfo = localStorage.getItem('infoExecutive');
    this.postVentaService.sendAttentionCode(this.clientData.rut, ORIGIN_CODES.BTABLET, this.executiveInfo,
      FUN_CODES.CLAVESEGURIDAD, FUN_MODULES.CREARCONTRATO, failed ? -1 : 0).subscribe(() => { }, () => { });
  }

  private invalidEmail(controlName: string) {
    const control = this.form[controlName];
    return control.hasError('email') || (control.hasError('pattern') && controlName === 'email');
  }
}
