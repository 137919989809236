import { HttpErrorCodes } from '@interfaces/HttpErrorCodes';

export const loginErrorCode = 40101;
export const wrongSacuKey = 40102;
export const transferServiceErrorCode = 40106;
export const INVALID_SCORE = 40119;
export const INACTIVITY_TIME_POST_VENTA = 600;

export const httpErrorCodes: HttpErrorCodes = {
  unauthorized: {
    code: 401,
    message: 'No autorizado',
    messageDescription: 'Usuario sin autorización.'
  },
  internalServerError: {
    code: 500,
    message: 'Error',
    messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
  },
  badRequest: {
    code: 400,
    message: 'Bad request',
    messageDescription: 'Hubo un error en la petición al servidor.'
  },
};

export const onVoluntaryTransferError = {
  message: 'El régimen seleccionado no puede ser transferido.',
  messageDescription: 'El saldo mínimo para transferir es de $1.000.',
};

export const onVoluntaryTransferWithTaxError = {
  message: 'El régimen seleccionado no puede ser transferido.',
  messageDescription: 'Debido a que posee una bonificación fiscal inferior al saldo mínimo para transferir de $1.000.',
};

export const onMandatoryTransferError = {
  message: 'No puedes transferir esta cuenta obligatoria.',
  messageDescription: 'No tienes saldo suficiente en alguno de sus fondos.',
};

export const onPinValidationError = {
  message: 'Código PIN incorrecto',
  messageDescription: 'El código PIN ingresado no coincide con el que fue enviado. ' +
    'Por favor reintente o solicita un nuevo código.'
};

export const onServiceError = {
  message: 'Ha ocurrido un error inesperado.',
  messageDescription: 'Vuelva a intentarlo más tarde.',
};

export const postVentaServiceError = {
  message: 'Ha ocurrido un error inesperado.',
  messageDescription: 'Estamos presentando dificultades en el sistema. Por favor intentar más tarde.',
};

export const contratAlreadyExists = {
  message: '¡Lo sentimos!',
  messageDescription: 'Ya hay un contrato vigente, por favor cree la clave en el sitio privado de AFP Planvital.',
};

export const invalidTokenError = {
  message: '¡Lo sentimos!',
  messageDescription: 'Su sesión ha expirado, vuelva a iniciar sesión.'
};

export const oneTryLeftMessageWarning = {
  message: 'Respuesta incorrecta',
  messageDescription: 'Aún tienes 1 intento restante'
};

export const blockedUserMessageError = {
  code: 40116,
  message: '¡Lo sentimos!, tu usuario se encuentra bloqueado.',
  messageDescription: 'Puedes intentarlo después del'
};

export const updateClientMessageSuccess = {
  message: '¡Actualización exitosa!',
  messageDescription: 'La actualización de datos fue realizada con éxito.'
};

export const securityPasswordMessageSuccess = {
  message: '¡Contrato de clave enviado!',
  messageDescription: 'Ahora podrás crear tu clave entrando al sitio privado de AFP Planvital.'
};

export const cancelContractError = {
  message: '¡Lo sentimos!',
  messageDescription: 'Ocurrio un error al cancelar el contrato. Por favor, intente nuevamente más tarde.'
};

export const timeUpError = {
  message: 'Atencion!',
  messageDescription: 'Se te ha acabado el tiempo para realizar la solicitud'
};

export const fundList = [
  {
    type: 'A',
    description: 'Más riesgoso'
  }, {
    type: 'B',
    description: 'Riesgoso'
  }, {
    type: 'C',
    description: 'Intermedio'
  }, {
    type: 'D',
    description: 'Conservador'
  }, {
    type: 'E',
    description: 'Más conservador'
  },
];

export const accountTypeName = {
  'CCO': 'Cuenta Obligatoria',
  'CCICO': 'Cuenta Obligatoria',
  'CAV': 'Cuenta de Ahorro Voluntario',
  'CAI': 'Cuenta de Indemnización',
  'CCV': 'Cuenta de Cotización Voluntaria',
  'CCICV': 'Cuenta de Cotización Voluntaria',
  'CCIV': 'Cuenta de Cotización Voluntaria',
  'CDC': 'Cuenta de Depósito Convenido',
  'CCIDC': 'Cuenta de Depósito Convenido',
  'CAF': 'Cuenta de Afiliado Voluntario',
  'CCIAV': 'Cuenta de Afiliado Voluntario',
  'CVC': 'Cuenta de Cotización Voluntaria Colectiva',
  'CAPVC': 'Cuenta de Cotización Voluntaria Colectiva',
  'APVC': 'Cuenta de Cotización Voluntaria Colectiva'
};

export const normalizedAccountType = {
  'CCO': 'CCICO',
  'CCICO': 'CCICO',
  'CAI': 'CAI',
  'CAF': 'CCIAV',
  'CCIAV': 'CCIAV',
  'CCV': 'CCICV',
  'CCIV': 'CCICV',
  'CCICV': 'CCICV',
  'CAV': 'CAV',
  'CDC': 'CCIDC',
  'CCIDC': 'CCIDC',
  'CVC': 'APVC',
  'APVC': 'APVC',
  'CAPVC': 'APVC',
};

export const accountsRank = {
  CCICO: 1,
  CAI: 2,
  CCIAV: 3,
  CCICV: 4,
  CAV: 5,
  CCIDC: 6,
  APVC: 7,
};

export const voluntaryAccounts = ['CCV', 'CDC', 'CAV', 'APVC', 'CCICV', 'CCIDC', 'CVC', 'CAPVC', 'CCIV'];
export const mandatoryAccounts = ['CCO', 'CAI', 'CAF', 'CCICO', 'CCIAV'];
export const distributableAccounts = ['CCV', 'CCICV', 'CCIDC', 'CDC', 'CAV'];

export const regimeTypeName = {
  'SR': 'Único',
  'RG': 'General',
  'O7': '57 bis',
  'C4': '54 bis',
  'AN': 'Antiguo',
  'TR': 'Transitorio',
  'EX': 'Excesos 54 bis',
  'AA': 'Tipo A',
  'AB': 'Tipo B',
  'BF': 'Bonificación Fiscal',
  'RC': 'Retiro 10'
};

export const rejectedReasons = {
  '1': 'Trabajador sin cuenta vigente.',
  '3': 'La cuenta se encuentra en trámite de traspaso total de saldos.',
  '4': 'A la fecha de suscripción de la OT no ha transcurrido el mes de recepción de la cuenta personal.',
  '5': 'Cuenta sin saldo.',
  '6': 'Cuenta afecta a reclamos en materia de afiliación, multiafiliación, falsificación o regularización de saldos.',
  '7': 'El trabajador se encuentra en trámite de desafiliación.',
  '8': 'La cuenta del trabajador se encuentra en trámite de pensión.',
  '9': 'Cuentas han sido embargadas.',
  '10': 'A la fecha de solicitud, se encuentra pendiente la materialización de giro de ahorro (CAV-CCV).',
  '11': 'A la fecha de solicitud, se encuentra pendiente la materialización de un formulario de cambio o distribución de fondos.',
  '12': 'No ha cumplido el tiempo de permanencia de acuerdo a licitación.',
  '13': 'El trabajador se encuentra fallecido.',
  '14': 'El trabajador tiene más de una cuenta obligatoria, pero solo se está notificando el traspaso de una de ellas.'
};

export const REJECTED_ALIMONY_ERROR = {
  message: 'Problema con el usuario',
  messageDescription: 'No podemos continuar con el traspaso por ahora, por favor consultar con la AFP de origen'
};

// eslint-disable-next-line max-len
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_PATTERN = /^[1-9][0-9]*$/;
export const NINE_DIGITS_PATTERN = /^[1-9]{1}[0-9]{8}$/;
export const CHILEAN_CELLPHONE_PATTERN = /^[9]{1}[0-9]{8}$/;
export const SAFE_NAME_PATTERN = /[/\\?%*:|"<>]/g;

export const planvitalSite = 'https://www.planvital.cl';

export const commissionStructureLink = 'https://www.spensiones.cl/portal/institucional/597/w3-article-15799.html';
export const profitabilityLink = 'http://www.spensiones.cl/portal/institucional/594/w3-propertyvalue-10089.html';
export const serviceQualityLink = 'http://www.spensiones.cl/infoydec';

export const dateFormat = 'YYYY-MM-DD';

export const MIN_PESOS = 2000;
export const MIN_UF = 0.1;
export const MIN_RIM = 1;
export const MAX_RIM = 80;
export const regexOnlyNumbers = /\D+/g;
export const regexNumbersAndCommas = /[^0-9,]/gm;
export const SLASH_REGEX = /^\/+|\/+$/g;

export const breakXsWidth = 360;
export const breakLargeWidth = 795;

// export const SESSION_LENGTH = 900000;
export const SESSION_ALERT_LENGTH = 720000;

export const IP_MAX_LENGTH = 20;

export const SCREEN_BREAKPOINTS = {
  TABLET: 1085,
  MEDIUM_MOBILE: 768,
  SMALL_MOBILE: 481,
};

export const TRANSFER_URL = '/';
export const ONLINE_LOGIN_URL = `${TRANSFER_URL}ingresar`;
export const CONTINUE_ASSISTED = `${TRANSFER_URL}continuar`;
export const CONFIRM_TRANSFER = `${TRANSFER_URL}continuar/confirmar`;
export const POST_VENTA_LOGIN_URL = `${TRANSFER_URL}ingreso-post-venta`;
export const POST_VENTA_URL = `${TRANSFER_URL}post-venta`;
export const EXECUTIVE_URL = `${TRANSFER_URL}ejecutivo`;
export const DEVICES_URL = `${TRANSFER_URL}devices`;
export const SEARCH_AFFILIATE_URL = `${TRANSFER_URL}buscar-afiliado`;
export const AFFILIATE_URL = `${TRANSFER_URL}afiliado`;
export const SEND_PASSWORD_URL = `${TRANSFER_URL}enviar-clave`;
export const SEND_SECURITY_PASSWORD_URL = `${TRANSFER_URL}enviar-clave-seguridad`;
export const SEND_CONTRACT_URL = `${TRANSFER_URL}enviar-contrato`;
export const PRODUCT_OPENING_URL = `${TRANSFER_URL}apertura-productos`;
export const ASSISTED_LOGIN_URL = `${TRANSFER_URL}ingreso-asistido`;
export const BIO_DESKTOP_LOGIN_URL = `${TRANSFER_URL}ingreso-biodesktop`;
export const QUESTIONS_LOGIN_URL = `${TRANSFER_URL}ingresar/preguntas`;
export const TRANSFER_AFP_URL = `${TRANSFER_URL}afp`;
export const CONTACT_URL = `${TRANSFER_URL}contactar`;
export const CONTACT_SUCCESSFUL_URL = `${TRANSFER_URL}contacto-exitoso`;
export const SELECT_QUOTE_URL = `${TRANSFER_URL}seleccionar-periodo`;
export const MY_PORTFOLIO_URL = `${TRANSFER_URL}mi-agenda`;
export const BUSINESS_TUBE_URL = `${TRANSFER_URL}tubo-negocios`;
export const CERTIFICATE_VOUCHERS_URL = `${TRANSFER_URL}certificates-vouchers`;
export const INVESTOR_PROFILE_BOX_URL = `${TRANSFER_URL}investor-profile`;
export const ACCOUNTS_LOGIN_URL = `${TRANSFER_URL}ingresar/accounts`;
export const BIOMETRIC_VALIDATION_URL = `${TRANSFER_URL}validacion-biometrica`;

export const TRANSFER_SURVIVOR_PENSION_BENEFICIARIES_URI = 'afiliado/asesoria/transferencia-pension-sobrevivencia';

export const BIOTABLET_PATHS = [ASSISTED_LOGIN_URL, BUSINESS_TUBE_URL, EXECUTIVE_URL, POST_VENTA_LOGIN_URL, MY_PORTFOLIO_URL, DEVICES_URL];

// -----------------------------------------------
export const BIO_TABLET_ORIGIN = 'BTABLET';

export const PLANVITAL_PHONE = '600 5000 800';
export const AFP_CAPITAL_PHONE = '600 6600 900';
export const AFP_CAPITAL_PHONE_SECOND = '02 2915 4150';
export const AFP_CUPRUM_PHONE = '600 2287 786';
export const AFP_HABITAT_PHONE = '600 2202 000';
export const AFP_HABITAT_PHONE_SECOND = '02 2340 6900';
export const AFP_MODELO_PHONE = '600 828 7200';
export const AFP_MODELO_PHONE_SECOND = '02 2828 7200';
export const AFP_PROVIDA_PHONE = '600 2010 150';
export const AFP_UNO_PHONE = '600 600 1101';

export const AUTHORIZED_PHONES = [
  '56938755546',
  '56926155402',
  '56940907989',
  '56931774259',
  '56952154782',
  '56926049487',
  '56923784934',
  '56926036882',
  '56938763816'
];

export const TRIPLE_DES_KEY = 'BioTablet2019';

export const INITIAL_STEPPER_INDEX = [3, 1];
export const EXTENDED_STEPPER_INDEX = [4, 2];

export const MAX_LENGTH_RUT = 12;

export const QUOTE_TIME_FRAMES = ['Últimos 12 meses', 'Últimos 24 meses', 'Últimos 36 meses', 'Otro'];
export const QUOTE_TIME_BACK_SELECTION = {
  'Últimos 12 meses': 12,
  'Últimos 24 meses': 24,
  'Últimos 36 meses': 36,
};
export const QUOTE_CERTIFICATE_ACCOUNT_TYPES = [{
  'name': 'Cuenta Obligatoria', value: 'CCO',
}, {
  'name': 'Cuenta Voluntaria', value: 'CAF',
}];

export const ACCOUNT_TYPES_DICT = {
  CCO: 'CCO',
  CAF: 'CAF',
  CAV: 'CAV'
};

export const QUOTATIONS_PRODUCTS_TYPES_DICT = [
  { type: 'CCO', display: 'Cuenta Obligatoria', message: 'Cuenta Obligatoria' },
  { type: 'CCV', display: 'Ahorro Previsional Voluntario', message: 'Cuenta de Ahorro Previsional Voluntario' },
  { type: 'CAV', display: 'Cuenta 2', message: 'Cuenta 2' },
];

export const modalData = {
  ...onVoluntaryTransferWithTaxError,
  iconName: 'i-alert-large',
  firstBtnText: 'Aceptar',
  images: false,
};

export const VALIDATION_OPERATIONS = {
  BIOMETRIC_VALIDATION: 'VERIFICAR_IDENTIDAD',
  BARCODE: 'BARCODE',
  SECURITY_QUESTIONS: 'VALIDACION_LLAMADA',
  EMAIL_SECURITY_QUESTIONS: 'VALIDACION_WEB',
  FACIAL_VALIDATION: 'VALIDACION_FACIAL',
};

export const ERROR_MESSAGES_FORM = {
  required: 'Valor requerido.',
  mustMatch: 'Emails deben ser iguales.',
  invalidRut: 'Rut inválido.',
  invalidEmail: 'Email inválido.',
  invalidPhone: 'Teléfono debe ser de 9 dígitos.',
  invalidDomain: 'Dominio del email inválido.',
  lockedEmail: 'Este correo ya está en uso.',
  lockedCellPhone: 'Este número telefónico ya está en uso.',
  maxlength: 'Se ha excedido el máximo de caracteres.',
  minlength: 'Se deben ingresar caracteres adicionales.'
};

export const ERROR_MESSAGES_CONTACT_FORM = {
  affiliate: 'RUT ya afiliado',
  required: 'Campo requerido.',
  invalidPhoneFormat: 'Teléfono debe comenzar con 9.'
};

export const SEARCH_AFFILIATE_ERROR_MESSAGE = {
  ok: '',
  error: 'El RUT buscado no coincide con afiliados en AFP PlanVital.',
};

export const PATH_FIRST_WITHDRAWAL = 'primer-retiro-fondos/biotablet';
export const PATH_SECOND_WITHDRAWAL = 'segundo-retiro/solicitud/biotablet';
export const PATH_THIRD_WITHDRAWAL = 'tercer-retiro/solicitud/biotablet';

export const PATH_GOVERNMENT_WITHDRAWAL = 'bono-cargo-fiscal/solicitud/biotablet';

export const PATH_WITHDRAWAL = {
  1: PATH_FIRST_WITHDRAWAL,
  2: PATH_SECOND_WITHDRAWAL,
  3: PATH_THIRD_WITHDRAWAL,
  'bono200': PATH_GOVERNMENT_WITHDRAWAL,
};

export const POST_VENTA_PATH = [
  '/post-venta',
  '/buscar-afiliado',
  '/afiliado',
  '/enviar-clave',
  '/ingreso-post-venta',
  '/enviar-clave-seguridad',
  '/enviar-contrato',
  '/seleccionar-periodo',
  '/mi-agenda',
  '/investor-profile',
  '/certificates-vouchers'
];

export const HIDDEN_HEADER_PATHS = [
  '/tubo-negocios',
  '/mi-agenda'
];

export const TIMER_PATH = [
  '/afp',
  '/ingresar/preguntas'
];

// eslint-disable-next-line max-len
export const SEND_PASSWORD_NO_DATA_TEXT = 'El afiliado no tiene correo o teléfono móvil registrados. Ve a “Datos de Contacto” para ingresar un medio para enviarle su clave.';
// eslint-disable-next-line max-len
export const SEND_PASSWORD_TEXT = 'Si el afiliado necesita una clave, se le enviará una clave provisoria a la información registrada en “Datos de Contacto”.';
export const WAITING_TIME_SUCCESS = 30;
export const WAITING_TIME_FAILURE = 5;

export const FUNDS_DESCRIPTION = {
  'A': 'Más Riesgoso',
  'B': 'Riesgoso',
  'C': 'Moderado',
  'D': 'Conservador',
  'E': 'Más Conservador',
};

export const CERTIFICATE_TYPES = {
  'general': [
    {
      name: 'Afiliación',
      description: 'Informa tu fecha de afiliación al Sistema Provisional y tu vigencia como afiliado.', type: 'affiliate'
    },
    {
      name: 'Antecedentes Previsionales',
      description: 'Detalle de tus cuentas personales y resumen de saldos.', type: 'contingency_records'
    },
    // { name: 'Vacaciones Progresiva',
    //   description: 'Cotizaciones acreditadas en cuenta individual desde la incorporación al sistema previsional.', type: 'vacations' },
  ],
  'transactional': [
    { name: 'Cotizaciones', description: 'Detalle de los montos cotizados según las fechas que ingreses.', type: 'quotations' },
    { name: 'Renta Imponible', description: 'Renta imponible de acuerdo a un determinado periodo.', type: 'remunerations' },
  ]
};

export const PUBLIC_CERTIFICATE_TYPES = {
  'general': [
    {
      name: 'Afiliación',
      description: 'Acredita la fecha de ingreso al sistema previsional y de incorporación a AFP PlanVital.', type: 'affiliation'
    },
    {
      name: 'Saldos',
      description: 'Contiene la información de las cuentas vigentes y saldos del afiliado en nuestra administradora.',
      type: 'pensionRecords'
    },
    {
      name: 'Antecedentes de Residencia',
      description: 'Acredita la residencia del afiliado.', type: 'residence'
    },
    {
      name: 'Vacaciones Progresivas',
      description: `Muestra los meses cotizados y su equivalente en años, para certificar si el afiliado cumple con los requisitos
       para solicitar a su empleador días de vacaciones adicionales.`, type: 'progressiveholidays'
    },
  ],
  'transactional': [
    {
      name: 'Cotizaciones', description: 'Muestras las cotizaciones de los últimos 12 meses del afiliado informado por su empleador.',
      type: 'contributions'
    },
    {
      name: 'Remuneraciones', description: 'Muestra las remuneraciones de los últimos 12 meses del afiliado informado por su empleador.',
      type: 'remunerations'
    },
  ]
};

export const TYPE_ACCOUNTS = [
  { type: 'obligatory', display: 'Obligatoria' },
  { type: 'voluntary', display: 'Voluntaria' }
];

export const QUOTE_TIME_PUBLIC_FRAMES = [
  { value: '12', display: 'Últimos 12 meses' },
  { value: '24', display: 'Últimos 24 meses' },
  { value: '36', display: 'Últimos 36 meses' },
  { value: '48', display: 'Últimos 48 meses' },
  { value: '60', display: 'Últimos 60 meses' },
  { value: 'another', display: 'Otro' },
];

export const REMUNERATIONS_TIME_PUBLIC_FRAMES = [
  { value: '12', display: 'Últimos 12 meses' },
  { value: 'another', display: 'Otro' },
];

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MMMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const CERTIFICATE_DESCRIPTIONS = {
  'Cotizaciones': {
    'title': 'Certificado',
    'boldTitle': 'de Cotizaciones',
    'description': 'Tu Certificado de Cotizaciones muestra el detalle de los montos cotizados en AFP PlanVital.',
  },
  'Renta Imponible': {
    'title': 'Renta',
    'boldTitle': 'Imponible',
    'description': 'Tu certificado de Renta Imponible de acuerdo a un determinado periodo.',
  },
};

export const QUOTE_CERTIFICATE_MODAL_MESSAGES = {
  'Cotizaciones': {
    'start': 'Estas por enviar el Certificado de Cotizaciones al mail del afiliado', 'end': '¿Cómo deseas continuar?',
  },
  'Renta Imponible': {
    'start': 'Estas por enviar el Certificado de Renta Imponible al mail del afiliado', 'end': '¿Cómo deseas continuar?',
  }
};

export const NO_CELLPHONE_NUMBER_MESSAGE = {
  'title': '¡Un momento!',
  'description': `Para poder enviar certificados, es necesario ingresar un teléfono móvil.
                  Dirígete a “Datos de Contacto”y actualiza la información del afiliado.`,
};

export const SIMULATOR_DETAILS = [
  {
    'title': 'Hacedor de planes',
    'description': 'Simula cuánto ganarías ahorrando con una cuenta de ahorro voluntario',
    'url': 'https://simuladordeahorro.planvital.cl/',
  },
  {
    'title': 'Tu fondo ideal',
    'description': 'Simula cuánto ganarías ahorrando con una cuenta de ahorro voluntario.',
    'url': 'https://simuladordeahorro.planvital.cl/',
  },
];

export const TRANSFER_STATES = {
  WAITING: 'esperando',
  ANSWERING: 'respondiendo',
  TRANSFERRED: 'traspasado',
  PENDING: 'pendiente',
  ASSISTED_PENDING: 'pendiente asistido',
  CONFIRMED: 'confirmado',
  APPROVED: 'aprobado',
  FINALIZED: 'finalizado',
  NULLIFIED: 'anulado',
  REJECTED: 'rechazado',
};

export const BIODESKTOP_ERRORS = {
  '1': 'El servicio de Autentia indica que la huella no calza con el RUT',
  '3': 'Asegúrese que el programa del huellero está instalado en su equipo',
  '5': 'El usuario canceló el ingreso de su huella',
  'errorService': 'Hubo un problema al cargar tus datos, por favor intenta nuevamente',
  'errorClientValidation': 'Hubo un problema al validar los datos del cliente, por favor intenta nuevamente'
};

export const INACTIVE_SECURITY_PASSWORD_MESSAGE = 'Inactiva';
export const ACTIVE_SECURITY_PASSWORD_MESSAGE = 'Activa';

export const SECURITY_KEY_DOESNT_EXIST_CODE = '90003';

export const TAXABLE_INCOME_RANGES = { '$750.000 o menos': false, 'Mayor a $750.000': true };
export const TRANSFER_ORIGINS = {
  BIODESKTOP_CODE: 'TRW.PDCO',
  BIODESKTOP_RING: 'TRW.PDAN',
  BIODESKTOP_BIOM: 'TRW.PDBI',
  BIODESKTOP_FACI: 'TRW.BIOF',

  BIOTABLET_RING: 'TRW.PANI',
  BIOTABLET_CODE: 'TRW.PCOD',
  BIOTABLET_BIOM: 'TRW.BIOM',
  BIOTABLET_FACI: 'TRW.BIOF',

  SACU_PASSWORD: 'TRW.SACU',
  SECURITY_QUESTIONS: 'TRW.PAFI'
};

export const ADDRESS_NOT_FOUND = 'Sin dirección';

export const ASSISTED_TRANSFER_ORIGIN = 'E';
export const NOT_ASSISTED_TRANSFER_ORIGIN = 'W';

export const MONEY_INPUT_TYPES = [{ code: 'U', value: 'UF' }, { code: 'P', value: 'Pesos' }, { code: 'R', value: '% RIM' }];
export const MONEY_INPUT_TYPES_CODES = { 'U': 'UF', 'P': 'Pesos', 'R': '% RIM' };
export const CLP_MONEY_TYPE = [{ code: 'P', value: 'Pesos' }];

export const CONTRACT_VALIDITIES = ['Temporal', 'Indefinido'];
export const APV_A_REGIME_BENEFITS = `El estado te premia con una bonificación anual del 15% del monto ahorrado, con un tope de 6 UTM y
                                      sólo tributarás por la rentabilidad de dichos recursos al momento del retiro de los fondos.`;
export const APV_B_REGIME_BENEFITS = `Se rebajará de la base imponible el monto que desees ahorrar. Esto implica que según el tramo
                                      de renta en el que te encuentres, obtendrás una rebaja de impuesto, aumentando tu renta líquida.`;
export const APV_DISCOUNT_AUTHORIZATION_TEXT = `Autorizo a mi empleador, a descontar de mi remuneración imponible mensual, el
                                                monto o porcentaje que se indica para enterar en mi cuenta de Ahoro Previsional
                                                Voluntario dentro de los primeros 10 días hábiles.`;
export const APV_DIRECT_DEPOSIT_DISCLAIMER = {
  first: 'Los depósitos directos se deben realizar a través de ',
  strong: 'Previred.',
  last: ' Es necesario que el afiliado se haga una cuenta en la plataforma.'
};

export const APV_EMPLOYER_CARDS_TEXT = { more: 'Ver más ...', less: 'Ver menos ...' };

export const APV_NAME_MAX_LENGTH = 50;
export const PHONE_MAX_LENGTH = 9;
export const PHONE_MIN_LENGTH = 9;

export const UF_INPUT_CODE = 'U';
export const RIM_INPUT_CODE = 'R';
export const PESOS_INPUT_CODE = 'P';

export const FUN_CODES = {
  TRASPASO: 'TRASPASO',
  ACTUALIZADATOS: 'ACTUALIZADATOS',
  CERTIFICADO: 'CERTIFICADO',
  CLAVEACCESO: 'CLAVEACCESO',
  CLAVESEGURIDAD: 'CLAVESEGURIDAD',
};

export const FUN_MODULES = {
  TRASPASO: 'TRASPASO',
  ACTUALIZADATOS: 'ACTUALIZADATOS',
  ANTECEDENTESPREV: 'ANTECEDENTESPREV',
  AFILIACION: 'AFILIACION',
  VACACIONESPROG: 'VACACIONESPROG',
  COTIZACIONES: 'COTIZACIONES',
  RENTA: 'RENTA',
  REVOCARCONTRATO: 'REVOCARCONTRATO',
  CREARCONTRATO: 'CREAR',
  RECUPERARSMS: 'RECUPERARCLIENTESMS',
  RECUPERAREMAIL: 'RECUPERARCLIENTEMAIL',
};

export const ORIGIN_CODES = {
  BTABLET: 'BTABLET',
};

export const CAV_OPENING_DISCLAIMER_TEXT = `Apertura de CAV es solo para Afiliado Dependientes, otros usuarios favor acceder desde el
                                            Sitio Privado de AFP PlanVital`;

export const NO_ILLICIT_ACTIVITY_DECLARATION_TEXT = `Declaro que los recursos no provienen de actividades ilícitas contempladas en la Ley
                                                    19.913 o en cualquier norma que lo modifique.`;

export const CHILE_COUNTRY_CODE = 'CL';
export const GENERAL_REGIME_DESCRIPTION = `El régimen General te permitirá efectuar retiros de la cuenta hasta el tope anual de 30 UTM
                                           libres de impuesto. Si excede ese monto se tributa por la ganancia de capital obtenida.`;
export const DEPENDANT_EMPLOYEE_CODE = 'DN';
export const GENERAL_REGIME_CODE = 'A';
export const EMPLOYEE_INCOME_SOURCE_CODE = 'R';
export const VOLUNTARY_ACCOUNT_CODE = 'CAV';
export const VOLUNTARY_PENSION_ACCOUNT_CODE = 'APV';

export const AFP_PATH = '/afp';
export const TIME_QUESTIONS = 120;
export const TIME_TRANSFER = 900;
export const DELAY_TIMER = 1000;
export const DELAY_GRAPHIC = 500;
export const SECOND_TIMER = 1000;
export const MINUTE_IN_SECONDS = 60;

export const TWO_MINUTES_MESSAGE = 'Tienes solo 2 minutos para responder.';
export const FIFTEEN_MINUTES_MESSAGE = 'Tienes solo 15 minutos para completar el traspaso.';

export const CANCEL_REMOTE_TRANSFER_MODAL_TEXT = 'Estás por anular la solicitud creada de traspaso vía web. ¿Cómo deseas continuar?';
export const CANCEL_REMOTE_PRODUCT_OPENING_MODAL_TEXT = 'Estás por anular la solicitud de apertura de productos. ¿Cómo deseas continuar?';

export const WRONG_CODE_TRANSFER_MODAL_TEXT = 'El código ingresado corresponde a un código de Traspaso';
export const WRONG_CODE_PRODUCT_OPENING_MODAL_TEXT = 'El código ingresado corresponde a un código de apertura de productos';

export const CONTINUE_COMPONENT_TEXTS = {
  productOpening: {
    title: `Continúa con tu solicitud de <br><span class="main-title-bold"> AFP PlanVital</span>`,
    subtitle: `Completa los siguientes datos para continuar tu solicitud asistida telefónicamente desde el Contact Center.`
  },
  transfer: {
    title: `Continúa tu proceso de traspaso <br><span class="main-title-bold"> a AFP PlanVital</span>`,
    subtitle: `Completa los siguientes datos para continuar tu traspaso asistido telefónico desde Contact Center.`
  }
};

export const MIN_DESKTOP_WIDTH = 720;

export const PORTFOLIO_PRODUCTS = ['CCO', 'CAV', 'APV'];

export const TRACE_STEP = {
  updatePersonalInfo: 'Actualizar datos personales',
  updateEmployers: 'Actualizar datos de empleador',
  toCallCenter: 'Derivar a Call Center',
  voucher: 'Vista Voucher',
};

export const START_DATE_UNIX_EPOCH = 1970;

export const MONTH_DICT = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre'
};
export const PLAN_VITAL_WHATSAPP_URL = 'https://api.whatsapp.com/send?phone=56222645200';

const partialText = 'Proceso de validación biométrica ';
export const APPROVED = partialText + 'completada con éxito. Haga click en el botón Continuar para seguir con el proceso de traspaso.';
export const REJECTED = partialText + 'rechazada. No se pudo validar correctamente su identidad.';
export const CANCELED = partialText + 'cancelada ya que se cerró la ventana que realiza el proceso de verificación.';

export const BIO_FACIAL_PROCESS = 'T';
export const BIO_FACIAL_SITE = 'transfer';
export const BIO_FACIAL_MODULE = 'transfer';

export const BUSINESS_TUBE_STATUSES = [{
  id: 'prospect',
  displayName: 'Prospectos',
  singularName: 'Prospecto'
}, {
  id: 'contacted',
  displayName: 'Contactados',
  singularName: 'Contactado'
}, {
  id: 'interviewed',
  displayName: 'Entrevistados',
  singularName: 'Entrevistado'
}, {
  id: 'closed',
  displayName: 'Cierre',
  singularName: 'Cerrado'
}];


export const BUSINESS_TUBE_DEFAULT_FILTERS = [{
  id: 'withoutFilter',
  displayName: 'Sin Filtro',
}, {
  id: 'today',
  displayName: 'Hoy',
}, {
  id: 'thisMonth',
  displayName: 'Este Mes'
}, {
  id: 'lastMonth',
  displayName: 'Mes Anterior'
}, {
  id: 'customDates',
  displayName: 'Personalizado'
}];

export const BUSINESS_TUBE_ERRORS = {
  ALREADY_EXISTS: 'El cliente con ese rut ya existe, intenta con otro rut.',
  CREATE: 'Ha ocurrido un error en el servicio al crear cliente.',
  UPDATE: 'Ha ocurrido un error en el servicio al actualizar cliente.'
};

export const WORD_COMPOUND_NAMES = ['DEL'];

export const COMMON_QUANTITY_NAMES = 4;

export const FB_BIOMETRY_STATUS_CODES = {
  approved: 'aprobado',
  rejected: 'rechazado',
  canceled: 'cancelado',
};

export const FB_BIOMETRY_REMOTE_ACTIONS = {
  close: 'cerrar',
};

export const FACIAL_BIOMETRY_STATUS_TEXTS = {
  waitingValidation: 'En espera de validación facial...',
};

export const FACIAL_BIOMETRY_STEPS = {
  facial: 'facial',
};

export const BUSINESS_TUBE_MODAL_UPDATE_STATUS_TEXTS = {
  archive: {
    title: '¿Desea archivar este cliente?',
    subtitle: 'Antes de realizar el cierre de este cliente podrá archivarlo para no perder sus datos',
    button: 'Sí, quiero archivarlo'
  },
  unarchive: {
    title: '¿Desea desarchivar este cliente?',
    subtitle: 'Si desarchivas este cliente volverá a estado prospecto',
    button: 'Sí, quiero desarchivarlo'
  },
  next: {
    title: '¿Quieres pasar al siguiente estado?',
    subtitle: 'El estado de tu cliente cambiará al contiguo.',
    button: 'Sí, quiero cambiarlo'
  },
  previous: {
    title: '¿Quieres que retroceda el estado de esta tarjeta?',
    subtitle: 'Si hubo algún error de cambio de estado la tarjeta volverá al estado anterior.',
    button: 'Sí, quiero retroceder'
  }
};
