import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';


@Injectable({
    providedIn: 'root'
})
export class DataLayerService {
   private window;

   // eslint-disable-next-line
   constructor(private windowRef: WindowReferenceService) {
       this.window = windowRef.nativeWindow;
   }

    private pingHome(obj): void {
        if (obj) {
            this.window.dataLayer.push(obj);
        }
    }

   public logPageView(url): void {
       const hit = {
           event: 'content-view',
           pageName: url
       };
       this.pingHome(hit);
   }

   public logEvent(event, category, action, label): void {
       const hit = {
           event,
           category,
           action,
           label
       };
        this.pingHome(hit);
   }

   public logCustomDimensionTest(value): void  {
       const hit = {
           event: 'custom-dimension',
           value
       };
       this.pingHome(hit);
   }

}
