import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import {
  CONTACT_URL,
  httpErrorCodes,
  loginErrorCode,
  MAX_LENGTH_RUT,
  onServiceError,
  QUESTIONS_LOGIN_URL,
  TRANSFER_AFP_URL,
  TRANSFER_ORIGINS,
  wrongSacuKey
} from '@constants';
import { SacuLoginData } from '@interfaces/sacuLoginData.interface';
import { GetSecurityQuestionsResponse } from '@interfaces/securityQuestions.interface';
import { GoogleAnalyticsProvider } from '@providers/googleAnalytics/googleAnalytics';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { QuestionsService } from '@services/questions/questions.service';
import { Util } from '@util';
import { ValidateRut } from 'app/validators/rut.validator';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss'],
})

export class OnlineComponent implements OnInit, OnDestroy {
  public loginSacuForm: UntypedFormGroup;
  public loginQuestionsForm: UntypedFormGroup;
  public maxLengthForSecurityKey = 20;
  public maxLengthForRut = MAX_LENGTH_RUT;
  public selectedIndex = 0;
  public loginQuestionsTab = 1;
  public securityQuestionsActivated = false;
  private accountTypeSelection = null;

  // Page Title
  public titlePageInBold = 'Traspaso Online';
  public titlePageNormal = 'Estás por realizar el ';

  // Page Img
  public imgPage = './assets/img/online-tranfer.svg';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private util: Util,
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalProvider: ModalProvider,
    private loadingProvider: LoadingProvider,
    private questionsService: QuestionsService,
    private googleAnalyticsProvider: GoogleAnalyticsProvider,
    private renderer: Renderer2
  ) {
    this.loginSacuForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      securityKey: ['', [Validators.required, Validators.maxLength(this.maxLengthForSecurityKey)]],
    });
    this.loginQuestionsForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      documentId: ['', Validators.required],
    });
    this.googleAnalyticsProvider.registerPageView('online');
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'recaptcha');
  }

  public ngOnInit(): void {
    // setTimeout(() => this.openModal(), 0);
    this.renderer.addClass(document.body, 'recaptcha');
  }

  get isProductionEnvironment() {
    return this.util.isProductionEnvironment();
  }

  get sacuForm() {
    return this.loginSacuForm.controls;
  }

  get questionsForm() {
    return this.loginQuestionsForm.controls;
  }

  get rutSacu() {
    return this.loginSacuForm.controls['rut'] as UntypedFormControl;
  }

  get rutQuestion() {
    return this.loginQuestionsForm.controls['rut'];
  }

  get disableSacuForm() {
    return this.loginSacuForm.invalid || !this.loginSacuForm.dirty || !this.loginSacuForm.touched;
  }

  get disableQuestionsForm() {
    return this.loginQuestionsForm.invalid || !this.loginQuestionsForm.dirty || !this.loginQuestionsForm.touched;
  }

  public sacuLogin() {
    if (this.loginSacuForm.invalid) return;
    sessionStorage.setItem('accountType', this.accountTypeSelection);
    sessionStorage.setItem('traceID', uuidv4());
    localStorage.removeItem('executiveRut');
    this.loadingProvider.showLoading();
    this.authenticationService.sacuLogin(this.loginSacuForm.value as SacuLoginData)
    .toPromise()
    .then(() => this.loginSuccessHandler())
    .catch(error => this.handleError(error))
    .finally(() => this.loadingProvider.hideLoading());
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedIndex = tabChangeEvent.index;
  }

  public getSecurityQuestions() {
    this.loadingProvider.showLoading();
    if (this.loginQuestionsForm.invalid) return;
    const { rut, documentId } = this.loginQuestionsForm.value;
    this.questionsService.getSecurityQuestions(rut, documentId, null, null, TRANSFER_ORIGINS.SECURITY_QUESTIONS)
      .toPromise()
      .then((response: GetSecurityQuestionsResponse) => this.handleQuestionsLoginSuccess(response, rut, documentId))
      .catch((error) => this.defaultErrorHandler(error))
      .finally(() => this.loadingProvider.hideLoading());
  }

  public handleError(error) {
    error = error.error || error;

    if (!error.code) {
      error = onServiceError;
    }
    const { message, messageDescription } = error;

    this.modalProvider.openCustomErrorModal({ message, messageDescription })
      .afterClosed()
      .subscribe(() => {
        if (error.statusCode && this.checkCode(error)) this.selectedIndex = this.loginQuestionsTab;
      });
  }

  public openSecurityKeyInfoModal() {
    this.modalProvider.openSecurityKeyInfoModal();
  }

  public openOtherAfpsContactModal() {
    this.modalProvider.openOtherAfpsContactModal();
  }

  public openDocumentIdInfoModal() {
    this.modalProvider.openDocumentIdInfoModal();
  }

  public goToContact() {
    this.router.navigateByUrl(CONTACT_URL);
  }

  public removeRutFormat() {
    this.sacuForm.rut.setValue(this.util.rutClean(this.sacuForm.rut.value));
    this.questionsForm.rut.setValue(this.util.rutClean(this.questionsForm.rut.value));
  }

  public addRutFormat() {
    this.sacuForm.rut.setValue(this.util.rutFormat(this.sacuForm.rut.value));
    this.questionsForm.rut.setValue(this.util.rutFormat(this.questionsForm.rut.value));
  }

  public securityKeyIsNotValid() {
    return this.sacuForm.securityKey.hasError('maxlength') && this.sacuForm.securityKey.touched;
  }

  public securityKeyIsWrong() {
    return this.sacuForm.securityKey.hasError('wrongsecurityKey') && this.sacuForm.securityKey.touched;
  }

  private handleQuestionsLoginSuccess(request: GetSecurityQuestionsResponse, rut: string, documentId: string) {
    const { questions, transactionId, interactiveQuestionId, isSinacofi } = request;
    sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.SECURITY_QUESTIONS);
    this.router.navigate([QUESTIONS_LOGIN_URL],
      { state: { questions, transactionId, interactiveQuestionId, rut, documentId, isSinacofi } });
  }

  private defaultErrorHandler(error) {
    error = error.error || error;
    const { extraData, statusCode } = error;
    const internalServerError = httpErrorCodes.internalServerError.code;
    const isServiceError = !statusCode || statusCode === internalServerError;
    if (isServiceError || !extraData.length) return this.modalProvider.openGenericErrorModal(onServiceError);
    this.modalProvider.openSecurityQuestionsErrorModal(extraData, extraData.length);
  }

  private async loginSuccessHandler() {
    sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.SACU_PASSWORD);
    await this.router.navigate([TRANSFER_AFP_URL], { state: { formValue: this.accountTypeSelection } });
  }

  private checkCode(error) {
    return error.code !== wrongSacuKey && error.code !== loginErrorCode;
  }

  // private openModal() {
  //   this.modalProvider.openCustomContactModal({
  //     primaryCallback: () => {},
  //     secondaryCallback: () => this.location.back(),
  //   });
  // }
}
