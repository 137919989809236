import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ClientBusinessTube } from '@interfaces/clientsBusinessTube.interface';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { BusinessTubeComponent } from 'app/modules/post-venta/business-tube/business-tube.component';
import { BusinessTubeConfirmationComponent } from '../confirmation/confirmation.component';
import { BUSINESS_TUBE_ERRORS, BUSINESS_TUBE_STATUSES } from '@constants';
import { finalize } from 'rxjs/operators';
import { BusinessTubeCloseClientModalComponent } from '../close-client-modal/close-client-modal.component';
import { ComponentType } from '@angular/cdk/portal';

@Component({
  selector: 'app-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss']
})

export class BusinessTubeClientCardComponent {
  @Input() public client: ClientBusinessTube;
  @Input() public isFromSearcher: boolean;
  @Input() public isArchivedView: boolean;
  @Output() public loading = new EventEmitter();
  @Output() public updatedStatus = new EventEmitter();
  @Output() public serviceError = new EventEmitter();
  @Output() public selectedClient = new EventEmitter();
  private innerWidth: number;

  constructor(
    private postVentaService: PostVentaService,
    private matDialog: MatDialog,
    public businessTubeComponent: BusinessTubeComponent
  ) {
    this.innerWidth = window.innerWidth;
  }

  public get statusDescription(): string {
    return BUSINESS_TUBE_STATUSES.find((status) => status.id === this.client.status).singularName;
  }

  get isClosed() {
    return this.client.status === 'closed';
  }

  public disabledStatusChange(status: string, limit: string) {
    return status === limit;
  }

  public emitSelectedClient(client: ClientBusinessTube) {
    this.selectedClient.emit(client);
  }

  public getBusinessExemptPaymentDescription(businessRut: string, businessExempt: boolean): string {
    return this.businessTubeComponent.getBusinessExemptPaymentDescription(businessRut, businessExempt);
  }

  public changeStatus(client: ClientBusinessTube, action: string) {
    let isBiodesktop = false;

    if (this.innerWidth > 420) isBiodesktop = true;

    const modalComponent: ComponentType<any> = this.isClosing(client, action) ?
      BusinessTubeCloseClientModalComponent : BusinessTubeConfirmationComponent;

    this.matDialog.open(modalComponent, {
      maxWidth: '95vw',
      width: isBiodesktop ? '50vw' : '95vw',
      height: isBiodesktop ? '50vh' : '99vh',
      data: { action, status: client.status }
    }).afterClosed().subscribe((response) => {
      if (response) {
        this.loading.emit(true);
        if (this.isClosing(client, action)) client.closingMonth = response;
        if (this.isArchiving(client, action)) return this.updateClientArchiveStatus(client);
        this.updateClient(client, action);
      }
    });
  }

  private isClosing(client: ClientBusinessTube, action: string): boolean {
    return client.status === 'interviewed' && action === 'next';
  }

  private isArchiving(client: ClientBusinessTube, action: string): boolean {
    return client.status === 'closed' && action === 'next';
  }

  private updateClient(client: ClientBusinessTube, action: string) {
    const previousStatus = client.status;
    const index = BUSINESS_TUBE_STATUSES.findIndex((status) => status.id === client.status);
    const updatedStatus = action === 'next' ? BUSINESS_TUBE_STATUSES[index + 1] : BUSINESS_TUBE_STATUSES[index - 1];
    client.status = updatedStatus.id;
    client.previousStatus = previousStatus;
    this.postVentaService.updateClientBusinessTube(client).pipe(
      finalize(() => this.loading.emit(false)))
      .subscribe(() => {
        this.updatedStatus.emit(updatedStatus);
      },
        () => this.serviceError.emit(BUSINESS_TUBE_ERRORS.UPDATE));
  }

  private updateClientArchiveStatus(client: ClientBusinessTube) {
    const request = {
      ...client,
      archived: !Boolean(client.archived)
    };
    this.postVentaService.updateClientArchiveStatus(request).pipe(
      finalize(() => this.loading.emit(false)))
      .subscribe(() => {
        this.updatedStatus.emit(BUSINESS_TUBE_STATUSES[3]);
      },
        () => this.serviceError.emit(BUSINESS_TUBE_ERRORS.UPDATE));
  }
}
