import { Component } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  APPROVED, BIO_FACIAL_MODULE, BIO_FACIAL_PROCESS,
  BIO_FACIAL_SITE, CANCELED,
  CONFIRM_TRANSFER,
  CONTINUE_COMPONENT_TEXTS,
  FACIAL_BIOMETRY_STATUS_TEXTS,
  FACIAL_BIOMETRY_STEPS,
  FB_BIOMETRY_REMOTE_ACTIONS,
  FB_BIOMETRY_STATUS_CODES,
  MAX_LENGTH_RUT, MIN_DESKTOP_WIDTH, QUESTIONS_LOGIN_URL,
  REJECTED, TRANSFER_ORIGINS, TRANSFER_STATES, TRANSFER_URL,
  WRONG_CODE_PRODUCT_OPENING_MODAL_TEXT, WRONG_CODE_TRANSFER_MODAL_TEXT,
  httpErrorCodes, onServiceError
} from '@constants';
import { environment } from '@env';
import { ClientValidationData } from '@interfaces/client.interface';
import { LogInterface } from '@interfaces/log.interface';
import { GetSecurityQuestionsResponse } from '@interfaces/securityQuestions.interface';
import { GoogleAnalyticsProvider } from '@providers/googleAnalytics/googleAnalytics';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { LogService } from '@services/logs/log.service';
import { QuestionsService } from '@services/questions/questions.service';
import { Util } from '@util';
import { EnvInterface } from 'environments/env';
import { ValidateRut } from 'app/validators/rut.validator';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-continue',
  templateUrl: './continue.component.html',
  styleUrls: ['./continue.component.scss'],
})

export class ContinueComponent {
  public codeForm: UntypedFormGroup;
  public loginQuestionsForm: UntypedFormGroup;
  public maxLengthForSecurityKey = 20;
  public maxLengthForRut = MAX_LENGTH_RUT;
  public selectedIndex = 0;
  public loginQuestionsTab = 1;
  public selectedSegment = 1;
  public step = 'code';
  public firebaseId: string;
  public codeStates = TRANSFER_STATES;
  public isCodeActive = true;
  public isProductOpening = false;
  public isFromLink = true;
  public texts: any;
  public wrongPath = false;
  public segments = {
    transfer: { id: 1 },
    product: { id: 2 }
  };
  public status: string;
  public remoteAction: string;
  public acceptDisclaimer = false;
  public clientName: string;
  private environment: EnvInterface;
  private fbData: ClientValidationData;
  private ngUnsubscribe = new Subject();
  private newWindow: any;
  public textStatus: string;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private util: Util,
    private router: Router,
    private modalProvider: ModalProvider,
    private loadingProvider: LoadingProvider,
    private questionsService: QuestionsService,
    private googleAnalyticsProvider: GoogleAnalyticsProvider,
    private firebaseDatabase: AngularFireDatabase,
    private logService: LogService,
  ) {
    this.environment = environment;

    this.codeForm = this.formBuilder.group({
      code: ['', [Validators.required]]
    });
    this.loginQuestionsForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      documentId: ['', Validators.required],
    });
    this.googleAnalyticsProvider.registerPageView('continue');
    this.checkProductOpening();
    this.setTexts();
  }

  get disableCodeForm(): boolean {
    return this.codeForm.invalid || !this.codeForm.dirty || !this.codeForm.touched;
  }

  get rutQuestion(): UntypedFormControl {
    return this.loginQuestionsForm.controls['rut'] as UntypedFormControl;
  }

  get disableQuestionsForm(): boolean {
    return this.loginQuestionsForm.invalid || !this.loginQuestionsForm.dirty || !this.loginQuestionsForm.touched;
  }

  get showWaitingMsg(): boolean {
    return (
      this.status !== FB_BIOMETRY_STATUS_CODES.approved
      && this.status !== FB_BIOMETRY_STATUS_CODES.rejected
      && this.status !== FB_BIOMETRY_STATUS_CODES.canceled
    );
  }

  public get isDesktop(): boolean {
    return window.innerWidth > MIN_DESKTOP_WIDTH;
  }

  private checkProductOpening() {
    this.isFromLink = this.router.url.includes('apertura');
    this.isProductOpening = this.isFromLink;
  }

  private setTexts() {
    this.texts = this.isProductOpening ? CONTINUE_COMPONENT_TEXTS.productOpening : CONTINUE_COMPONENT_TEXTS.transfer;
  }

  public changeSegmentSelection(event: number) {
    this.selectedSegment = event;
    this.isProductOpening = this.selectedSegment === this.segments.product.id;
    this.setTexts();
  }

  public async validateCode() {
    localStorage.removeItem('executiveRut');
    if (this.environment.mockHttpCalls) return this.mockFirebaseIdCode();

    this.loadingProvider.showLoading();
    const response = await this.validateRecaptcha();
    this.loadingProvider.hideLoading();
    if (typeof response === 'undefined' || !response.isValid) {
      this.defaultErrorHandler({});
      return;
    }

    this.isCodeActive = true;
    const { code } = this.codeForm.value;
    const validation = this.firebaseDatabase.object(`client_code/${code}`);
    await this.observeFirebaseCode(validation);
  }

  public getSecurityQuestions() {
    this.loadingProvider.showLoading();
    if (this.loginQuestionsForm.invalid) return;
    const { documentId } = this.loginQuestionsForm.value;
    const rut = this.util.rutClean(this.loginQuestionsForm.getRawValue().rut);
    this.questionsService.getSecurityQuestions(rut, documentId, this.firebaseId)
      .toPromise()
      .then((response: GetSecurityQuestionsResponse) => this.handleQuestionsLoginSuccess(response, rut, documentId))
      .catch((error) => this.defaultErrorHandler(error))
      .finally(() => this.loadingProvider.hideLoading());
  }

  public openDocumentIdInfoModal() {
    this.modalProvider.openDocumentIdInfoModal();
  }

  public handleQuestionsLoginSuccess(request: GetSecurityQuestionsResponse, rut: string, documentId: string) {
    const { questions, transactionId, interactiveQuestionId, isSinacofi } = request;
    this.router.navigate([QUESTIONS_LOGIN_URL], {
      state: {
        questions, transactionId, interactiveQuestionId,
        rut, documentId, firebaseId: this.firebaseId,
        isSinacofi
      }
    });
  }

  private async validateRecaptcha() {
    return await this.authenticationService.validateRecaptcha()
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(() => {
        this.defaultErrorHandler({});
      });
  }

  private mockFirebaseIdCode() {
    this.firebaseId = 'firebaseIdMock';
    this.rutQuestion.patchValue(this.util.rutFormat(this.util.rutClean('1-9')));
    this.rutQuestion.disable();
    this.step = 'login';
  }

  private async observeFirebaseCode(validation: AngularFireObject<unknown>) {
    validation.valueChanges().pipe(first()).subscribe((firebaseId: string) => {
      if (!firebaseId) {
        this.codeForm.markAsUntouched();
        return this.codeForm.controls['code'].setErrors({ 'incorrect': true });
      }
      this.firebaseId = firebaseId;
      const ref = this.firebaseDatabase.object(`client/${this.firebaseId}`);
      ref.valueChanges().pipe(first()).subscribe((data: any) => {
        sessionStorage.setItem('traceID', data.traceId);
        sessionStorage.setItem('originTransfer', data.origin);
        localStorage.setItem('executiveRut', data.executiveRut);
      });
      this.compareCodeWithFirebaseCode(ref);
    });
  }

  private compareCodeWithFirebaseCode(ref: AngularFireObject<unknown>) {
    ref.valueChanges().pipe(first()).subscribe((firebaseData: ClientValidationData) => {
      if (firebaseData.productOpening !== undefined) this.checkWrongPath(firebaseData.productOpening);
      if (this.wrongPath) return;
      if (this.checkFirebaseDataStatus(firebaseData)) {
        this.isCodeActive = false;
        return this.codeForm.controls['code'].setErrors({ 'incorrect': true });
      }
      this.fbData = firebaseData;
      this.clientName = this.fbData.name;
      if (firebaseData.origin === TRANSFER_ORIGINS.BIODESKTOP_FACI) {
        this.textStatus = FACIAL_BIOMETRY_STATUS_TEXTS.waitingValidation;
        this.step = FACIAL_BIOMETRY_STEPS.facial;
        this.observeFacialStatus();
        this.setFacialBiometricLoginAction();
        return;
      }
      this.rutQuestion.patchValue(this.util.rutFormat(this.util.rutClean(firebaseData.rut)));
      this.rutQuestion.disable();
      this.step = 'login';
    });
  }

  private observeFacialStatus() {
    const ref = this.firebaseDatabase.object(`client/${this.firebaseId}`);
    ref.valueChanges().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ClientValidationData) => {
        this.status = data.status;
        this.remoteAction = data.remoteAction;
        if ((this.status === FB_BIOMETRY_STATUS_CODES.approved
          || this.status === FB_BIOMETRY_STATUS_CODES.rejected
          || this.status === FB_BIOMETRY_STATUS_CODES.canceled
        ) && this.remoteAction === FB_BIOMETRY_REMOTE_ACTIONS.close) {
          this.newWindow.close();
        }
        if (this.status === FB_BIOMETRY_STATUS_CODES.approved) {
          this.textStatus = APPROVED;
        }
        if (this.status === FB_BIOMETRY_STATUS_CODES.rejected) {
          this.textStatus = REJECTED;
        }
        if (this.status === FB_BIOMETRY_STATUS_CODES.canceled) {
          this.textStatus = CANCELED;
        }
        if (this.status === FB_BIOMETRY_STATUS_CODES.approved && this.remoteAction === FB_BIOMETRY_STATUS_CODES.approved) {
          this.closeMinisite();
        }
      });
  }

  private setTimeout() {
    return new Promise<void>((resolve) => setTimeout(resolve, 30000)); // 30000 ms = 30 sec
  }

  private async closeMinisite() {
    await this.setTimeout();
    if (this.newWindow) this.newWindow.close();
  }

  private checkWrongPath(productOpeningCode: boolean) {
    if (this.isProductOpening !== productOpeningCode) {
      this.wrongPath = true;
      const messageDescription = productOpeningCode ? WRONG_CODE_PRODUCT_OPENING_MODAL_TEXT : WRONG_CODE_TRANSFER_MODAL_TEXT;
      return this.modalProvider.openCustomErrorModal({
        message: 'Error en el código ingresado',
        messageDescription
      })
        .afterClosed().subscribe(() => {
          if (!this.isFromLink) return;
          this.router.navigateByUrl(TRANSFER_URL);
        });
    }
    this.wrongPath = false;
  }

  private checkFirebaseDataStatus(firebaseData: ClientValidationData) {
    return firebaseData.status !== this.codeStates.PENDING && firebaseData.status !== this.codeStates.ASSISTED_PENDING;
  }

  private defaultErrorHandler(error) {
    error = error.error || error;
    const { extraData, statusCode } = error;
    const internalServerError = httpErrorCodes.internalServerError.code;
    const isServiceError = !statusCode || statusCode === internalServerError;
    if (isServiceError || !extraData.length) return this.modalProvider.openGenericErrorModal(onServiceError);
    this.modalProvider.openSecurityQuestionsErrorModal(extraData, extraData.length);
  }

  public async goToFacialSite(): Promise<void> {
    const facialValidationURL = environment.bioFacialSite;
    const dataToCode = {
      name: this.fbData.name,
      process: BIO_FACIAL_PROCESS,
      rut: this.fbData.rut,
      transferId: this.firebaseId,
      site: BIO_FACIAL_SITE,
      module: BIO_FACIAL_MODULE
    };

    const authorizedToken = await this.authenticationService.facialBiometricAuthTrolley(dataToCode).toPromise();
    const params = { token: authorizedToken };

    // const base64Encode = btoa(JSON.stringify(dataToCode));
    // const params = { trolley: base64Encode };

    const paramsString = Object.keys(params)
      .map(key => `${key}=${params[key]}`);

    this.newWindow = window.open(`${facialValidationURL}?${paramsString}`, '_blank');

    if (this.newWindow) {
      this.newWindow.focus();
    }
    this.step = 'facialWaiting';
  }

  public async setFacialBiometricLoginAction(): Promise<void> {
    const facialBiometryRef = this.firebaseDatabase.database
      .ref(`client/${this.firebaseId}/facialBiometry`);

    facialBiometryRef.on('value', (async biometrySnapshot => {
      if (biometrySnapshot.val()) {
        // eslint-disable-next-line max-len
        const message = `ContinueComponent.setFacialBiometricLoginAction - trigger redirection to ${CONFIRM_TRANSFER} - transferId: ${this.firebaseId}`;
        this.sendLog(biometrySnapshot.val(), false, message);
        await this.router.navigate([CONFIRM_TRANSFER], { state: { firebaseId: this.firebaseId } });
      }
    }));
  }

  public async cancelProcess() {
    await this.firebaseDatabase.object(`client/${this.firebaseId}`).update({
      status: TRANSFER_STATES.NULLIFIED,
    });
    this.step = 'code';
  }

  private sendLog(logObject: any, isError: boolean, typeLog: string) {
    const request = {
      logObject,
      isError,
      customMessage: `[WebTransfer] Response of ${typeLog} transfer`,
    } as LogInterface;
    this.logService.sendLog(request);
  }
}
