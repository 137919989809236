import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { VALIDATION_OPERATIONS } from '@constants';
import { environment } from '@env';
import {
  BiometricLoginData, FacialBiometricLoginData,
  SecurityQuestionsBiometricLoginData
} from '@interfaces/biometricLoginData.interface';
import { SendRemoteLoginCode } from '@interfaces/client.interface';
import { MessagingAuthenticationResponse } from '@interfaces/messaging-authentication.interface';
import { SacuLoginData } from '@interfaces/sacuLoginData.interface';
import { RECAPTCHA_VALIDATOR_MOCK } from '@mocks/recaptchaValidator.mock';
import { HttpClientInterceptor } from '@providers/httpClientInterceptor/httpClientInterceptor';
import { SessionProvider } from '@providers/session/session';
import { Util } from '@util';
import { Observable } from 'rxjs';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { EXECUTIVE_RUT, EXECUTIVE_SESSION } from 'util/storage.constants';
import { BIOMETRIC_AUTHENTICATION_MOCKUP } from './mocks/biometricAuthentication.mock';
import { EXECUTIVE_AUTHENTICATION_MOCKUP } from './mocks/executiveAuthentication.mock';
import { MESSAGING_AUTHENTICATION_MOCKUP, SACU_AUTHENTICATION_MOCKUP } from './mocks/sacuAuthentication.mock';
import { SEND_ASSISTED_CODE_MOCKUP } from './mocks/sendCodeEmail.mock';
import { TOKEN_MOCK } from './mocks/token.mock';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public baseURL: string;
  private executiveRut: string;
  private biometricParams = {
    COLOR_PRIMARY: '#930800',
    TITLE: 'Traspaso AFP PlanVital',
    OTI: true,
  };
  private uidFirebase: string;

  constructor(
    public http: HttpClientInterceptor,
    public util: Util,
    public firebaseAuth: AngularFireAuth,
    public sessionProvider: SessionProvider,
  ) {
    this.baseURL = environment.baseURL;
    this.firebaseAuth.onAuthStateChanged(async user => {
    this.uidFirebase = (await this.firebaseAuth.currentUser).uid;
    });
  }

  public sacuLogin({ rut, securityKey }: SacuLoginData) {
    const data = { rut: this.util.rutClean(rut), securityKey, ip: '1.1.1.1', traceID: this.util.getTraceID() };
    return this.http.post(`${this.baseURL}authentication/unique-key`, data, SACU_AUTHENTICATION_MOCKUP)
      .pipe(
        mergeMap(async (response) => await this.setCurrentUserData(response.token)),
        catchError(error => this.util.generalCatchError(error)),
      );
  }

  public async messagingAccountsLogin({ rut, securityKey, ticket }): Promise<MessagingAuthenticationResponse> {
    const data = { rut: this.util.rutClean(rut), securityKey, ticket, ip: '1.1.1.1', traceID: this.util.getTraceID() };
    return this.http.post(`${this.baseURL}whatsapp/login-security-key`, data, MESSAGING_AUTHENTICATION_MOCKUP).toPromise();
  }

  public async logout() {
    sessionStorage.removeItem(EXECUTIVE_SESSION);
    return await this.firebaseAuth.signOut().catch(() => { });
  }

  public async setCurrentUserData(token: string) {
    await this.logout();
    this.sessionProvider.setSessionEvents();
    if (environment.mockHttpCalls) {
      return sessionStorage.setItem('currentUser', JSON.stringify({ displayName: 'Valentina Torres', uid: '17750775' }));
    }
    const credential = await this.firebaseAuth.signInWithCustomToken(token);

    await credential.user.getIdToken();
    const uid = this.uidFirebase;
    sessionStorage.setItem('currentUser', JSON.stringify({ uid }));
  }

  public async setAffiliateIdToken(token: string) {
    await this.logout();
    this.sessionProvider.setSessionEvents();
    if (environment.mockHttpCalls) {
      return sessionStorage.setItem('affiliateIdToken', '17750775');
    }
    const credential = await this.firebaseAuth.signInWithCustomToken(token);
    const idToken = await credential.user.getIdToken();
    sessionStorage.setItem('affiliateIdToken', idToken);
  }

  public async validateToken(): Promise<boolean> {
    const firebaseCurrent = await this.firebaseAuth.currentUser;
    return firebaseCurrent.getIdToken(true).then((idToken) => {
      sessionStorage.setItem('affiliateIdToken', idToken);
      return true;
    }).catch(() => {
      return false;
    });
  }

  public removeCurrentUserData() {
    sessionStorage.removeItem('currentUser');
  }

  public isAuthenticated(): boolean {
    return Boolean(this.firebaseAuth.currentUser);
  }

  public executiveLogin(executiveRut: string): Observable<any> {
    return this.http.get(`${this.baseURL}executives/?rut=${executiveRut}`, EXECUTIVE_AUTHENTICATION_MOCKUP).pipe(
      finalize(() => {
        this.setExecutiveRut(executiveRut);
      }),
      catchError(error => {
        return this.util.generalCatchError(error);
      })
    );
  }

  public biometricLogin(biometricData: BiometricLoginData, origin: string) {
    const { rut } = biometricData.executive;
    const data = {
      ...biometricData,
      originCode: origin,
      traceID: this.util.getTraceID()
    };
    this.executiveRut = `${rut}`;
    return this.http.post(`${this.baseURL}authentication/biometric`, data, BIOMETRIC_AUTHENTICATION_MOCKUP)
      .pipe(
        mergeMap(async (response) => {
          await this.setCurrentUserData(response.token);
          return response;
        }),
        catchError(error => this.util.generalCatchError(error))
      );
  }

  public securityQuestionsBiometricLogin(securityQuestionsBiometricData: SecurityQuestionsBiometricLoginData) {
    const { rut } = securityQuestionsBiometricData.executive;
    this.executiveRut = `${rut}`;
    const data = {
      ...securityQuestionsBiometricData,
      originCode: this.util.getOriginTransfer(),
      traceID: this.util.getTraceID()
    };
    return this.http.post(`${this.baseURL}authentication/biometric-security-questions`,
      data, BIOMETRIC_AUTHENTICATION_MOCKUP)
      .pipe(
        mergeMap(async (response) => {
          await this.setCurrentUserData(response.token);
          return response;
        }),
        catchError(error => this.util.generalCatchError(error))
      );
  }

  public signInAnonymously() {
    return this.firebaseAuth.signInAnonymously()
      .then(async (response) => {
        const token = await response.user.getIdToken();
        const uid = response.user.uid;
        return { token, uid };
      })
      .catch(() => null);
  }

  private setExecutiveSession(rut: string) {
    sessionStorage.setItem(EXECUTIVE_SESSION, rut);
  }

  public biometricUrl(rut: string, token: string, uid: string, barcode?: string, isPostVenta?: boolean) {
    const pageId = this.generatePageId();
    const params = {
      ...this.biometricParams,
      RUT: Number(rut.slice(0, -1)),
      DV: rut.slice(-1),
      callback: `${environment.biometricCallbackUrl}&pageId=${pageId}&token=${token}&uid=${uid}`
    };
    this.setPostVenta(isPostVenta, params);
    if (barcode) params['BARCODE'] = barcode;
    const encodeURI = encodeURIComponent(JSON.stringify(params));
    const url = `${environment.biometricApkUrl}.${VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION}&params=${encodeURI}`;

    return { url, pageId };
  }

  public biometricUrlForIdentityCard(token: string, uid: string, isPostVenta?: boolean) {
    const pageId = this.generatePageId();
    const params = {
      ...this.biometricParams,
      callback: `${environment.biometricCallbackUrl}&pageId=${pageId}&token=${token}&uid=${uid}`
    };
    this.setPostVenta(isPostVenta, params);
    const encodeURI = encodeURIComponent(JSON.stringify(params));
    const url = `${environment.biometricApkUrl}.${VALIDATION_OPERATIONS.BARCODE}&params=${encodeURI}`;

    return { url, pageId };
  }

  public getExecutiveRut() {
    this.executiveRut = localStorage.getItem(EXECUTIVE_RUT);
    return this.executiveRut;
  }

  public uid() {
    const { uid } = environment.mockHttpCalls ? { uid: '19' } : { uid: this.uidFirebase };
    return uid;
  }

  public setExecutiveRut(rut: string) {
    this.executiveRut = rut;
    localStorage.removeItem(EXECUTIVE_RUT);
    localStorage.setItem(EXECUTIVE_RUT, rut);
    this.setExecutiveSession(rut);
  }

  public getToken(rut: string) {
    const requestData = { 'rut': rut };
    return this.http.post(`${environment.baseURL}post-venta/get-token/`, requestData, TOKEN_MOCK)
      .pipe(catchError(error => this.util.generalCatchError(error)));
  }

  public sendAssistedCode(request: SendRemoteLoginCode, rut: string) {
    this.getExecutiveRut();
    const data = {
      ...request,
      traceID: this.util.getTraceID(),
      originCode: this.util.getOriginTransfer(),
      executiveRut: this.getExecutiveRut(),
      rut
    };
    return this.http.post(`${this.baseURL}authentication/send-assisted-code`, data, SEND_ASSISTED_CODE_MOCKUP)
      .pipe(catchError(error => this.util.generalCatchError(error)));
  }

  private generatePageId() {
    return (Math.floor(Math.random() * 10000000) + 1 + Date.now()).toString(16);
  }

  private setPostVenta(isPostVenta: boolean, params) {
    if (isPostVenta) {
      params.TRASPASO = false;
      params.PREVIRED = false;
      params.TITLE = 'Asistencia AFP PlanVital';
      params.OTI = false;
    }
  }

  public validateRecaptcha() {
    return this.http.get(`${this.baseURL}recaptcha-validator/`, RECAPTCHA_VALIDATOR_MOCK)
      .pipe(catchError(error => this.util.generalCatchError(error)));
  }

  public facialBiometricLogin(data: FacialBiometricLoginData): Observable<FacialBiometricLoginResponse> {
    return this.http.post(`${this.baseURL}authentication/facial-biometric`, data, {} as any)
      .pipe(
        mergeMap(async (response) => {
          await this.setCurrentUserData(response.token);
          return response;
        }),
        catchError(error => this.util.generalCatchError(error))
      );
  }

  public facialBiometricAuthTrolley(data: any): Observable<{ token: string }> {
    return this.http.post(`${environment.apiwebBaseUrl}transfer-facial-biometric/trolley-auth/authorize`, data, {} as any)
      .pipe(
        mergeMap(async (response) => {
          return response.token;
        }),
        catchError(error => this.util.generalCatchError(error))
      );
  }

}

export interface FacialBiometricLoginResponse {
  token: string;
  success: boolean;
}
