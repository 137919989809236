import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from '@env';
import { ClientInfo, GovernmentCheckResponse, PlanVitalAccount } from '@interfaces/client.interface';
import { DefaultObject } from '@interfaces/defaultObject.interface';
import { VerifyBlockedClientResponse } from '@interfaces/verifyBlockedClient.interface';
import { PV_ACCOUNTS_MOCKUP } from '@mocks/accountsInPV.mock';
import { CLIENT_MOCKUP } from '@mocks/client.mock';
import { GOVERNMENT_CHECK_MOCK } from '@mocks/governmentCheck.mock';
import { HttpClientInterceptor } from '@providers/httpClientInterceptor/httpClientInterceptor';
import { Util } from '@util';
import { CLIENT_DATA } from 'util/storage.constants';

import { AttentionCodeResponse } from '@interfaces/attentionCodeResponse';
import { AuthenticationService } from '../authentication/authentication.service';
import { ATTENTION_CODE_MOCK } from './mocks/attention-code.mock';
import { EMPLOYEE_TYPES_MOCKUP } from './mocks/employeeTypes.mock';
import { REGIONS_MOCKUP } from './mocks/regions.mock';
import { VALIDATE_EMAIL_DOMAIN_MOCKUP } from './mocks/validateEmailDomain.mock';
import { VERIFY_BLOCKED_CLIENT } from './mocks/verifyBlockedClient.mock';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  public baseURL: string;
  public baseAppURL: string;

  constructor(
    public http: HttpClientInterceptor,
    public util: Util,
    public authenticationService: AuthenticationService,
  ) {
    this.baseAppURL = environment.baseAppURL;
    this.baseURL = environment.baseURL;
  }

  public checkIsGovernmentBeneficiary(): Observable<GovernmentCheckResponse> {
    const { rut } = JSON.parse(localStorage.getItem(CLIENT_DATA));
    return this.http.post(`${this.baseAppURL}withdrawals/v3/bonus/check-requirement/session/`, { rut }, GOVERNMENT_CHECK_MOCK).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getClient(): Observable<ClientInfo> {
    const uid = this.authenticationService.uid();
    let url = `${this.baseURL}clients/?rut=${uid}&origin=${this.util.getOriginTransfer()}&traceID=${this.util.getTraceID()}`;
    const executiveRut = this.authenticationService.getExecutiveRut();
    if (executiveRut) url += `&executiveRut=${executiveRut}`;

    return this.http.get(url, CLIENT_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getAccountsInPV(): Observable<Array<PlanVitalAccount>> {
    const uid = this.authenticationService.uid();
    return this.http.get(`${this.baseURL}clients/accounts/?rut=${uid}`, PV_ACCOUNTS_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getEmployeeTypes(rut?: string): Observable<Array<DefaultObject>> {
    const uid = rut ? rut : this.authenticationService.uid();
    return this.http.get(`${this.baseURL}employers/employee-types/?rut=${uid}`, EMPLOYEE_TYPES_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public getRegions() {
    return this.http.get(`${this.baseURL}parameters/regions/`, REGIONS_MOCKUP).pipe(
      mergeMap((response) => of(this.util.checkSpelling(response))),
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public verifyBlockedClient(rut: string): Observable<VerifyBlockedClientResponse> {
    rut = this.util.rutClean(rut);
    return this.http.get(`${this.baseURL}clients/block/verify/?rut=${rut}`, VERIFY_BLOCKED_CLIENT).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public validateEmailDomain(email: string): Observable<any> {
    return this.http.get(`${this.baseURL}clients/validate/email/?email=${email}`, VALIDATE_EMAIL_DOMAIN_MOCKUP).pipe(
      catchError(error => this.util.generalCatchError(error))
    );
  }

  public sendAttentionCode(rut: string, origin: string): Observable<AttentionCodeResponse> {
    return this.http.post(`${environment.baseURL}clients/attention-code/`, { rut, origin }, ATTENTION_CODE_MOCK);
  }

  public logFirstStepApv(rut: string, executiveName: string) {
    return this.http.post(`${environment.baseAppURL}log/`,
      { rut, type: 'apv_opening_discount', step: 1, product: null, executiveName }, ATTENTION_CODE_MOCK);
  }
}
