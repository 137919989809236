import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BUSINESS_TUBE_URL, CERTIFICATE_VOUCHERS_URL, MY_PORTFOLIO_URL, POST_VENTA_LOGIN_URL, SEARCH_AFFILIATE_URL } from '@constants';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';
import { DEVICE_TYPE, FUNCTIONALITY } from 'util/storage.constants';

@Component({
  selector: 'app-functionality-selection',
  templateUrl: './functionality-selection.component.html',
  styleUrls: ['./functionality-selection.component.scss']
})
export class FunctionalitySelectionComponent implements OnInit {
  public loading = true;
  public biotablet: boolean;

  constructor(
    private router: Router,
    private inactivityService: InactivityService
  ) { }

  public ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    this.loading = false;
    this.biotablet = localStorage.getItem(DEVICE_TYPE) === 'biotablet';
  }

  public goTo(functionality: string) {
    const navigates = {
      affiliate: SEARCH_AFFILIATE_URL,
      productOpening: SEARCH_AFFILIATE_URL,
      myPortfolio: MY_PORTFOLIO_URL,
      businessTube: BUSINESS_TUBE_URL,
      certificates: CERTIFICATE_VOUCHERS_URL
    };
    localStorage.setItem(FUNCTIONALITY, functionality);
    this.router.navigateByUrl(navigates[functionality]);
  }

  public goToLoginPostVenta() {
    this.router.navigateByUrl(POST_VENTA_LOGIN_URL);
  }
}
