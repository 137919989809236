import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { httpErrorCodes } from '@constants';
import { BehaviorSubject, Observable, from, throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommunicationProvider } from '../communication/communication';

@Injectable()
export class HttpInterceptorProvider implements HttpInterceptor {
  private whitelist = [
    'authentication/unique-key',
    'contact/',
    'authentication/security-questions',
    'authentication/facial-biometric',
  ];
  private token$: BehaviorSubject<string>;

  get token() {
    return new Promise((resolve, reject) =>
      this.token$.pipe(take(1))
        .subscribe(resolve, reject)
    );
  }

  constructor(
    public http: HttpClient,
    private communicationProvider: CommunicationProvider,
    private firebaseAuth: AngularFireAuth,
  ) {
    this.token$ = new BehaviorSubject(null);
    this.firebaseAuth.onAuthStateChanged(async user => {
      if (!user) { return this.token$.next(null); }

      const firebaseUser = await this.firebaseAuth.currentUser;
      const token = await firebaseUser.getIdToken();
      this.token$.next(token);
    });
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next)
      .catch((error) => {
        if (error instanceof HttpErrorResponse && error.status === httpErrorCodes.unauthorized.code) {
          this.communicationProvider.getInterceptedSource().next(error.status);
        }
        return throwError(error).toPromise();
      }));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = await this.token;
    if (!token || this.whitelist.some(prefix => request.url.indexOf(prefix) > -1)) {
      return next.handle(request).toPromise();
    }
    request = request.clone({ setHeaders: { Authorization: String(token) } });
    return next.handle(request).toPromise();
  }
}
