import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CANCEL_REMOTE_PRODUCT_OPENING_MODAL_TEXT, CANCEL_REMOTE_TRANSFER_MODAL_TEXT,
  MAX_LENGTH_RUT,
  POST_VENTA_URL,
  PRODUCT_OPENING_URL, TRANSFER_AFP_URL, TRANSFER_ORIGINS, TRANSFER_STATES,
  VALIDATION_OPERATIONS,
  onServiceError,
  postVentaServiceError
} from '@constants';
import { SecurityQuestionsBiometricLoginData } from '@interfaces/biometricLoginData.interface';
import { ClientPhoneValidation, ClientValidationData } from '@interfaces/client.interface';
import { LogInterface } from '@interfaces/log.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { TransferProvider } from '@providers/transfer/transfer.provider';
import { AutentiaService } from '@services/autentia/autentia.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { LogService } from '@services/logs/log.service';
import { Util } from '@util';
import 'firebase/firestore';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EXECUTIVE_RUT, PENDING_AUTH } from 'util/storage.constants';

export type loginStepType = 'login' | 'loginIdentityCard' | 'waitingIdentityCard' |
  'waiting' | 'errorValidation' | 'errorService' | 'ok';

export type UserTypes = 'executive' | 'client' | 'clientSignature';

@Component({
  selector: 'app-loading-identity-validation',
  templateUrl: './loading-identity-validation.component.html',
  styleUrls: ['./loading-identity-validation.component.scss']
})

export class LoadingIdentityValidationComponent implements OnInit {

  @Input() public userTypeI: UserTypes;
  @Input() public clientPhoneValidationObject: ClientPhoneValidation;
  @Input() public isCodeValidation: boolean;
  @Input() public isFacialValidation: boolean;
  @Input() public isBiotablet = false;
  @Input() public isBiometricValidation: boolean;
  @Input() public goToCommercialManagement: boolean;
  @Input() public executiveInfo;
  @Input() public rut: string;
  @Output() public userType = new EventEmitter();
  @Output() public status = new EventEmitter();
  @Output() public errorCode = new EventEmitter();
  @Output() public infoExecutive = new EventEmitter();
  public clientForm: UntypedFormGroup;
  public maxLengthForRut = MAX_LENGTH_RUT;
  public operation = 'default';
  public currentValidationRef: AngularFireObject<any>;
  public operations = [{
    name: VALIDATION_OPERATIONS.SECURITY_QUESTIONS,
    displayName: 'Validar con llamada',
  }, {
    name: VALIDATION_OPERATIONS.EMAIL_SECURITY_QUESTIONS,
    displayName: 'Validar vía web',
  }, {
    name: VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION,
    displayName: 'Validar con huella dactilar',
  }];
  public clientListRef: AngularFireList<any>;
  private ngUnsubscribe = new Subject();
  private validationInfo: any;
  private equalsRutError = 'El rut del ejecutivo no puede ser el mismo del afiliado.';
  private notExecutiveError = 'RUT no registrado como ejecutivo PlanVital';


  constructor(
    private util: Util,
    private firebaseDatabase: AngularFireDatabase,
    private authenticationService: AuthenticationService,
    private autentiaService: AutentiaService,
    private router: Router,
    private modalProvider: ModalProvider,
    private logService: LogService,
    private loadingProvider: LoadingProvider,
    private ngZone: NgZone,
    private readonly transferProvider: TransferProvider,
  ) {
    this.clientListRef = this.firebaseDatabase.list('client');
  }

  public async ngOnInit() {
    if (this.isExecutive()) {
      return this.validateExecutive();
    }
    if (this.isClient()) return this.validateClient();
  }

  public canNullify() {
    return this.clientPhoneValidationObject.data.status === TRANSFER_STATES.NULLIFIED
      || this.clientPhoneValidationObject.data.status === TRANSFER_STATES.APPROVED;
  }

  public openModalNullifyClient() {
    const modalText = this.isCodeValidation ? CANCEL_REMOTE_PRODUCT_OPENING_MODAL_TEXT : CANCEL_REMOTE_TRANSFER_MODAL_TEXT;
    this.modalProvider.cancelRemoteRequestModal(modalText)
      .afterClosed().subscribe((response) => {
        if (!response) return;
        this.nullifyClient();
      });
  }

  public validation() {
    localStorage.removeItem('clientPhoneValidationCode');
    this.isBiotablet ? this.goToProductOpening() : this.validationRemote();
  }

  public validationRemote() {
    const firebaseId = localStorage.getItem(PENDING_AUTH);
    const validationData = {
      client: this.clientPhoneValidationObject.data.data.afiliado,
      executive: this.executiveInfo.executive,
      firebaseId,
    } as SecurityQuestionsBiometricLoginData;
    this.setOrigin();
    if (!this.isCodeValidation && !this.isFacialValidation) {
      return this.openModalExecutiveReminder(validationData);
    }
    if (this.isCodeValidation) validationData.biotablet = true;
    this.securityQuestionsBiometricLogin(validationData);
  }

  public goBack() {
    this.status.emit('clientValidation');
  }

  private openModalExecutiveReminder(validationData: SecurityQuestionsBiometricLoginData) {
    this.modalProvider.openModalExecutiveReminder()
      .afterClosed().subscribe((response) => {
        if (response) return this.securityQuestionsBiometricLogin(validationData);
      });
  }

  private securityQuestionsBiometricLogin(validationData: SecurityQuestionsBiometricLoginData) {
    this.authenticationService.securityQuestionsBiometricLogin(validationData)
      .subscribe(() => {
        this.sendLog({ validationData, codeValidation: this.isCodeValidation }, false,
          'validationRemote(). routing to transfer URL');
        this.continueAndNavigate();
      }, (e) => {
        let message = onServiceError.message;
        this.sendLog({ validationData, codeValidation: this.isCodeValidation }, true,
          'validationRemote()');
        this.clientPhoneValidationObject.status = 'errorService';
        const extraData = e?.error?.extraData;
        if (extraData?.previred) message = extraData.description;
        this.errorEmiter(message);
      });
  }

  private async goToProductOpening() {
    this.loadingProvider.showLoading();
    await this.validateAndSetToken();
    localStorage.setItem('productOpeningOrigin', 'code');
    this.loadingProvider.hideLoading();
    return this.router.navigateByUrl(PRODUCT_OPENING_URL);
  }

  private async validateAndSetToken() {
    const cleanAffiliateRut = this.util.rutClean(this.clientPhoneValidationObject.data.rut);
    await this.authenticationService.getToken(cleanAffiliateRut)
      .toPromise()
      .then(async ({ token }) => {
        await this.authenticationService.setAffiliateIdToken(token);
      })
      .catch(error => {
        this.handleServiceError(error);
      });
  }

  private handleServiceError(error) {
    error = error.error || error;
    if (!error.code) {
      error = postVentaServiceError;
    }
    this.modalProvider.openGenericRetryErrorModal(error);
  }

  private sendLog(logObject: any, isError: boolean, typeLog: string) {
    const request = {
      logObject,
      isError,
      customMessage: `[WebTransfer] Response of ${typeLog}`,
    } as LogInterface;
    this.logService.sendLog(request);
  }

  private continueAndNavigate() {
    const firebaseId = localStorage.getItem(PENDING_AUTH);
    localStorage.removeItem(PENDING_AUTH);

    // this.router.navigate([TRANSFER_AFP_URL], { state: { firebaseId, isFirebaseAssisted: this.isCodeValidation } });
    this.transferProvider.setFirebaseId(firebaseId);
    this.transferProvider.setIsFirebaseAssisted(this.isCodeValidation);
    this.router.navigate([TRANSFER_AFP_URL]);
  }

  private async nullifyClient() {
    const sessionData = await this.authenticationService.signInAnonymously();
    if (!sessionData || !sessionData.token) return;
    this.currentValidationRef.update({ status: TRANSFER_STATES.NULLIFIED }).then(() => {
      this.clientPhoneValidationObject.data.status = 'anulado';
      localStorage.removeItem(PENDING_AUTH);
      localStorage.removeItem('clientPhoneValidationCode');
      this.clientPhoneValidationObject.timeOut = false;
    });

    this.currentValidationRef.update({ remoteAction: TRANSFER_STATES.NULLIFIED });
  }

  private setOrigin() {
    if (this.isBiometricValidation) return sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.BIODESKTOP_BIOM);
    if (this.isCodeValidation) return sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.BIODESKTOP_CODE);
    if (this.isFacialValidation) return sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.BIODESKTOP_FACI);
    sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.BIODESKTOP_RING);
  }

  private isClient(): boolean {
    return this.userTypeI === 'client';
  }

  private isExecutive(): boolean {
    return this.userTypeI === 'executive';
  }

  private async saveClientFirebase() {
    this.clientListRef.push(this.clientPhoneValidationObject.data).then(async (response) => {
      localStorage.setItem(PENDING_AUTH, response.key);

      if (this.isFacialValidation) this.setFacialBiometricLoginAction(response.key);

      this.currentValidationRef = this.firebaseDatabase.object(`client/${response.key}`);
      if (this.isCodeValidation || this.isFacialValidation) await this.sendCode(response);
      this.currentValidationRef.valueChanges()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: ClientValidationData) => {
          this.clientPhoneValidationObject.connected = true;
          this.clientPhoneValidationObject.data = data;
          if (data.status === TRANSFER_STATES.ANSWERING) this.cancelTimeOut(360000);
        });
    }).catch(() => {
      this.clientPhoneValidationObject.status = 'failedPush';
      this.sendLog({ clientObject: this.clientPhoneValidationObject, codeValidation: this.isCodeValidation }, true,
        'saveClientFirebase()');
      this.errorEmiter(onServiceError.message);
    });
  }

  private async sendCode(response) {
    const codeRef = this.firebaseDatabase.object('client_code');
    const rut = this.clientPhoneValidationObject.data.rut;
    const code = this.util.rutClean(rut).substring(0, rut.length - 1) + response.key.substr(-4);

    const field = {};
    field[code] = response.key;
    await codeRef.update(field);
    const request = {
      name: this.clientPhoneValidationObject.data.name,
      email: this.clientPhoneValidationObject.data.email,
      phone: this.clientPhoneValidationObject.data.phone,
      action: this.isBiotablet ? 'product' : '',
      code,
    };
    this.clientPhoneValidationObject.code = code;
    localStorage.setItem('clientPhoneValidationCode', code);

    this.authenticationService.sendAssistedCode(request, this.util.rutClean(rut)).subscribe();
    this.cancelTimeOut(360000);
  }

  public async nextStep(response?, executiveRut?: string) {
    await this.authenticationService.signInAnonymously();
    localStorage.setItem(EXECUTIVE_RUT, executiveRut);
    this.infoExecutive.emit(response);
    if (this.goToCommercialManagement) return this.router.navigateByUrl(POST_VENTA_URL);
    this.status.emit('clientValidation');
    this.userType.emit('client');
  }

  private async errorEmiter(error: string) {
    this.errorCode.emit(error);
    this.status.emit('error');
  }

  private callAutentiaService(rut: string) {
    // BYPASS
    // return this.userTypeI === 'client' ? this.loginPrevired('response') : this.nextStep('response', rut);
    this.autentiaService.loginFingerprint(rut,
      (response) =>
        this.userTypeI === 'client' ? this.loginPrevired(response) : this.nextStep(response, rut),
      (error) => this.errorEmiter(error.ErcDesc)
    );
  }

  private validateExecutive() {
    const executiveRut = this.util.rutClean(this.rut);
    // BYPASS (uncomment first and second callAutentiaService)
    // const executiveRut = this.util.rutClean('76288003');
    // this.callAutentiaService(executiveRut);
    this.authenticationService.executiveLogin(executiveRut).pipe().subscribe(
      () => this.callAutentiaService(executiveRut),
      () => this.errorEmiter(this.notExecutiveError));
    // this.callAutentiaService(executiveRut);
  }

  private validateClient() {
    const pendingKey = localStorage.getItem(PENDING_AUTH);

    if (pendingKey) return this.loadOnGoingValidation(pendingKey);
    if (this.isBiometricValidation) {
      const rut = this.util.rutClean(this.clientPhoneValidationObject.data.rut);
      return this.callAutentiaService(rut);
    }
    this.clientPhoneValidationObject.data.productOpening = this.isBiotablet;
    this.saveClientFirebase();
  }

  private cancelTimeOut(time: number) {
    return setTimeout(() => { this.clientPhoneValidationObject.timeOut = true; }, time);
  }

  private loadOnGoingValidation(key: string) {
    this.clientPhoneValidationObject.code = localStorage.getItem('clientPhoneValidationCode');
    this.currentValidationRef = this.firebaseDatabase.object(`client/${key}`);
    this.currentValidationRef.valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ClientValidationData) => {
        if (data.status !== TRANSFER_STATES.ANSWERING && data.status !== TRANSFER_STATES.ASSISTED_PENDING
          && data.status !== TRANSFER_STATES.PENDING && data.status !== TRANSFER_STATES.APPROVED) {
          return localStorage.removeItem(PENDING_AUTH);
        }
        if (data.status === TRANSFER_STATES.ANSWERING || data.status === TRANSFER_STATES.ASSISTED_PENDING) {
          this.checkTimeOut(data.date);
        }
        this.clientPhoneValidationObject.connected = true;
        this.clientPhoneValidationObject.data = data;
        const isCodeValidation = data.ring;
        this.operation = isCodeValidation ? VALIDATION_OPERATIONS.EMAIL_SECURITY_QUESTIONS
          : VALIDATION_OPERATIONS.SECURITY_QUESTIONS;
        this.clientPhoneValidationObject.status = 'validation';
      });
  }

  private checkTimeOut(date: number) {
    const timeNow = Date.now();

    if (date + 360000 <= timeNow) return this.clientPhoneValidationObject.timeOut = true;
    const timeDif = 360000 - (timeNow - date);
    this.cancelTimeOut(timeDif);
  }

  private loginPrevired(userVerification) {
    this.buildRequestLoginPrevired(userVerification);
    const rutsEquals = this.checkRutsAreTheSame();
    if (rutsEquals) return this.errorEmiter(this.equalsRutError);
    this.authenticationService.biometricLogin(this.validationInfo, TRANSFER_ORIGINS.BIODESKTOP_BIOM).subscribe(
      () => this.handleUserValidationSuccess(),
      (e) => {
        const extraData = e?.error?.extraData;
        let message = onServiceError.message;
        if (extraData?.previred) message = extraData.description;
        this.errorEmiter(message);
      });
  }

  private buildRequestLoginPrevired(userVerification) {
    this.validationInfo = { ...this.executiveInfo };
    this.validationInfo['client'] = this.buildUserRequest(userVerification);
  }

  private checkRutsAreTheSame() {
    const { executive, client } = this.validationInfo;
    return executive.rut === client.rut;
  }

  private async handleUserValidationSuccess() {
    sessionStorage.setItem('isBiometricAssisted', String(true));
    // ********* NON Devices *********
    // this.transferProvider.setIsFirebaseAssisted(true);
    // this.transferProvider.setFirebaseId(localStorage.getItem(PENDING_AUTH));
    sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.BIODESKTOP_BIOM);
    this.router.navigate([TRANSFER_AFP_URL]);
  }

  private buildUserRequest(biometricData) {

    let {
      apellido_paterno_af: lastName,
      apellido_materno_af: motherLastName
    } = biometricData;

    const {
      nombre_af: name,
      rut_numero_af: rut,
      dv_af: dv,
      NroAudit: verificationCode,
      UrlOTI: verificationUrl
    } = biometricData;

    lastName = lastName.replace(/ /g, '-');
    motherLastName = motherLastName.replace(/ /g, '-');

    return {
      lastName,
      motherLastName,
      name,
      rut: rut + dv,
      verificationCode,
      verificationUrl
    };
  }

  private setFacialBiometricLoginAction(firebaseId: string) {
    if (!this.isFacialValidation) return;

    const facialBiometryRef = this.firebaseDatabase.database.ref(`client/${firebaseId}/facialBiometry`);
    facialBiometryRef.on('value', snapshot => {
      if (!snapshot.val()) return;
      this.makeCallForFacialBiometricLogin(firebaseId, facialBiometryRef);
    });
  }

  public makeCallForFacialBiometricLogin(firebaseId: string, ref: firebase.default.database.Reference) {
    let response = {};
    let error = {};

    const data = {
      transferId: firebaseId,
      clientStepId: 1,
      originCode: TRANSFER_ORIGINS.BIODESKTOP_FACI,
      traceID: this.util.getTraceID(),
    };

    this.authenticationService.facialBiometricLogin(data).subscribe({
      next: async (serviceResponse) => {
        response = serviceResponse;
        this.loadingProvider.showLoading();
        if (serviceResponse.success) {
          this.ngZone.run(() => {
            localStorage.removeItem(PENDING_AUTH);
            this.transferProvider.setFirebaseId(firebaseId);
            this.transferProvider.setIsFirebaseAssisted(true);
            this.router.navigate([TRANSFER_AFP_URL]);
          });
        }
      },
      error: (err) => {
        error = err;
        let message = {
          message: err.message || 'authenticationService.facialBiometricLogin()',
          messageDescription: 'Error al intentar iniciar sesión con biometría facial. Por favor, inténtelo nuevamente.',
        };
        const extraData = err?.error?.extraData;
        if (extraData?.previred) {
          message = {
            message: onServiceError.message,
            messageDescription: extraData.description,
          };
        }
        this.modalProvider.openGenericRetryErrorModal(message);
      },
      complete: () => {
        const message = `LoadingIdentityValidationComponent.callFacialBiometricLogin() - service call - transferId: ${firebaseId}.`;
        this.sendLog({ response, error, data }, false, message);

        this.loadingProvider.hideLoading();
        ref.off('value');
      },
    });
  }
}

