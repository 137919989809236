/* eslint-disable max-len */
const GIFT_ICON = 'i-gift-white';
const INFO_ICON = 'i-info-circle-white';
const USER_ICON = 'i-two-users-white';
const CALENDAR_ICON = 'i-calendar';

export const MONTH_DICT_PORTFOLIO = {
  1: CALENDAR_ICON,
  2: GIFT_ICON,
  3: CALENDAR_ICON,
  4: USER_ICON,
  5: CALENDAR_ICON,
  6: INFO_ICON,
  7: CALENDAR_ICON,
  8: CALENDAR_ICON,
  9: CALENDAR_ICON,
  10: USER_ICON,
  11: CALENDAR_ICON,
  12: CALENDAR_ICON,
  13: CALENDAR_ICON,
  14: CALENDAR_ICON,
  15: CALENDAR_ICON,
  16: INFO_ICON,
  17: CALENDAR_ICON,
  18: CALENDAR_ICON,
  19: CALENDAR_ICON,
  20: CALENDAR_ICON,
  21: CALENDAR_ICON,
  22: CALENDAR_ICON,
  23: CALENDAR_ICON,
  24: CALENDAR_ICON,
};

export const MONTHS_SHOWN_PORTFOLIO = [
  { value: 2, name: 'Mes 2' },
  { value: 4, name: 'Mes 4' },
  { value: 6, name: 'Mes 6' },
  { value: 10, name: 'Mes 10' },
  { value: 16, name: 'Mes 16' },
];

export const MAE_DIFFERENTIATED_MESSAGES = {
  2: `Hola *[affiliateName]* 👋 ¿cómo estás? Soy *[executiveName]*, tu ejecutiv@ de AFP Planvital, y te quiero contar que ya eres parte de nuestra AFP y hemos recibido los saldos de tu cuenta obligatoria. Puedes revisarlos, ingresando a tu “Sucursal en línea” con tu RUT y Clave de Acceso en👉 www.planvital.cl o descargando nuestra APP Planvital. Si tienes dudas de cómo crear tu clave 🔑 ¡me cuentas!%0a%0a
*¿Sabías que solo por estar afiliado al sistema de AFP, tienes acceso a miles de beneficios?* 👀%0a%0a
Ingresa a https://www.misbeneficiosafp.cl/ y comienza a disfrutar de los descuentos.%0a%0a
Espero haberte ayudado y ante cualquier duda que tengas ¡puedes escribirme!%0a%0a
¡Recuerda que en AFP PlanVital somos *Socios de por Vida!* 🤝`,
  4: `Hola *[affiliateName]* 👋 ¿cómo estás? Soy *[executiveName]*, tu ejecutiv@ de AFP Planvital, y quiero invitarte a que conozcas nuestros simuladores: *“Perfil de Inversionista”* el cual te ayudará a determinar cuál es el fondo ideal para ti y el *“Hacedor de Planes”* para que puedas concretar todos tus proyectos a través de nuestra *Cuenta 2*, una excelente alternativa de ahorro y con la comisión más baja del mercado, *¡tan solo un 0,16%!*%0a%0a
Descúbrelos aquí👉 https://www.planvital.cl/afiliado/asesoria/simuladores%0a%0a
¡Recuerda que en AFP PlanVital somos *Socios de por Vida!* 🤝`,
  6: `Hola *[affiliateName]* 👋 ¿cómo estás? Soy *[executiveName]*, tu ejecutiv@ de AFP Planvital y te quiero recordar que todos los meses llega a tu e-mail la cartola 4.0, la cual te explica de forma simple y visual el detalle de tus ahorros durante los últimos 36 meses. Además, podrás estar al tanto de si tu empleador ha pagado las cotizaciones, saber en qué fondos tienes tus ahorros y la evolución de tu saldo en el período. 👌%0a%0a
*Si tiene dudas de cómo visualizar tu cartola, ¡no dudes en escribirme!*%0a%0a
¡Recuerda que en AFP PlanVital somos *Socios de por Vida!* 🤝`,
  10: `Hola *[affiliateName]* 👋 ¿cómo estás? Soy *[executiveName]*, tu ejecutiv@ de AFP Planvital. Me gustaría presentarte nuestro Simulador de Pensión, con el que podrás calcular tus alternativas de ahorro para el futuro, ingresa a 👉 https://www.spensiones.cl/apps/simuladorPensiones/ %0a%0a
Para complementar tu futura pensión puedes hacerlo con un APV, tenemos la menor comisión del mercado, cualquier pregunta no dudes en escribirme.%0a%0a
¡Recuerda que en AFP PlanVital somos *Socios de por Vida!* 🤝`,
  16: `Hola *[affiliateName]* 👋 ¿cómo estás? Soy *[executiveName]*, tu ejecutiv@ de AFP Planvital. Quiero agradecerte todo el tiempo que llevas con nosotros e informarte que tenemos servicios digitales y varios canales disponibles para ti. Si tienes alguna duda escríbenos a nuestro Whatsapp de AFP PlanVital +56 2 22645200 donde podrás descargar certificados y mucho más.%0a%0a
También puedes encontrarnos en👇 %0a%0a
*Contact Center:* 800 072 072 %0a%0a
*Mail:* infovital@planvital.cl %0a%0a
*Facebook:* facebook.com/AFPPlanVital %0a%0a
*Página Web:* www.planvital.cl %0a%0a
*Twitter:* twitter.com/planvitalafp %0a%0a
*Instagram:* instagram.com/planvitalafp %0a%0a
*Whatsapp:* +56 2 22645200 %0a%0a
¡Recuerda que en AFP PlanVital somos *Socios de por Vida!* 🤝`
};

export const MAE_DEFAULT_MESSAGE = `Hola *[affiliateName]*,%0a%0a
Soy *[executiveName]*, tu ejecutivo/a en AFP PlanVital.%0a%0a
Estoy aquí para guiarte y brindarte la información que necesites para gestionar tus ahorros de la mejor manera.%0a%0a
Si tienes alguna duda o hay algún tema en el que te pueda apoyar, no dudes en escribirme directamente.%0a%0a
Estoy disponible para ayudarte en lo que necesites.%0a%0a
*¡En AFP PlanVital, estamos contigo en cada paso!*%0a%0a
Gracias por tu confianza,%0a%0a
Equipo AFP PlanVital`;
