import { Injectable } from '@angular/core';
import { environment } from '@env';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsProvider {

  private basePageView = '/traspaso';

  private pathAnalytics = {
    assisted: '/ingresoAsistido',
    unassisted: '/',
    online: '/ingresar',
    continue: '/continuar',
    confirm: '/continuar/confirmar',
    questions: '/ingresar/preguntas',
    contact: '/contactar',
    contactSuccessful: '/contactar/exitoso',
    accountSelection: '/afp/seleccionDeCuentas',
    personalInfo: '/afp/datosPersonales',
    employers: '/afp/datosEmpleador',
    pin: '/afp/pin',
    voucher: '/afp/comprobante'
  };

  constructor() { }

  public registerPageView(page: pageView) {
    gtag('config', environment.gaMeasurementId, { 'page_path': this.basePageView + this.pathAnalytics[page] });
  }
}

export type pageView = loginPages | transferPages;
export type loginPages = 'assisted' | 'unassisted' | 'online' | 'continue' | 'confirm' | 'questions' | 'contact' | 'contactSuccessful';
export type transferPages = 'accountSelection' | 'employers' | 'personalInfo' | 'voucher' | 'pin';

