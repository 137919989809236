import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {
  ACCOUNTS_LOGIN_URL, BIOTABLET_PATHS, DEVICES_URL,
  EXECUTIVE_URL, ONLINE_LOGIN_URL, TRANSFER_URL
} from '@constants';
import { Util } from '@util';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class PlatformGuardService {
  public isBlueBird = false;
  public firstLoad: boolean;

  constructor(private router: Router, private deviceService: DeviceDetectorService, public util: Util) {
    const userAgentIsEF500 = this.deviceService.getDeviceInfo().userAgent.indexOf('EF500') > -1;
    const isMobileDevice = this.deviceService.isMobile();
    this.isBlueBird = userAgentIsEF500 && isMobileDevice;
    // BYPASS
    // this.isBlueBird = true;
    this.firstLoad = true;
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let canActivate;
    this.checkPathForDevices(state.url);
    canActivate = this.isBlueBird ? this.validateRouteForBiometricDevice(state.url) : this.validateRouteForDevice(state.url);
    return canActivate;
  }

  private checkPathForDevices(url: string) {
    if (this.firstLoad) {
      this.firstLoad = false;
      this.isBlueBird = this.isBlueBird || url === DEVICES_URL;
    }
  }

  private validateRouteForBiometricDevice(url: string): boolean {
    if (url === ONLINE_LOGIN_URL || url === TRANSFER_URL) this.router.navigateByUrl(EXECUTIVE_URL);
    return BIOTABLET_PATHS.includes(url);
  }

  private validateRouteForDevice(url: string): boolean {
    if (BIOTABLET_PATHS.includes(url))
      this.router.navigateByUrl(TRANSFER_URL);
    return url === ONLINE_LOGIN_URL || url === TRANSFER_URL || this.validateRouteForMessagingAccountsUrl(url);
  }

  private validateRouteForMessagingAccountsUrl(url: string) {
    const tailRegex = /(?:\?|&)((rut=[^&]+&ticket=[^&]+)|(ticket=[^&]+&rut=[^&]+)).*$/;
    const headMatch = url.match(/^[^?]+/);
    return headMatch[0] === ACCOUNTS_LOGIN_URL && tailRegex.test(url);
  }
}
