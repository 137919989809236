import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { APV_EMPLOYER_CARDS_TEXT, APV_NAME_MAX_LENGTH, ERROR_MESSAGES_FORM, PHONE_MAX_LENGTH, PHONE_MIN_LENGTH } from '@constants';
import { Employer } from '@interfaces/client.interface';
import { EmployerDataResponse } from '@interfaces/employerDataResponse.interface';
import { Commune, Region } from '@interfaces/region.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { EmployerService } from '@services/employer/employer.service';
import { Util } from '@util';
import { ValidateRut } from 'app/validators/rut.validator';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-drop-info-card',
  templateUrl: './drop-info-card.component.html',
  styleUrls: ['./drop-info-card.component.scss']
})

export class DropInfoCardComponent implements OnInit, AfterViewInit {
  @Input() public employerInput: EmployerDataResponse;
  @Input() public id: string;
  @Input() public newEmployer: boolean;
  @Input() public selectedIn: boolean;
  @Input() public regions: Array<Region>;
  @Output() public selectedOut = new EventEmitter();
  @Output() public employerOutput = new EventEmitter();
  public latitude: number;
  public longitude: number;
  public zoom: number;
  public communes: Array<Commune> = [];
  public employer: UntypedFormGroup;
  public nameMaxLength = APV_NAME_MAX_LENGTH;
  public phoneMinLength = PHONE_MIN_LENGTH;
  public phoneMaxLength = PHONE_MAX_LENGTH;
  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;
  public fullAddress: string;
  public showHideText = APV_EMPLOYER_CARDS_TEXT.more;
  public isActive = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private employerService: EmployerService,
    private util: Util,
    private modalProvider: ModalProvider,
    private loadingProvider: LoadingProvider,
  ) {
    this.employer = this.createEmployerForm({} as any);
  }

  get businessName() { return this.employer.controls.businessName; }

  get address() { return this.employer.controls.address; }

  get rut() { return this.employer.controls.rut; }

  get regionCode() { return this.employer.controls.regionCode; }

  get communeCode() { return this.employer.controls.communeCode; }

  get email() { return this.employer.controls.email; }

  get cellphoneNumber() { return this.employer.controls.cellphoneNumber; }

  get phoneNumber() { return this.employer.controls.phoneNumber; }

  public isRutInvalid(form: UntypedFormGroup) {
    const rut = form.controls['rut'];
    return rut.invalid && !rut.hasError('rutNotFound');
  }

  public searchEmployer() {
    this.loadingProvider.showLoading();
    const rut = this.rut.value;

    this.employerService.searchEmployer(rut)
      .pipe(finalize(() => this.loadingProvider.hideLoading()))
      .subscribe((employer: Employer) => this.handleSearchEmployerSuccess(employer),
        (error) => this.handleSearchEmployerError(error, rut));
  }

  public addRutFormat(form: UntypedFormGroup) {
    const rut = form.controls['rut'];
    this.rut.setValue(this.util.rutFormat(rut.value));
  }

  public removeRutFormat(form: UntypedFormGroup) {
    const rut = form.controls['rut'];
    rut.setValue(this.util.rutClean(rut.value));
  }

  public ngOnInit() {
    this.setEmployerInfo();
    this.regionSubscription(this.employer);
  }

  public ngAfterViewInit() {
    const element = document.getElementById(this.id);
    element.style.maxHeight = '0';
  }

  public showHide() {

    if (!this.newEmployer) { this.employer.controls['rut'].disable(); }

    const element = document.getElementById(this.id);
    if (element.style.maxHeight === '0px') {
      this.isActive = true;
      this.showHideText = APV_EMPLOYER_CARDS_TEXT.less;
      element.style.maxHeight = '1400px';
    } else {
      this.isActive = false;
      this.showHideText = APV_EMPLOYER_CARDS_TEXT.more;
      element.style.maxHeight = '0';
    }
  }

  public getErrorMessage(form: UntypedFormGroup, controlName: string) {
    const control = form.controls[controlName];

    if (control.hasError('required')) {
      return ERROR_MESSAGES_FORM['required'];
    }

    if (control.hasError('invalidRut')) {
      return ERROR_MESSAGES_FORM['invalidRut'];
    }

    if (control.hasError('rutNotFound')) {
      return control.getError('rutNotFound');
    }

    if (this.invalidEmail(form, controlName)) {
      return ERROR_MESSAGES_FORM['invalidEmail'];
    }

    if (control.hasError('invalidDomain')) {
      return ERROR_MESSAGES_FORM['invalidDomain'];
    }

    if (control.hasError('maxlength')) {
      return ERROR_MESSAGES_FORM['maxlength'];
    }

    if (control.hasError('minlength')) {
      return ERROR_MESSAGES_FORM['invalidPhone'];
    }

    return '';
  }

  public setRegionCodeFromCommune(communeCode: string) {
    const regionFound = this.regions.find(region => region.communes.some(commune => commune.code === communeCode));

    if (regionFound) {
      this.regionCode.setValue(regionFound.code);
      this.setCommunes(regionFound.code);
    }
  }

  private setEmployerInfo() {
    if (!this.newEmployer && this.employerInput) {
      this.rut.setValue(this.employerInput.rut);
      this.businessName.setValue(this.employerInput.businessName);
      this.email.setValue(this.employerInput.email);
      this.phoneNumber.setValue(this.employerInput.phoneNumber);
      this.cellphoneNumber.setValue(this.employerInput.cellphoneNumber);
      this.address.setValue(this.employerInput.address);
      this.communeCode.setValue(this.employerInput.communeCode);
      this.setRegionCodeFromCommune(this.communeCode.value);
    }
  }

  private handleSearchEmployerSuccess(employer: Employer) {
    const form = this.employer;
    employer.rut = this.util.rutFormat(employer.rut);

    Object.keys(employer).forEach((key) => {
      const control = form.get(key);
      if (!control) return;

      control.setValue(employer[key]);
    });

    this.setRegionsAndCommunes(employer, form as UntypedFormGroup);
  }

  private regionSubscription(form: UntypedFormGroup) {
    form.get('regionCode').valueChanges
      .subscribe(regionCodeSelected => {
        const updatedRegion = this.regions.find(region => region.code === regionCodeSelected);
        const { communeCode } = form.controls;
        communeCode.setValue('');

        if (!updatedRegion) {
          this.communes = [];
          return;
        }

        if (!updatedRegion) return;

        this.communes = updatedRegion.communes;
      });
  }

  private setRegionsAndCommunes(employer: Employer, form: UntypedFormGroup) {
    const { regionCode, communeCode } = employer;

    const region = this.findRegion(regionCode);
    employer.regionCode = region ? regionCode : null;

    this.setCommunes(employer.regionCode);

    const commune = this.findCommune(communeCode);
    employer.communeCode = commune ? communeCode : null;

    this.setControlValue(form, 'communeCode', employer.communeCode);
  }

  private setCommunes(regionCode: string) {
    const regionSelected = this.regions.find(region => region.code === regionCode);
    this.communes = regionSelected ? regionSelected.communes : [];
  }

  private setControlValue(form: UntypedFormGroup, controlName: string, value) {
    const control = form.get(controlName);
    control.setValue(value);
  }

  private findRegion(regionCode: string): Region {
    return this.regions.find(region => region.code === regionCode);
  }

  private findCommune(communeCode: string): Commune {
    return this.communes.find(commune => commune.code === communeCode);
  }

  private clearForm(employerRut: string, form: UntypedFormGroup) {
    form.reset();
    const { rut } = form.controls;
    rut.setValue(this.util.rutFormat(employerRut));
  }

  private handleSearchEmployerError(error, rut: string) {
    const form = this.employer;
    this.clearForm(rut, form as UntypedFormGroup);
    const code = error.error && error.error.code;

    if (code) return form.get('rut').setErrors({ rutNotFound: error.error.message });

    this.modalProvider.openGenericErrorModal(error);
  }

  private invalidEmail(form: UntypedFormGroup, controlName: string) {
    const control = form.controls[controlName];
    return control.hasError('email') || (control.hasError('pattern') && controlName === 'email');
  }

  private createEmployerForm(employer: Employer): UntypedFormGroup {
    const form = this.formBuilder.group({
      businessName: [{value: employer.businessName, disabled: true}, [Validators.required, Validators.maxLength(50)]],
      address: [{value: employer.address, disabled: true}],
      rut: [this.util.rutFormat(employer.rut), [Validators.required, ValidateRut]],
      regionCode: [{value: employer.regionCode, disabled: true }],
      communeCode: [{value: employer.communeCode, disabled: true}],
      email: [{value: employer.email, disabled: true }],
      cellphoneNumber: [{value: employer.cellphoneNumber, disabled: true}],
      phoneNumber: [{value: employer.phoneNumber, disabled: true}],
    });
    form.controls['communeCode'].disable();
    this.setFormOnChange(form);
    if (employer.rut) this.markControlsAsTouched(form);

    return form;
  }

  private markControlsAsTouched(form: UntypedFormGroup) {
    Object.values(form.controls).forEach(control => control.markAsTouched());
  }

  private setFormOnChange(form: UntypedFormGroup) {
    form.valueChanges.subscribe(() => {
      const rut = form.controls['rut'];
      if (!rut.errors || !rut.errors['rutNotFound']) return;

      rut.setErrors(null);
    });
  }

  public saveEmployer(): EmployerDataResponse {
    this.showHide();
    this.employer.markAsUntouched();
    this.employerOutput.emit({ response: this.employer.getRawValue(), newEmployer: this.newEmployer });
    if (this.newEmployer) this.employer.reset();
    return this.employer.value;
  }

  public select() {
    const infoToEmit = {id: this.id, form: this.employer};
    this.selectedOut.emit(infoToEmit);
  }
}
