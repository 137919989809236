/* eslint-disable no-magic-numbers */
export const CLIENT_QUOTATIONS_MOCKUP = {
  success: [
    {
      response: {
        lastQuotation: '11-2023',
        monthsListed: 13
      },
    },
  ],
  failures: [
    {
      response: {
        'code': 50001,
        'folioNumber': null,
        'messageDescription': 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        'serviceErrorMessage': '',
        'message': 'Error',
      },
    },
  ],
};
