/* eslint-disable @typescript-eslint/ban-types */
import {
  AFP_CAPITAL_PHONE,
  AFP_CAPITAL_PHONE_SECOND,
  AFP_CUPRUM_PHONE,
  AFP_HABITAT_PHONE,
  AFP_HABITAT_PHONE_SECOND,
  AFP_MODELO_PHONE,
  AFP_MODELO_PHONE_SECOND,
  AFP_PROVIDA_PHONE,
  AFP_UNO_PHONE
  } from '@constants';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@interfaces/modalData.interface';

@Component({
  selector: 'app-contact-afps-modal',
  templateUrl: './contact-afps-modal.component.html',
  styleUrls: ['./contact-afps-modal.component.scss'],
})
export class OtherAfpsContactModalComponent {
  public AFP_CAPITAL_PHONE = AFP_CAPITAL_PHONE;
  public AFP_CAPITAL_PHONE_SECOND = AFP_CAPITAL_PHONE_SECOND;
  public AFP_CUPRUM_PHONE = AFP_CUPRUM_PHONE;
  public AFP_HABITAT_PHONE = AFP_HABITAT_PHONE;
  public AFP_HABITAT_PHONE_SECOND = AFP_HABITAT_PHONE_SECOND;
  public AFP_MODELO_PHONE = AFP_MODELO_PHONE;
  public AFP_MODELO_PHONE_SECOND = AFP_MODELO_PHONE_SECOND;
  public AFP_PROVIDA_PHONE = AFP_PROVIDA_PHONE;
  public AFP_UNO_PHONE = AFP_UNO_PHONE;

  constructor(
    public dialogRef: MatDialogRef<OtherAfpsContactModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
  }

  public primaryAction(): void {
    this.dialogRef.close();
  }
}
