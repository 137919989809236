import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { DataSnapshot } from '@angular/fire/compat/database/interfaces';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VALIDATION_OPERATIONS, IP_MAX_LENGTH, POST_VENTA_URL } from '@constants';
import { BiometricLoginData } from '@interfaces/biometricLoginData.interface';
import { BiometricResponse } from '@interfaces/biometricResponse.interface';
import { ModalProvider } from '@providers/modal/modal';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Util } from '@util';
import { ValidateRut } from 'app/validators/rut.validator';
import firebase from 'firebase/compat';
import { EXECUTIVE_RUT } from 'util/storage.constants';

export type loginStepType = 'login' | 'loginIdentityCard' | 'waitingIdentityCard' | 'waiting' | 'errorValidation' | 'errorService' | 'ok';

@Component({
  selector: 'app-post-venta',
  templateUrl: './post-venta.component.html',
  styleUrls: ['./post-venta.component.scss'],
})
export class PostVentaComponent implements OnInit {
  public executiveForm: UntypedFormGroup;
  public executiveRut: string;
  public pageId: string;
  public token: string;
  public url: string;
  public executiveValidationStatus: loginStepType = 'login';
  public initialLogin = 'login';
  public loading: boolean;
  public validationInfo: BiometricLoginData;
  public maxLengthForRut = 12;
  public biometricError: { code: any; description: any } = { code: null, description: '' };
  public operations = [
    {
      name: VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION,
      displayName: 'Validar con huella',
    },
    {
      name: VALIDATION_OPERATIONS.BARCODE,
      displayName: 'Validar con cédula',
    },
  ];
  public operation = VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
  public userLoginType = 'executive';
  private isPostVenta = true;
  private undefinedBarcode = undefined;
  private uid: string;
  private biometricWindow: Window;
  private validStatus = 'activo';
  private validStatusCode = 200;
  private biometricLogin = { operation: '', barcode: '' };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private util: Util,
    private firebaseDatabase: AngularFireDatabase,
    private ngZone: NgZone,
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalProvider: ModalProvider
  ) {
    this.url = '';
    this.biometricWindow = null;
    this.executiveForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      operation: [VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION, [Validators.required]],
    });
  }

  get executiveRutControl() {
    return this.executiveForm.controls['rut'];
  }

  get disableExecutiveForm() {
    return this.executiveForm.invalid || !this.executiveForm.dirty || !this.executiveForm.touched;
  }

  public async ngOnInit() {
    await this.authenticationService.logout();
    await this.getToken();
    this.executiveForm.get('operation').valueChanges.subscribe((option) => this.changeOptionSelected(option));
  }

  public retryLogin() {
    this.validation();
  }

  public goBack() {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    this[userStatus] = this.initialLogin;
  }

  public retryBiometricLogin() {
    this.validate(this.userLoginType);
  }

  public validate(type: string) {
      // BYPASS
    // localStorage.setItem(EXECUTIVE_RUT, '76288003'); // You can use 76288003 or 113890541
    // sessionStorage.setItem('executiveSession', '76288003');
    // return this.router.navigate([POST_VENTA_URL]);
    const formName = `${type}Form`;
    const form = this[formName] as UntypedFormGroup;
    const { rut } = form.value;
    // BYPASS FOR ANY RUT
    // localStorage.setItem(EXECUTIVE_RUT, this.util.rutClean(rut));
    // return this.router.navigate([POST_VENTA_URL]);
    if (form.invalid) return;
    this.verifyBiometricIdentity(rut, type);
  }

  private changeOptionSelected(option) {
    this.operation = option;
    const userStatus = `${this.userLoginType}ValidationStatus`;
    if (option === VALIDATION_OPERATIONS.BARCODE && this[userStatus] === 'login') this.setLoginWithIdentityCard();
    if (option === VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION && this[userStatus] === 'loginIdentityCard') this.setLogin();
  }

  private setLoginWithIdentityCard() {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    this[userStatus] = 'loginIdentityCard';
    this.initialLogin = 'loginIdentityCard';
  }

  private setLogin() {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    this[userStatus] = 'login';
    this.initialLogin = 'login';
  }

  private verifyBiometricIdentity(rut: string, type: string) {
    const statusName = `${type}ValidationStatus`;
    this.generateUrlAndPageId(this.util.rutClean(rut));
    this[statusName] = this.initialLogin === 'loginIdentityCard' ? 'waitingIdentityCard' : 'waiting';
    this.callBiometricApk();
    this.validateUser(type);
  }

  private generateUrlAndPageId(rut: string) {
    const { url, pageId } = this.getUrlAndPageId(rut);

    this.url = url;
    this.pageId = pageId;
  }

  private getUrlAndPageId(rut: string) {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    const isVerifyIdentity = this.operation === VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
    const failValidation = this[userStatus] === 'errorValidation';

    if (isVerifyIdentity) {
      return this.authenticationService.biometricUrl(rut, this.token, this.uid, this.undefinedBarcode, this.isPostVenta);
    }
    if (this.initialLogin === 'loginIdentityCard' || failValidation) {
      return this.authenticationService.biometricUrlForIdentityCard(this.token, this.uid, this.isPostVenta);
    }
    if (this.initialLogin === 'login' || failValidation) {
      return this.authenticationService.biometricUrl(rut, this.token, this.uid, this.biometricLogin.barcode, this.isPostVenta);
    }
  }

  private callBiometricApk() {
    this.biometricWindow = window.open(this.url);
    if (this.biometricWindow) {
      setTimeout(() => this.biometricWindow.close(), 1000);
    }
  }

  private validateUser(type: string) {
    this.loading = true;
    this.cancelOnChangeDataEvent();
    this.firebaseDatabase.database.ref(`user/${this.uid}/${this.pageId}`).on('value', (data) => this.handleFirebaseData(data, type));
  }

  private async getToken() {
    const sessionData = await this.authenticationService.signInAnonymously();

    if (!sessionData || !sessionData.token) return this.invalidToken();
    this.token = sessionData.token;
    this.uid = sessionData.uid;
  }

  private invalidToken() {
    this.modalProvider
      .openErrorTokenInvalid()
      .afterClosed()
      .subscribe(() => this.getToken());
  }

  private handleFirebaseData(data: DataSnapshot, type: string) {
    const firebaseData = data.val();
    if (!firebaseData || firebaseData.status !== this.validStatus) return;
    this.ngZone.run(async () => {
      this.loading = false;

      if (this.isBiometricResponseInvalid(firebaseData)) return this.handleInvalidBiometricResponse(firebaseData);

      const isBarcodeOperation = this.operation === VALIDATION_OPERATIONS.BARCODE;
      const userStatus = `${this.userLoginType}ValidationStatus`;
      if (isBarcodeOperation && this[userStatus] === 'waitingIdentityCard') return this.setBarCode(userStatus, firebaseData);

      this.setValidationInformation(type, firebaseData);
      this.validation();
      this.cancelOnChangeDataEvent();
    });
  }

  private isBiometricResponseInvalid(data: any) {
    return data.verification_codigo !== this.validStatusCode;
  }

  private handleInvalidBiometricResponse(data) {
    const { verification_codigo: code, verification_mensaje: description } = data;
    this.biometricError = { code, description };
    this.handleUserValidationError(false);
    this.cancelOnChangeDataEvent();
  }

  private setBarCode(userStatus: string, firebaseData) {
    this.biometricLogin.barcode = firebaseData.verification_codigo_barra;
    this[userStatus] = 'login';
    this.initialLogin = 'login';
    this.cancelOnChangeDataEvent();
  }

  private buildUserRequest(biometricData: BiometricResponse) {
    const { verification_apellidos: lastNames, verification_nombres: names } = biometricData;
    const { verification_rut: rut, verification_transaccion: verificationCode, verification_url: verificationUrl } = biometricData;

    const { lastNamesSplit, namesSplit } = this.saveInfoExecutive(lastNames, names, rut);

    return {
      lastname: lastNamesSplit[0],
      motherLastName: lastNamesSplit.length > 1 ? lastNamesSplit[1] : lastNamesSplit[0],
      name: namesSplit[0],
      rut,
      verificationCode,
      verificationUrl,
    };
  }

  private saveInfoExecutive(lastNames: string, names: string, rut: string) {
    const lastNamesSplit = lastNames.trim().split(' ');
    const namesSplit = names ? names.trim().split(' ') : lastNamesSplit;
    const infoExecutive = lastNamesSplit[0]
      ? this.util.removeAccents(`${namesSplit[0]}-${lastNamesSplit[0]}`).substring(0, IP_MAX_LENGTH)
      : rut;
    localStorage.setItem('infoExecutive', infoExecutive);
    return { lastNamesSplit, namesSplit };
  }

  private cancelOnChangeDataEvent() {
    this.firebaseDatabase.database.ref(`user/${this.uid}/${this.pageId}`).off('value');
  }

  private setValidationInformation(type: string, firebaseData) {
    this.validationInfo = { ...this.validationInfo };
    this.validationInfo[type] = this.buildUserRequest(firebaseData);
    this.executiveRut = firebaseData.verification_rut || this.util.rutClean(this.executiveRutControl.value);
    localStorage.setItem(EXECUTIVE_RUT, this.executiveRut);
    localStorage.setItem('validationInfoExecutive', JSON.stringify(this.validationInfo[type]));
  }

  private validation() {
    this.loading = true;

    const serviceLogin = this.authenticationService.executiveLogin(this.executiveRut);

    serviceLogin.subscribe(
      () => this.handleUserValidationSuccess(),
      () => this.handleUserValidationError(true)
    );
  }

  private handleUserValidationSuccess() {
    this.loading = false;
    if (this.initialLogin !== 'login') {
      if (this.operation === VALIDATION_OPERATIONS.BARCODE) this.operation = VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
      return;
    }
    return this.router.navigate([POST_VENTA_URL]);
  }

  private handleUserValidationError(isServerError: boolean) {
    this.executiveValidationStatus = isServerError ? 'errorService' : 'errorValidation';
    this.loading = false;
  }
}
