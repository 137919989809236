import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges {
  @Input() public length: number;
  @Input() public pageSize: number;
  @Input() public pageActive: number;
  @Output() public page: EventEmitter<{ pageIndex: number }> = new EventEmitter();
  public totalPages = 0;
  public pages: any[];

  public ngOnChanges(): void {
    this.totalPages = Math.ceil(this.length / this.pageSize);
    this.changePagination();
  }

  public get backDisable(): boolean {
    return this.length === 0 || this.pageActive === 1;
  }

  public get nextDisable(): boolean {
    return this.length === 0 || this.pageActive === this.totalPages;
  }

  public changePage(page: number) {
    this.pageActive = page;
    this.page.emit({ pageIndex: page - 1 });
    this.changePagination();
  }

  public backPage() {
    if (this.backDisable) return;
    this.changePage(this.pageActive - 1);
  }

  public nextPage() {
    if (this.nextDisable) return;
    this.changePage(this.pageActive + 1);
  }

  private changePagination() {
    let pagTmp = [];
    if (this.totalPages <= 5) {
      for (let i = 1; i <= this.totalPages; i++) pagTmp.push(i);
    } else {
      if (this.pageActive <= 3) {
        pagTmp = [1, 2, 3, 4, '...', this.totalPages];
      } else if (this.pageActive > this.totalPages - 3) {
        pagTmp = [1, '...', this.totalPages - 3, this.totalPages - 2, this.totalPages - 1, this.totalPages];
      } else {
        pagTmp = [1, '...', this.pageActive - 1, this.pageActive, this.pageActive + 1, '...', this.totalPages];
      }
    }
    this.pages = pagTmp;
  }

}
