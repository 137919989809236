import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  blockedUserMessageError,
  CONFIRM_TRANSFER,
  httpErrorCodes,
  INVALID_SCORE,
  oneTryLeftMessageWarning,
  ONLINE_LOGIN_URL,
  onServiceError,
  TRANSFER_AFP_URL
} from '@constants';
import { GetSecurityQuestionsResponse, Question } from '@interfaces/securityQuestions.interface';
import { GoogleAnalyticsProvider } from '@providers/googleAnalytics/googleAnalytics';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { TimerProvider } from '@providers/timer/timer';
import { ClientService } from '@services/client/client.service';
import { QuestionsService } from '@services/questions/questions.service';
import { Util } from '@util';
import { FirebaseUtil } from 'util/firebase-util';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  providers: [
    FirebaseUtil,
  ]
})
export class QuestionsComponent implements OnInit {
  public questions: Array<Question> = [];
  public form: UntypedFormGroup;
  public rut: string;
  public documentId: string;
  public transactionId: string;
  public interactiveQuestionId: number;
  public loadingQuestions = false;
  public firebaseId: string;
  public errorGetQuestions = false;
  public isSinacofi: boolean;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private loadingProvider: LoadingProvider,
    private timerProvider: TimerProvider,
    private clientService: ClientService,
    private questionsService: QuestionsService,
    private modalProvider: ModalProvider,
    private googleAnalyticsProvider: GoogleAnalyticsProvider,
    private util: Util,
    private firebaseUtil: FirebaseUtil,
  ) {
    this.form = this.formBuilder.group({
      questions: this.formBuilder.array([], Validators.required),
    });
    this.googleAnalyticsProvider.registerPageView('questions');
    this.setNavigationQuestions();
  }

  get questionsList() {
    return (this.form.get('questions') as UntypedFormArray).controls;
  }

  public ngOnInit() {
    if (!this.questions.length) return this.goBack();
    this.setQuestions();
  }

  public login() {
    if (this.form.invalid) return;

    this.loadingProvider.showLoading();
    this.questionsService.securityQuestionsLogin(this.form.value.questions, this.rut, this.transactionId, this.interactiveQuestionId,
      this.isSinacofi)
      .toPromise()
      .then(async () => await this.router.navigate([TRANSFER_AFP_URL]))
      .catch((error) => this.securityQuestionsLoginErrorHandler(error))
      .finally(() => this.loadingProvider.hideLoading());
  }

  public goBack() {
    this.router.navigate([ONLINE_LOGIN_URL]);
  }

  public async customLogin() {
    if (this.form.invalid) return;
    this.timerProvider.stopTimer();
    this.loadingProvider.showLoading();
    this.questionsService.securityQuestionsLogin(this.form.value.questions, this.rut, this.transactionId,
      this.interactiveQuestionId, this.isSinacofi)
      .toPromise()
      .then(async () => await this.router.navigate([TRANSFER_AFP_URL]))
      .catch((err) => this.verifyBlockedClientErrorHandler(err))
      .finally(() => this.loadingProvider.hideLoading());
  }

  public customLoginWId() {
    if (this.form.invalid) return;
    this.loadingProvider.showLoading();
    this.timerProvider.stopTimer();
    this.questionsService.authSecurityQuestionsLogin(this.form.value.questions, this.rut, this.transactionId,
      this.interactiveQuestionId, this.firebaseId, this.isSinacofi)
      .toPromise()
      .then(async () => await this.router.navigate([CONFIRM_TRANSFER], { state: { firebaseId: this.firebaseId } }))
      .catch((err) => this.verifyBlockedClientErrorHandler(err))
      .finally(() => this.loadingProvider.hideLoading());
  }

  private securityQuestionsLoginErrorHandler(error) {
    if (error.statusCode === httpErrorCodes.internalServerError.code) return this.modalProvider.openGenericErrorModal(onServiceError);
    this.clientService.verifyBlockedClient(this.rut)
      .subscribe(
        (response) => this.verifyBlockedClientHandler(response),
        (err) => this.verifyBlockedClientErrorHandler(err)
      );
  }

  private verifyBlockedClientHandler(verifyResponse) {
    const { blocked, unlockDate } = verifyResponse;
    if (blocked) {
      const { message, messageDescription: errorDescription } = blockedUserMessageError;
      const completeSpanishDate = this.util.getCompleteSpanishDate(unlockDate);
      const messageDescription = `${ errorDescription } ${ completeSpanishDate }`;
      return this.handleQuestionsLoginError({ message, messageDescription });
    }
    this.modalProvider.openLoginQuestionErrorModal(oneTryLeftMessageWarning, blocked)
      .afterClosed().subscribe((response) => {
      if (!response) return this.goBack();
      this.getSecurityQuestions(true);
      this.util.scrollUp();
    });
  }

  private handleQuestionsLoginError(error) {
    const { message, messageDescription } = error;
    this.modalProvider.openCustomErrorModal({ message, messageDescription })
      .afterClosed().subscribe(() => this.goBack());
  }

  private verifyBlockedClientErrorHandler(error) {
    error = error.error || error;
    if (error.code === INVALID_SCORE) {
      return this.modalProvider.openLoginQuestionErrorRetryModal()
        .afterClosed().subscribe((response) => {
          if (!response) {
            this.firebaseUtil.nullify(this.firebaseId);
            return this.goBack();
          }
          this.getSecurityQuestions(true);
          this.util.scrollUp();
          this.timerProvider.startTimer();
        });
    }
    const { extraData } = error;

    if (extraData) return this.modalProvider.openSecurityQuestionsErrorModal(extraData, extraData.length);
    return this.modalProvider.openGenericErrorModal(onServiceError);
  }

  private getSecurityQuestions(retry: boolean = false) {
    this.loadingProvider.showLoading();
    this.loadingQuestions = true;
    this.questionsService.getSecurityQuestions(this.rut, this.documentId, this.firebaseId, retry)
      .toPromise()
      .then((response: GetSecurityQuestionsResponse) => {
        this.getSecurityQuestionsHandler(response);
        this.clientNullifyCheck();
      })
      .catch((data) => {
        this.getSecurityQuestionsErrorHandler(data);
        this.clientNullifyCheck(true);
      })
      .finally(() => {
        this.loadingProvider.hideLoading();
        this.loadingQuestions = false;
      });
  }

  private clientNullifyCheck(isFromError?: boolean) {
    if (!this.questionsList.length || isFromError) { this.firebaseUtil.nullify(this.firebaseId); }
  }

  private getSecurityQuestionsHandler(response) {
    const { questions, transactionId, interactiveQuestionId } = response;
    this.setTransactionData(questions, transactionId, interactiveQuestionId);
  }

  private getSecurityQuestionsErrorHandler(data = null) {
    if (this.firebaseId) this.errorGetQuestions = true;
    const { extraData } = data.error;

    if (extraData) return this.modalProvider.openSecurityQuestionsErrorModal(extraData, extraData.length)
      .afterClosed().subscribe(() => this.firebaseId ? null : this.goBack());

    this.modalProvider.openCustomErrorModal(onServiceError)
      .afterClosed().subscribe(() => this.goBack());
  }

  private createQuestionForm(question: Question): UntypedFormGroup {
    return this.formBuilder.group({
      questionId: [question.id, Validators.required],
      answerId: ['', Validators.required],
    });
  }

  private setNavigationQuestions() {
    if (!this.router.getCurrentNavigation().extras.state) return;
    const { questions, transactionId, interactiveQuestionId, rut, documentId, firebaseId, isSinacofi }
      = this.router.getCurrentNavigation().extras.state;
    this.questions = questions;
    this.transactionId = transactionId;
    this.interactiveQuestionId = interactiveQuestionId;
    this.rut = rut;
    this.documentId = documentId;
    this.firebaseId = firebaseId;
    this.isSinacofi = isSinacofi;
  }

  private setTransactionData(questions, transactionId, interactiveQuestionId) {
    this.questions = questions;
    this.transactionId = transactionId;
    this.interactiveQuestionId = interactiveQuestionId;
    this.resetForm();
    this.setQuestions();
  }

  private resetForm() {
    this.form.removeControl('questions');
    const questionsControl = this.formBuilder.array([], Validators.required);
    this.form.addControl('questions', questionsControl);
  }

  private setQuestions() {
    const questions = this.form.get('questions') as UntypedFormArray;
    this.questions.forEach(question => {
      const form = this.createQuestionForm(question);
      questions.push(form);
    });
  }
}
