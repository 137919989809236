import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '@env';
import { Injectable } from '@angular/core';
import { TRANSFER_URL } from '@constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (environment.mockHttpCalls) return true;
    const isAuthenticated = this.authenticationService.isAuthenticated();
    if (!isAuthenticated) this.router.navigateByUrl(TRANSFER_URL);
    return isAuthenticated;
  }
}
