import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BUSINESS_TUBE_MODAL_UPDATE_STATUS_TEXTS } from '@constants';

@Component({
  selector: 'app-business-tube-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class BusinessTubeConfirmationComponent implements OnInit {
  public modalTexts: { title: string, subtitle: string, button: string };
  public contactRadioForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<BusinessTubeConfirmationComponent>) { }

  public ngOnInit() {
    switch (true) {
      case this.next:
        this.modalTexts = BUSINESS_TUBE_MODAL_UPDATE_STATUS_TEXTS.next;
        break;
      case this.previous:
        this.modalTexts = BUSINESS_TUBE_MODAL_UPDATE_STATUS_TEXTS.previous;
        break;
      case this.archive:
        this.modalTexts = BUSINESS_TUBE_MODAL_UPDATE_STATUS_TEXTS.archive;
        break;
      case this.unarchive:
        this.modalTexts = BUSINESS_TUBE_MODAL_UPDATE_STATUS_TEXTS.unarchive;
        break;
    }
  }

  get next(): boolean {
    return this.data.action === 'next' && this.data.status !== 'closed';
  }

  get previous(): boolean {
    return this.data.action === 'previous';
  }

  get archive(): boolean {
    return this.data.action === 'next' && this.data.status === 'closed' || this.data.archiveFunctionality && this.data.archiving;
  }

  get unarchive(): boolean {
    return this.data.archiveFunctionality && !this.data.archiving;
  }

  public confirmAction(action: boolean) {
    this.dialogRef.close(action);
  }
}
