import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { PLAN_VITAL_WHATSAPP_URL } from '@constants';

export interface WhatsappResultModalStatus {
  resultStatus: boolean;
}

@Component({
  selector: 'app-whatsapp-result-modal',
  templateUrl: './whatsapp-result-modal.component.html',
  styleUrls: ['./whatsapp-result-modal.component.scss']
})
export class WhatsappResultModalComponent {
  public resultStatus: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WhatsappResultModalStatus,
    public dialogRef: MatDialogRef<WhatsappResultModalComponent>,
    private location: Location,
  ) {
    this.resultStatus = data.resultStatus;
  }

  public closeDialog() {
    this.dialogRef.close(false);
  }

  public closePage() {
    this.location.back();
  }

  public goToWhatsapp(): void {
    window.location.replace(PLAN_VITAL_WHATSAPP_URL);
  }

}
