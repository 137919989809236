import { AuthenticationService } from '../authentication/authentication.service';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@env';
import { HttpClientInterceptor } from '@providers/httpClientInterceptor/httpClientInterceptor';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { TRACE_MOCKUP } from './trace.mock';
import { Util } from '@util';
import { EXECUTIVE_RUT } from 'util/storage.constants';
import { LogService } from '@services/logs/log.service';
import packageInfo from '../../../../package.json';
import { LogInterface } from '@interfaces/log.interface';

@Injectable({
  providedIn: 'root'
})
export class TraceService {
  public baseURL: string;
  private originTransfer: string = sessionStorage.getItem('originTransfer');
  private traceID: string = sessionStorage.getItem('traceID');
  private executiveRut: string;

  constructor(
    public http: HttpClientInterceptor,
    public util: Util,
    public authenticationService: AuthenticationService,
    private logService: LogService,
  ) {
    this.baseURL = environment.baseURL;
  }

  public notifyState(stepId: number, serviceIdErrors: Array<number>): Observable<any> {
    const traceData = { rut: this.authenticationService.uid(), stepId, serviceIdErrors };
    return this.http.post(`${this.baseURL}traces/`, traceData, TRACE_MOCKUP).pipe(
      catchError((error) => throwError(error)),
      retry(5)
    );
  }

  public notifyValidatedClient(rutClient: string, rutExecutive: string): Observable<any> {
    const traceData = { rutClient, rutExecutive };
    return this.http.post(`${this.baseURL}traces/post-venta/client/`, traceData, TRACE_MOCKUP).pipe(
      catchError((error) => throwError(error))
    );
  }

  public bigQueryInsert(request: any, step: string, isError: boolean, response?: any): Observable<any> {
    this.executiveRut = localStorage.getItem(EXECUTIVE_RUT);
    let traceData = {
      rut: this.authenticationService.uid(),
      request,
      step,
      date: 'AUTO',
      originCode: this.originTransfer,
      traceID: this.traceID,
      response: response ? response : 'no response',
      isError,
    } as any;

    if (this.executiveRut) traceData = { ...traceData, executiveRut: this.executiveRut };

    const logData = {
      rut: this.authenticationService.uid(),
      originCode: this.originTransfer,
      traceID: this.traceID,
      executiveRut: this.executiveRut,
      version : packageInfo.version,
    };

    this.sendLog(logData, false, 'Front Version Validator');
    return this.http.post(`${this.baseURL}traces/big-query`, traceData, TRACE_MOCKUP).pipe(
      catchError((error) => throwError(error)),
      retry(5)
    );
  }

  private sendLog(logObject: any, isError: boolean, typeLog: string) {
    const request = {
      logObject,
      isError,
      customMessage: `[WebTransfer] Response of ${typeLog}`,
    } as LogInterface;
    this.logService.sendLog(request);
  }
}
