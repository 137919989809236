import { take } from 'rxjs/operators';

import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';

import { PATH_WITHDRAWAL } from '@constants';
import { environment } from '@env';
import { GovernmentCheckResponse } from '@interfaces/client.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ClientService } from '@services/client/client.service';
// TODO: descomentar cuando toque deshabilitar el segundo retiro
// import { WithdrawalService } from '@services/withdrawal/withdrawal.service';

@Component({
  selector: 'app-affiliate-request-10',
  templateUrl: './affiliate-request-10.component.html',
  styleUrls: ['./affiliate-request-10.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AffiliateRequest10Component implements OnInit {
  @Input() private uid: string;
  @Input() private pageId: string;
  public bonusAmount: number;
  public isBeneficiary: 'S' | 'N' | 'P';
  public showGovernmentBonusCard = false;
  public isSecondWithdrawalAvailable = true;

  constructor(
    private clientService: ClientService,
    private loadingProvider: LoadingProvider,
    // TODO: descomentar cuando toque deshabilitar el segundo retiro
    // private withdrawalService: WithdrawalService,
  ) { }

  public ngOnInit() {
    // TODO: descomentar cuando toque deshabilitar el segundo retiro
    // this.checkWithdrawalAvailability();
  }

// TODO: descomentar cuando toque deshabilitar el segundo retiro
/*   private checkWithdrawalAvailability() {
    this.loadingProvider.showLoading();
    this.withdrawalService.isWithdrawalAvailable(2).
    subscribe((response) => {
      const { available } = response;
      this.isSecondWithdrawalAvailable = available;
      this.loadingProvider.hideLoading();
    },
    (error) => {
      this.isSecondWithdrawalAvailable = false;
      this.loadingProvider.hideLoading();
    });
  } */

  public async checkIsBeneficiary(): Promise<void> {
    try {
      this.loadingProvider.showLoading();
      const response = await this.clientService.checkIsGovernmentBeneficiary().pipe(take(1)).toPromise() as GovernmentCheckResponse;
      this.bonusAmount = response.bonusAmount;
      this.isBeneficiary = response.withdrawalAvailable;
      setTimeout(() => { this.showGovernmentBonusCard = true; }, 300);
    } finally {
      this.loadingProvider.hideLoading();
    }
  }

  public goToRequest10(numWithdrawal: number | string): void {
    // eslint-disable-next-line
    if(numWithdrawal == 1) return;
    // TODO: descomentar cuando toque deshabilitar el segundo retiro
    // eslint-disable-next-line
    // if (numWithdrawal == 2) this.checkWithdrawalAvailability();
    // eslint-disable-next-line
    // if (numWithdrawal == 2 && !this.isSecondWithdrawalAvailable) return;
    const path = PATH_WITHDRAWAL[numWithdrawal];
    window.open(`${environment.baseRequest10URL}${path}?index=${this.uid}&page=${this.pageId}`, '_blank');
  }

  public get isBeneficiaryChecked() { return this.isBeneficiary === 'S'; }
  public get isNotBeneficiaryChecked() { return this.isBeneficiary === 'N'; }
  public get isPendingBeneficiaryChecked() { return this.isBeneficiary === 'P'; }
}
