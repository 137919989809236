import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import * as Chartist from 'chartist';
import { BarChart, LineChart, PieChart } from 'chartist';
import { Subscription } from 'rxjs';

export type ChartType = 'Pie' | 'Bar' | 'Line';

export type ChartInterfaces =
  | Chartist.PieChart
  | Chartist.BarChart
  | Chartist.LineChart;
export type ChartOptions =
  | Chartist.BarChartOptions
  | Chartist.LineChartOptions
  | Chartist.PieChartOptions;
export type ResponsiveOptionTuple = Chartist.ResponsiveOptions<
  ChartOptions
>;
export type ResponsiveOptions = ResponsiveOptionTuple[];

export interface ChartEvent {
  [eventName: string]: (data: any) => void;
}

export const POINT_ANIMATION = 'width-pulse 3s ease infinite';
export const BLACK_COLOR = 'rgb(67, 67, 67)';
export const GREY_COLOR = 'rgba(0, 0, 0, 0.4)';
export const FORMAT_NUMBER_REGEX = /(\d)(?=((\d{3}){1,10})+(?!\d))/g;

@Component({
  selector: 'app-chartist',
  template: '<ng-content></ng-content>',
})
export class ChartistComponent implements OnInit, OnChanges, OnDestroy {
  private didEnterSubscription: Subscription;

  @Input()
  public data: Promise<Chartist.SeriesObject> | Chartist.SeriesObject;

  @Input() public type: Promise<ChartType> | ChartType;

  @Input()
  public options: ChartOptions;

  @Input()
  public defaultOptions: ChartOptions = {
    axisX: {
      showGrid: false,
      offset: 70,
    },
    axisY: {
      showGrid: false,
      showLabel: false,
      offset: 0,
    },
    chartPadding: {
      left: 30,
      right: 50,
    },
    lineSmooth: false,
    fullWidth: true,
  };

  @Input()
  public responsiveOptions: Promise<ResponsiveOptions> | ResponsiveOptions;

  @Input() public events: ChartEvent;

  public chart: ChartInterfaces;

  private element: HTMLElement;

  constructor(
    element: ElementRef,
  ) {
    this.element = element.nativeElement;
  }

  public ngOnInit(): Promise<ChartInterfaces> {
    if (!this.type || !this.data) {
      return Promise.reject('Expected at least type and data.');
    }

    return this.renderChart().then((chart) => {
      if (this.events !== undefined) {
        this.bindEvents(chart);
      }
      return chart;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.update(changes);
  }

  public ngOnDestroy(): void {
    if (this.chart) {
      this.chart.detach();
    }
    if (this.didEnterSubscription) {
      this.didEnterSubscription.unsubscribe();
    }
  }

  public renderChart(): Promise<ChartInterfaces> {
    const promises: Array<any> = [
      this.type,
      this.element,
      this.data,
      this.options,
      this.responsiveOptions,
    ];

    return Promise.all(promises).then((values) => {
      const [type, element, data, options, responsiveOptions] = values;
      const chartTypes = {
        Bar: BarChart,
        Line: LineChart,
        Pie: PieChart,
      };

      if (data && 'series' in data && data.series.length && !Array.isArray(data.series[0])) {
        const series = data.series as Array<{ name: string, data: Array<number> }>;

        const originalSeries = series.filter(serie => !serie.name.endsWith('hidden'));

        series.length = 0;
        series.push(...originalSeries);
      }

      this.options = { ...this.defaultOptions, ...options };
      
      if (chartTypes[type]) {
        this.chart = new chartTypes[type](element, data, this.options, responsiveOptions);
      } else {
        throw new Error(`${type} is not a known chart type`);
      }

      return this.chart;
    });
  }

  public update(changes: SimpleChanges): void {

    if (!this.chart || 'type' in changes) {
      this.renderChart();
      return;
    }
    if (changes.data) {
      this.data = changes.data.currentValue;
    }

    if (changes.options) {
      this.options = changes.options.currentValue;
    }

    (this.chart as any).update(this.data, this.options);
  }

  public bindEvents(chart: any): void {
    for (const event of Object.keys(this.events)) {
      chart.on(event, this.events[event]);
    }
  }
}

// https://gitlab.influitive.io/beaulne/chartist-plugin-tooltip-nojQuery
const defaultOptions = {
  className: 'ct-tooltip',
  translation: () => ({ left: 0, top: 0 }),
};

Object.assign(Chartist, { plugins: [] });
