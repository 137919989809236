import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CANCEL_REMOTE_TRANSFER_MODAL_TEXT, TRACE_STEP,
  TRANSFER_AFP_URL, TRANSFER_ORIGINS, TRANSFER_STATES,
  VALIDATION_OPERATIONS
} from '@constants';
import { BiometricLoginData, SecurityQuestionsBiometricLoginData } from '@interfaces/biometricLoginData.interface';
import { BiometricResponse } from '@interfaces/biometricResponse.interface';
import { ClientPhoneValidation, ClientValidationData } from '@interfaces/client.interface';
import { EmailDomainValidator } from '@providers/emailDomainValidator/emailDomainValidator';
import { GoogleAnalyticsProvider } from '@providers/googleAnalytics/googleAnalytics';
import { ModalProvider } from '@providers/modal/modal';
import { TransferProvider } from '@providers/transfer/transfer.provider';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { TraceService } from '@services/trace/trace.service';
import { Util } from '@util';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { ValidateRut } from 'app/validators/rut.validator';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { PENDING_AUTH } from 'util/storage.constants';
import { DataSnapshot } from '@angular/fire/compat/database/interfaces';

export type loginStepType = 'login' | 'loginIdentityCard' | 'waitingIdentityCard' |
  'waiting' | 'errorValidation' | 'errorService' | 'ok';

@Component({
  selector: 'app-assisted',
  templateUrl: './assisted.component.html',
  styleUrls: ['./assisted.component.scss']
})

export class AssistedComponent implements OnInit, OnDestroy {
  public executiveForm: UntypedFormGroup;
  public clientForm: UntypedFormGroup;
  public clientSCForm: UntypedFormGroup;
  public clientESCForm: UntypedFormGroup;
  public executiveRut: string;
  public pageId: string;
  public token: string;
  public regexWhiteSpace = / /g;
  public url: string;
  public executiveValidationStatus: loginStepType = 'login';
  public clientValidationStatus: loginStepType = 'login';
  public clientPhoneValidationObject: ClientPhoneValidation = {
    status: 'login',
    timeOut: false,
  } as ClientPhoneValidation;
  public initialLogin = 'login';
  public loading: boolean;
  public validationInfo: BiometricLoginData;
  public maxLengthForRut = 12;
  public maxLengthForName = 120;
  public maxLengthForPhone = 9;
  public biometricError = { code: null, description: '' };
  public operations = [{
    name: VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION,
    displayName: 'Validar con huella',
    userTypeOption: 'executive',
  }, {
    name: VALIDATION_OPERATIONS.BARCODE,
    displayName: 'Validar con cédula',
    userTypeOption: 'executive',
  },
  {
    name: VALIDATION_OPERATIONS.EMAIL_SECURITY_QUESTIONS,
    displayName: 'Validar vía web',
    userTypeOption: 'client',
  }];
  public operation = VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
  public userLoginType: 'executive' | 'client' = 'executive';
  public currentValidationRef: AngularFireObject<any>;
  private ngUnsubscribe = new Subject();
  private uid: string;
  private biometricWindow: Window;
  private validStatus = 'activo';
  private validStatusCode = 200;
  private biometricLogin = { operation: '', barcode: '' };

  private clientListRef: AngularFireList<any>;
  private isFirebaseAssisted: boolean;
  private isBiometricAssisted: boolean;

  constructor(
    private emailDomainValidator: EmailDomainValidator,
    private formBuilder: UntypedFormBuilder,
    private util: Util,
    private firebaseDatabase: AngularFireDatabase,
    private ngZone: NgZone,
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalProvider: ModalProvider,
    private googleAnalyticsProvider: GoogleAnalyticsProvider,
    private traceService: TraceService,
    private transferProvider: TransferProvider,
  ) {
    this.url = '';
    this.biometricWindow = null;
    this.googleAnalyticsProvider.registerPageView('assisted');
    sessionStorage.removeItem('isBiometricAssisted');
    this.executiveForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      operation: [VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION, [Validators.required]]
    });
    this.clientForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      operation: [VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION, [Validators.required]]
    });
    this.clientSCForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      operation: [VALIDATION_OPERATIONS.SECURITY_QUESTIONS, [Validators.required]]
    });
    this.clientESCForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      operation: [VALIDATION_OPERATIONS.EMAIL_SECURITY_QUESTIONS, [Validators.required]]
    });
    this.clientListRef = this.firebaseDatabase.list('client');
  }

  get executiveRutControl() { return this.executiveForm.controls['rut']; }

  get clientRutControl() { return this.clientForm.controls['rut']; }

  get disableExecutiveForm() { return this.executiveForm.invalid || !this.executiveForm.dirty || !this.executiveForm.touched; }

  get disableClientForm() { return this.clientForm.invalid || !this.clientForm.dirty || !this.clientForm.touched; }

  get disableClientSCForm() { return this.clientSCForm.invalid || !this.clientSCForm.dirty || !this.clientSCForm.touched; }

  get disableClientESCForm() { return this.clientESCForm.invalid || !this.clientESCForm.dirty || !this.clientESCForm.touched; }

  get userTypeName() { return this.userLoginType === 'executive' ? 'ejecutivo' : 'cliente'; }

  get displayNullifyButton() {
    return !(this.clientPhoneValidationObject.data.status === TRANSFER_STATES.NULLIFIED ||
      this.clientPhoneValidationObject.data.status === TRANSFER_STATES.APPROVED) &&
      this.clientPhoneValidationObject.connected;
  }

  public async ngOnInit() {
    await this.authenticationService.logout();
    this.getToken();

    this.executiveForm.get('operation').valueChanges.subscribe((option) => this.changeOptionSelected(option));
    this.clientForm.get('operation').valueChanges.subscribe((option) => this.changeOptionSelected(option));
    this.clientSCForm.get('operation').valueChanges.subscribe((option) => this.changeOptionSelected(option));
    this.clientESCForm.get('operation').valueChanges.subscribe((option) => this.changeOptionSelected(option));

  }

  public retryLogin() {
    this.validation();
  }

  public getOperations(userType: string) {
    if (userType === 'client') {
      return this.operations;
    }
    return this.operations.filter(({ userTypeOption }) => userType === userTypeOption);
  }

  public goBack() {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    this[userStatus] = this.initialLogin;
    this.clientPhoneValidationObject.status = 'login';
  }

  public retryBiometricLogin() {
    if (this.operation === VALIDATION_OPERATIONS.SECURITY_QUESTIONS)
      return this.validationRemote();
    this.validate(this.userLoginType);
  }

  public validate(type: string) {
    const formName = `${type}Form`;
    const form = this[formName] as UntypedFormGroup;
    const { rut } = form.value;

    if (form.invalid) return;
    this.verifyBiometricIdentity(rut, type);
  }

  public async buildClientObject(remote: boolean) {
    // BYPASS
    // this.validationInfo.executive = EXECUTIVE_ACEPTA_BYPASS;
    this.clientPhoneValidationObject.data = {
      attempts: 0,
      date: Date.now(),
      executiveRut: this.validationInfo.executive.rut,
      ring: !remote,
    } as ClientValidationData;
    this.clientPhoneValidationObject.data.traceId = this.util.setAndGetTraceId();
    this.clientPhoneValidationObject.data.origin = this.util.getOriginTransfer();
    if (remote) {
      const { rut, name, phone } = this.clientSCForm.value;
      this.clientPhoneValidationObject.data.rut = rut;
      this.clientPhoneValidationObject.data.name = name;
      this.clientPhoneValidationObject.data.phone = phone;
      this.clientPhoneValidationObject.data.status = TRANSFER_STATES.PENDING;
    } else {
      const { rut, email, phone, name } = this.clientESCForm.value;
      this.clientPhoneValidationObject.data.rut = rut;
      this.clientPhoneValidationObject.data.name = name;
      this.clientPhoneValidationObject.data.phone = phone;
      this.clientPhoneValidationObject.data.email = email;
      this.clientPhoneValidationObject.data.status = TRANSFER_STATES.ASSISTED_PENDING;
    }
    localStorage.setItem('assistedEmail', this.clientPhoneValidationObject.data.email);
    localStorage.setItem('assistedPhone', String(this.clientPhoneValidationObject.data.phone));
    this.clientPhoneValidationObject.status = 'validation';
    const refAnexo = this.firebaseDatabase.object(`executive_anexo/${this.validationInfo.executive.rut}`);
    this.clientPhoneValidationObject.data.anexo = await refAnexo.valueChanges().pipe(first()).toPromise() as string;
  }

  public async saveClient() {
    const sessionData = await this.authenticationService.signInAnonymously();
    if (!sessionData || !sessionData.token) return;
    this.isFirebaseAssisted = this.operation === VALIDATION_OPERATIONS.EMAIL_SECURITY_QUESTIONS;
    this.setPhoneOrigin();
    await this.buildClientObject(!this.isFirebaseAssisted);
    this.clientListRef.push(this.clientPhoneValidationObject.data).then(async (response) => {
      localStorage.setItem(PENDING_AUTH, response.key);
      this.currentValidationRef = this.firebaseDatabase.object(`client/${response.key}`);
      if (this.isFirebaseAssisted) {
        const codeRef = this.firebaseDatabase.object('client_code');
        const rut = this.clientPhoneValidationObject.data.rut;
        const code = this.util.rutClean(rut).substring(0, rut.length - 1) + response.key.substr(-4);
        const field = {};
        field[code] = response.key;
        await codeRef.update(field);
        const request = {
          name: this.clientPhoneValidationObject.data.name,
          email: this.clientPhoneValidationObject.data.email,
          phone: this.clientPhoneValidationObject.data.phone,
          traceId: this.clientPhoneValidationObject.data.traceId,
          origin: this.clientPhoneValidationObject.data.origin,
          code,
        };
        this.clientPhoneValidationObject.code = code;
        this.authenticationService.sendAssistedCode(request, this.util.rutClean(rut)).subscribe();
      }
      this.currentValidationRef.valueChanges()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: ClientValidationData) => {
          this.clientPhoneValidationObject.connected = true;
          this.clientPhoneValidationObject.data = data;
        });
    })
      .catch((error) => {
        // TODO: do something with this.
        this.clientPhoneValidationObject.status = 'failedPush';
        this.traceService.bigQueryInsert(
          this.clientPhoneValidationObject.data,
          TRACE_STEP.toCallCenter,
          true,
          error
        );
      });
  }

  public loadOngoingValidation(key: string) {
    this.currentValidationRef = this.firebaseDatabase.object(`client/${key}`);
    this.currentValidationRef.valueChanges()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ClientValidationData) => {
        if (data.status !== TRANSFER_STATES.ANSWERING && data.status !== TRANSFER_STATES.ASSISTED_PENDING
          && data.status !== TRANSFER_STATES.PENDING && data.status !== TRANSFER_STATES.APPROVED) {
          return localStorage.removeItem(PENDING_AUTH);
        }
        this.clientPhoneValidationObject.connected = true;
        this.clientPhoneValidationObject.data = data;
        this.isFirebaseAssisted = data.ring;
        this.operation = this.isFirebaseAssisted ? VALIDATION_OPERATIONS.EMAIL_SECURITY_QUESTIONS
          : VALIDATION_OPERATIONS.SECURITY_QUESTIONS;
        this.clientPhoneValidationObject.status = 'validation';
      });
  }

  public validationRemote() {
    this.loading = true;
    const firebaseId = localStorage.getItem(PENDING_AUTH);
    const validationData = {
      client: this.clientPhoneValidationObject.data.data.afiliado,
      executive: this.validationInfo.executive,
      firebaseId,
    } as SecurityQuestionsBiometricLoginData;
    if (this.isFirebaseAssisted) validationData.biotablet = true;
    this.authenticationService.securityQuestionsBiometricLogin(validationData)
      .subscribe(() => {
        localStorage.removeItem(PENDING_AUTH);
        this.transferProvider.setIsFirebaseAssisted(this.isFirebaseAssisted);
        this.transferProvider.setFirebaseId(firebaseId);
        this.router.navigate([TRANSFER_AFP_URL]);
        // this.router.navigate([TRANSFER_AFP_URL], { state: { firebaseId, isFirebaseAssisted: this.isFirebaseAssisted } });
      }, () => this.handleUserValidationError(true));
  }

  private setPhoneOrigin() {
    if (this.isFirebaseAssisted) return sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.BIOTABLET_CODE);
    sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.BIOTABLET_RING);
  }

  public async validateEmailDomain() {
    const emailControl = this.clientESCForm.controls['email'];
    const email = this.clientESCForm.value['email'];
    if (emailControl.hasError('email') || emailControl.hasError('pattern') || !email) return;
    const validDomain = await this.emailDomainValidator.validateEmailDomain(email);
    if (!validDomain) return emailControl.setErrors({ invalidDomain: true });
  }

  public async nullifyClient() {
    const sessionData = await this.authenticationService.signInAnonymously();
    if (!sessionData || !sessionData.token) return;
    this.currentValidationRef.update({ status: TRANSFER_STATES.NULLIFIED }).then(() => {
      localStorage.removeItem(PENDING_AUTH);
    });

  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public openNullifyModal() {
    this.modalProvider.cancelRemoteRequestModal(CANCEL_REMOTE_TRANSFER_MODAL_TEXT)
      .afterClosed().subscribe((response) => {
        if (!response) return;
        this.nullifyClient();
      });
  }

  private changeOptionSelected(option) {
    this.operation = option;
    const userStatus = `${this.userLoginType}ValidationStatus`;
    if (option === VALIDATION_OPERATIONS.BARCODE && this[userStatus] === 'login') this.setLoginWithIdentityCard();
    if (option === VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION && this[userStatus] === 'loginIdentityCard') this.setLogin();
  }

  private setLoginWithIdentityCard() {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    this[userStatus] = 'loginIdentityCard';
    this.initialLogin = 'loginIdentityCard';
  }

  private setLogin() {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    this[userStatus] = 'login';
    this.initialLogin = 'login';
  }

  private verifyBiometricIdentity(rut: string, type: string) {
    const statusName = `${type}ValidationStatus`;
    this.generateUrlAndPageId(this.util.rutClean(rut));
    this[statusName] = this.initialLogin === 'loginIdentityCard' ? 'waitingIdentityCard' : 'waiting';
    // COMMENT FOR BYPASS
    this.callBiometricApk();
    this.validateUser(type);
  }

  private generateUrlAndPageId(rut: string) {
    const { url, pageId } = this.getUrlAndPageId(rut);

    this.url = url;
    this.pageId = pageId;
  }

  private getUrlAndPageId(rut: string) {
    const userStatus = `${this.userLoginType}ValidationStatus`;
    const isVerifyIdentity = this.operation === VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
    const failValidation = this[userStatus] === 'errorValidation';

    if (isVerifyIdentity) return this.authenticationService.biometricUrl(rut, this.token, this.uid);
    if (this.initialLogin === 'loginIdentityCard' || failValidation) {
      return this.authenticationService.biometricUrlForIdentityCard(this.token, this.uid);
    }
    if (this.initialLogin === 'login' || failValidation) {
      return this.authenticationService.biometricUrl(rut, this.token, this.uid, this.biometricLogin.barcode);
    }
  }

  private callBiometricApk() {
    this.biometricWindow = window.open(this.url);
    if (this.biometricWindow) { setTimeout(() => this.biometricWindow.close(), 1000); }
  }

  private validateUser(type: string) {
    this.loading = true;
    this.cancelOnChangeDataEvent();

    // BYPASS
    // const mock = BIOMETRIC_AUTHENTICATION_BYPASS;

    // if (type === 'executive') {
    //   this.setValidationInformation(type, mock);
    //   return this.validation();
    // }

    this.firebaseDatabase.database.ref(`user/${this.uid}/${this.pageId}`)
      .on('value', (data) => this.handleFirebaseData(data, type));
  }

  private async getToken() {
    const sessionData = await this.authenticationService.signInAnonymously();

    if (!sessionData || !sessionData.token) return this.invalidToken();
    this.token = sessionData.token;
    this.uid = sessionData.uid;
  }

  private invalidToken() {
    this.modalProvider.openErrorTokenInvalid().afterClosed().subscribe(() => this.getToken());
  }

  private handleFirebaseData(data: DataSnapshot, type: string) {
    const firebaseData = data.val();
    if (!firebaseData || firebaseData.status !== this.validStatus) return;
    this.ngZone.run(async () => {
      this.loading = false;

      if (this.isBiometricResponseInvalid(firebaseData)) return this.handleInvalidBiometricResponse(firebaseData);

      const isBarcodeOperation = this.operation === VALIDATION_OPERATIONS.BARCODE;
      const userStatus = `${this.userLoginType}ValidationStatus`;
      if (isBarcodeOperation && this[userStatus] === 'waitingIdentityCard') return this.setBarCode(userStatus, firebaseData);

      this.setValidationInformation(type, firebaseData);
      this.validation();
      this.cancelOnChangeDataEvent();
    });
  }

  private isBiometricResponseInvalid(data: any) {
    return data.verification_codigo !== this.validStatusCode;
  }

  private handleInvalidBiometricResponse(data) {
    const { verification_codigo: code, verification_mensaje: description } = data;
    this.biometricError = { code, description };
    this.handleUserValidationError(false);
    this.cancelOnChangeDataEvent();
  }

  private setBarCode(userStatus: string, firebaseData) {
    this.biometricLogin.barcode = firebaseData.verification_codigo_barra;
    this[userStatus] = 'login';
    this.initialLogin = 'login';
    this.cancelOnChangeDataEvent();
  }

  private buildUserRequest(biometricData: BiometricResponse) {
    let { verification_apellidos: lastName, verification_nombres: name } = biometricData;
    const { verification_rut: rut, verification_transaccion: verificationCode, verification_url: verificationUrl } = biometricData;

    lastName = lastName.replace(this.regexWhiteSpace, '-');
    name = name ? name.trim().replace(this.regexWhiteSpace, '-') : lastName;

    return {
      lastName,
      motherLastName: lastName,
      name,
      rut,
      verificationCode,
      verificationUrl,
    };
  }

  private cancelOnChangeDataEvent() {
    this.firebaseDatabase.database.ref(`user/${this.uid}/${this.pageId}`).off('value');
  }

  private setValidationInformation(type: string, firebaseData) {
    this.validationInfo = { ...this.validationInfo };
    this.validationInfo[type] = this.buildUserRequest(firebaseData);

    if (this.userLoginType === 'executive') {
      this.executiveRut = firebaseData.verification_rut;
    }
  }

  private validation() {
    this.loading = true;

    const serviceLogin = this.userLoginType === 'executive' ?
      this.authenticationService.executiveLogin(this.executiveRut) :
      this.authenticationService.biometricLogin(this.validationInfo, TRANSFER_ORIGINS.BIOTABLET_BIOM);

    // BYPASS
    // return this.handleUserValidationSuccess();

    serviceLogin
      .subscribe(() => this.handleUserValidationSuccess(),
        () => this.handleUserValidationError(true));
  }

  private async handleUserValidationSuccess() {
    this.loading = false;
    if (this.userLoginType === 'executive') {
      this.executiveValidationStatus = 'ok';
      if (this.operation === VALIDATION_OPERATIONS.BARCODE) this.operation = VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
      this.userLoginType = 'client';
      this.clientValidationStatus = 'login';
      this.initialLogin = 'login';
      const pendingKey = localStorage.getItem(PENDING_AUTH);
      if (pendingKey) { this.loadOngoingValidation(pendingKey); }
      return;
    }
    this.clientValidationStatus = 'ok';
    this.setIsBiometricAssisted();
    await this.router.navigate([TRANSFER_AFP_URL]);
  }

  private setIsBiometricAssisted() {
    this.isBiometricAssisted = true;
    // ***** Devices *****
    this.transferProvider.setIsFirebaseAssisted(true);
    this.transferProvider.setFirebaseId(localStorage.getItem(PENDING_AUTH));
    sessionStorage.setItem('isBiometricAssisted', String(this.isBiometricAssisted));
    sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.BIOTABLET_BIOM);
  }

  private handleUserValidationError(isServerError: boolean) {
    this.clientValidationStatus = isServerError ? 'errorService' : 'errorValidation';
    this.executiveValidationStatus = isServerError ? 'errorService' : 'errorValidation';
    this.clientPhoneValidationObject.status = isServerError ? 'errorService' : 'errorValidation';
    this.loading = false;
  }
}
