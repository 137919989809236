import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { TRANSFER_STATES } from '@constants';
import { LogInterface } from '@interfaces/log.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { LogService } from '@services/logs/log.service';



@Injectable()
export class FirebaseUtil {
  public transferStates: any = TRANSFER_STATES;

  constructor(
    private authenticationService: AuthenticationService,
    private logService: LogService,
    private loadingProvider: LoadingProvider,
    private firebaseDatabase: AngularFireDatabase,
    ) {

    }

  public confirm(firebaseId: string) {
    this.authenticationService.signInAnonymously()
    .then((response) => {
      this.loadingProvider.showLoading();
      this.sendLog({ ...response, firebaseId }, false, 'confirm');
      return this.updateFirebaseRef({ status: this.transferStates.CONFIRMED }, firebaseId);
    })
    .catch((error) => this.sendLog({ ...error, firebaseId }, true, 'confirm'));
  }

  public cancel(firebaseId: string) {
    this.authenticationService.signInAnonymously().then((response) => {
      this.sendLog({ ...response, firebaseId }, false, 'cancel');
      return this.updateFirebaseRef({ status: this.transferStates.REJECTED }, firebaseId);
    })
    .catch((error) => this.sendLog({ ...error, firebaseId }, true, 'cancel'));
  }

  public nullify(firebaseId: string) {
    this.authenticationService.signInAnonymously().then((response) => {
      this.sendLog({ ...response, firebaseId }, false, 'nullify');
      return this.updateFirebaseRef({ status: this.transferStates.NULLIFIED }, firebaseId);
    })
    .catch((error) => this.sendLog({ ...error, firebaseId }, true, 'nullify'));
  }

  private updateFirebaseRef(data: any, firebaseId: string) {
    const ref = this.firebaseDatabase.object(`client/${firebaseId}`);
    return ref.update(data)
    .then(() => this.sendLog(firebaseId , false, 'updateFirebaseRef'))
    .catch((error) => this.sendLog({ ...error, firebaseId }, true, 'updateFirebaseRef'));
  }

  private sendLog(response: any, isError: boolean, typeError: string) {
    const { token, ...logObject } = response;
    const request = {
      logObject,
      isError,
      customMessage: `[WebTransfer] Response of ${typeError}`,
    } as LogInterface;
    this.logService.sendLog(request);
  }

}
