import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '@env';
import { Injectable } from '@angular/core';
import { BIOMETRIC_VALIDATION_URL, POST_VENTA_LOGIN_URL } from '@constants';
import { DEVICE_TYPE, EXECUTIVE_RUT, EXECUTIVE_SESSION } from 'util/storage.constants';

@Injectable({
  providedIn: 'root'
})
export class PostVentaGuardService {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (environment.mockHttpCalls) return true;
    const isAuthenticated = this.authenticationService.isAuthenticated();
    const executiveSession = localStorage.getItem(EXECUTIVE_RUT) === sessionStorage.getItem(EXECUTIVE_SESSION);
    if (isAuthenticated || executiveSession) return true;
    const isBiotablet = localStorage.getItem(DEVICE_TYPE) === 'biotablet';
    isBiotablet ? this.router.navigateByUrl(POST_VENTA_LOGIN_URL) : this.router.navigateByUrl(BIOMETRIC_VALIDATION_URL);
    return false;
  }
}
