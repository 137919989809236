export const EXECUTIVE_ACEPTA_BYPASS = {
  name: 'Nombre',
  lastName: 'Apellido',
  motherLastName: 'Materno',
  rut: 19,
  verification: {
    code: '0',
    date: '05012021',
    providerId: 'VI03',
    type: 'BORRAR',
    keyUrl: 'BORRAR',
    userUrl: 'BORRAR',
    url: 'BORRAR',
  }
};

export const BIOMETRIC_AUTHENTICATION_BYPASS = {
  pageId: '123',
  status: '123',
  verification_apellidos: '123',
  verification_codigo: '123',
  verification_dedo: '123',
  verification_genero: '123',
  verification_mensaje: '123',
  verification_nacimiento: '123',
  verification_nacionalidad: '123',
  verification_nombres: '123',
  verification_resultado: '123',
  verification_rut: '123',
  verification_serie: '123',
  verification_transaccion: '123',
  verification_url: '123',
  verification_vencimiento: '123',
  webcallbackmethod: '123',
};
