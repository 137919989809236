import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpErrorCodes } from '@constants';
import { environment } from '@env';
import { HttpParams } from '@interfaces/httpParams.interface';
import { Mockup } from '@interfaces/mockup.interface';
import { Observable, of, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';
import { EnvInterface } from '../../../environments/env';

@Injectable()
export class HttpClientInterceptor {
  private httpSuccessCode = 200;
  private headers = new HttpHeaders();
  private environment: EnvInterface;

  constructor(private http: HttpClient) {
    this.environment = environment;
  }

  public delete(url: string, mockup: Mockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'delete', url, mockup, data: false, customHeaders });
  }

  public get(url: string, mockup: Mockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'get', url, mockup, data: false, customHeaders });
  }

  public post(url: string, data: any, mockup: Mockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'post', url, mockup, data, customHeaders });
  }

  public put(url: string, data: any, mockup: Mockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'put', url, mockup, data, customHeaders });
  }

  public patch(url: string, data: any, mockup: Mockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'patch', url, mockup, data, customHeaders });
  }

  private mockedResponse(mockup: Mockup, failure: boolean = false): Observable<any> {
    const responseStatus =
      (mockup.failures && (Math.random() > this.environment.mockedResponseSuccessRate || failure)) ? 'failures' : 'success';
    const responseNumber = this.environment.mockedResponseRandom ? Math.floor(Math.random() * mockup[responseStatus].length) : 0;

    const responseOptionsParams = {
      status: responseStatus === 'success' ? this.httpSuccessCode : httpErrorCodes.badRequest.code,
    };
    if (responseStatus === 'success') {
      responseOptionsParams['body'] = mockup[responseStatus][responseNumber].response;
    } else {
      responseOptionsParams['error'] = mockup[responseStatus][responseNumber].response;
    }
    const response = new HttpResponse(responseOptionsParams);
    const responseError = new HttpErrorResponse(responseOptionsParams);

    // Keep this log to see the mock used when forcing an error
    // eslint-disable-next-line no-console
    if (failure) console.log('mockedResponse', responseError.error);

    return responseStatus === 'success' ? of(response.body).pipe(delay(this.environment.mockedResponseDelay)) : throwError(responseError);
  }

  private httpCall({ method, url, mockup, data, customHeaders }: HttpParams): Observable<any> {
    const header = customHeaders ? customHeaders : this.headers;
    if (this.shouldItMockFailure(url)) {
      // Keep this log to see the url where a mocked error will be forced
      // eslint-disable-next-line no-console
      console.log('urlErrorMocked', url);
      return this.mockedResponse(mockup, true);
    }
    if (this.shouldUseMocks(url)) return this.mockedResponse(mockup);

    if (method === 'delete' || method === 'get') return this.http[method](url, { headers: header });

    return this.http[method]<any>(url, data, { headers: header });
  }

  private shouldItMockFailure(url: string) {
    return this.isServiceOnList(url, this.environment.mockedUrlFailure) && this.environment.mockHttpCalls;
  }

  private shouldUseMocks(url: string) {
    return !this.isServiceOnList(url, this.environment.servicesNotMocked) && this.environment.mockHttpCalls;
  }

  private isServiceOnList(url: string, mockUrlList: string[]): boolean {
    return mockUrlList.some((mockUrl) => url.includes(mockUrl));
  }
}

