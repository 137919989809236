import { Component, OnInit } from '@angular/core';
import { VALIDATION_OPERATIONS } from '@constants';
import { ActionButton } from '@interfaces/actionButton.interface';
import { ClientPhoneValidation } from '@interfaces/client.interface';
import { AuthenticationService } from '@services/authentication/authentication.service';
import 'firebase/firestore';
import { DEVICE_TYPE } from 'util/storage.constants';

export type loginStepType = 'login' | 'loginIdentityCard' | 'waitingIdentityCard' |
  'waiting' | 'errorValidation' | 'errorService' | 'ok';

export type userTypes = 'executive' | 'client';

@Component({
  selector: 'app-biodesktop',
  templateUrl: './biodesktop.component.html',
  styleUrls: ['./biodesktop.component.scss']
})

export class BioDesktopComponent implements OnInit {
  public status = 'functionalitySelection';
  public userType: userTypes = 'executive';
  public functionalitySelected: string;
  public executiveRut: string;
  public errorCode: string;
  public isCodeValidation: boolean;
  public clientPhoneValidationObject: ClientPhoneValidation;
  public executiveInfo;
  public clientInfo;
  public isBiometricValidation: boolean;
  public isFacialValidation: boolean;
  public goToCommercialManagement: boolean;
  public operations = [
    {
      name: VALIDATION_OPERATIONS.SECURITY_QUESTIONS,
      displayName: 'Validar con llamada',
    }, {
      name: VALIDATION_OPERATIONS.EMAIL_SECURITY_QUESTIONS,
      displayName: 'Validar vía web',
    }, {
      name: VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION,
      displayName: 'Validar con huella dactilar',
    },
    {
      name: VALIDATION_OPERATIONS.FACIAL_VALIDATION,
      displayName: 'Validar con biometría facial',
    }
  ];

  // BYPASS
  // private executiveBypass = {
  //   rut: '170240766',
  //   lastName: 'lastName',
  //   motherLastName: 'motherLastName',
  //   name: 'name',
  //   verificationCode: 'testCode',
  //   verificationUrl: 'testOTI'
  // };

  public buttons = [{
    action: this.selectionEmitter.bind(this, false),
    displayName: 'Traspaso',
  }, {
    action: this.selectionEmitter.bind(this, true),
    displayName: 'Gestión Comercial',
  }] as ActionButton[];

  constructor(
    private authenticationService: AuthenticationService) {
  }

  public async ngOnInit() {
    await this.authenticationService.logout();
    localStorage.setItem(DEVICE_TYPE, 'biodesktop');
  }

  private selectionEmitter(goToCommercialManagement: boolean) {
    this.status = 'executiveValidation';
    this.goToCommercialManagement = goToCommercialManagement;
  }

  public statusEmit($event) {
    this.status = $event;
  }

  public executiveRutEmit($event) {
    this.executiveRut = $event;
  }

  public infoExecutiveEmit($event) {
    const { NroAudit, UrlOTI, nombre_af, apellido_paterno_af, apellido_materno_af, rut_numero_af, dv_af } = $event;
    this.executiveInfo = {
      // BYPASS
      // executive: this.executiveBypass
      executive: {
        rut: rut_numero_af + dv_af,
        lastName: apellido_paterno_af,
        motherLastName: apellido_materno_af,
        name: nombre_af,
        verificationCode: NroAudit,
        verificationUrl: UrlOTI
      }
    };
  }

  public userTypeEmit($event) {
    this.userType = $event;
  }

  public clientPhoneValidationObjectEmit($event) {
    this.clientPhoneValidationObject = $event;
  }

  public isBiometricValidationEmit($event) {
    this.isBiometricValidation = $event;
  }

  public isCodeValidationEmit($event) {
    this.isCodeValidation = $event;
  }

  public isFacialValidationEmit($event) {
    this.isFacialValidation = $event;
  }

  public errorCodeEmit($event) {
    this.errorCode = $event;
  }
}
