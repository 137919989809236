import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAX_LENGTH_RUT } from '@constants';
import { Util } from '@util';
import 'firebase/firestore';
import { ValidateRut } from 'app/validators/rut.validator';

export type loginStepType = 'login' | 'loginIdentityCard' | 'waitingIdentityCard' |
  'waiting' | 'errorValidation' | 'errorService' | 'ok';

@Component({
  selector: 'app-executive-identity-validation',
  templateUrl: './executive-identity-validation.component.html',
  styleUrls: ['./executive-identity-validation.component.scss']
})

export class ExecutiveIdentityValidationComponent implements OnInit {
  @Output() public status = new EventEmitter();
  @Output() public userType = new EventEmitter();
  @Output() public executiveRut = new EventEmitter();
  public executiveForm: UntypedFormGroup;
  public maxLengthForRut = MAX_LENGTH_RUT;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private util: Util,
  ) {
    this.executiveForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      operation: ['Validación con huella', [Validators.required]]
    });
  }

  get executiveRutControl() { return this.executiveForm.controls['rut']; }

  get disableExecutiveForm() { return this.executiveForm.invalid || !this.executiveForm.dirty || !this.executiveForm.touched; }

  public async ngOnInit() {
  }

  public validateExecutive() {
    this.status.emit('loading');
    this.executiveRut.emit(this.util.rutClean(this.executiveRutControl.value));
  }

  public goBack() {
    this.status.emit('functionalitySelection');
  }
}
